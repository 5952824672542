import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {Link, useNavigate} from 'react-router-dom';
import {
    CarOutlined,
    DeleteOutlined,
    ExportOutlined,
    HeartOutlined,
    HomeOutlined,
    MoreOutlined,
    RightOutlined,
    UserOutlined
} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Button, Dropdown} from 'antd';
import {Store} from 'app/store/Store';
import classNames from 'classnames';
import {useAddToFavoritesAssets} from 'features/Offer/hook/useAddToFavoritesAssets';
import {useRemoveFromFavoritesAssets} from 'features/Offer/hook/useRemoveFromFavoritesAssets';
import {observer} from 'mobx-react-lite';
// import { ReactComponent as IconFlat } from 'shared/assets/icons/iconFlat.svg';
import {goToAsset, userList} from 'shared/config/routes';
import {assets_assets_data} from 'shared/graphql/__generated__/assets';
import {CurrencyFormat} from 'shared/helpers/CurrencyFormat';
import {isValidUrl} from 'shared/helpers/isValidURL';
import {SultanAvatar} from 'widgets/Avatar/SultanAvatar';
import CoinsOutlined from 'widgets/Icons/CoinsOutlined';
import ExcavatorOutlined from 'widgets/Icons/ExcavatorOutlined';

import {ENUM_ASSET_STATUS} from '../../../__generated__/global-types';

import cls from './OfferCard.module.scss';


interface IAssetCardProps {
    className?: string;
    asset: assets_assets_data;
    maxInRow?: 3 | 4;
}

export const AssetCard = observer(function OfferCard(props: IAssetCardProps) {
    const {asset, maxInRow} = props;

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {auth} = useInstance(Store);

    const addToFavorites = useAddToFavoritesAssets({UID: asset.attributes?.UID!});
    const removeFromFavorites = useRemoveFromFavoritesAssets({UID: asset.attributes?.UID!});

    const inFavorites: boolean = auth.isFavoritesAssets(asset.attributes?.UID!);
    const closed: boolean = asset.attributes?.status === ENUM_ASSET_STATUS.CLOSED_3

    let type
    switch (asset.attributes?.asset.type) {
        case 'estate':
            type = <span><HomeOutlined className={cls.icon}/> {t('Real estate')}</span>;
            break;
        case 'tech':
            type = <span><ExcavatorOutlined className={cls.icon}/> {t('Construction vehicles')}</span>;
            break;
        case 'car':
            type = <span><CarOutlined className={cls.icon}/> {t('Transport')}</span>;
            break;
    }

    const items: MenuProps['items'] = [
        {
            key: 'open',
            label: <Link to={goToAsset(asset.attributes?.UID!)} className={cls.bold}>{t('Open')}</Link>,
            icon: <RightOutlined/>
        },
        ...(isValidUrl(asset.attributes?.asset.link!) ? [
            {
                key: 'link',
                label: (<a href={asset.attributes?.asset.link!} target={'_blank'}
                           rel={'noreferrer'}>{t('Evaluate an asset')}...</a>),
                icon: <ExportOutlined/>
            }
        ] : []),
        !inFavorites
            ?
            {
                key: 'favorites',
                label: t('Add to favorites'),
                icon: <HeartOutlined/>,
                onClick: () => addToFavorites(),
            }
            :
            {
                key: 'remove-from-favorites',
                label: t('Remove from favorites'),
                icon: <DeleteOutlined/>,
                onClick: () => removeFromFavorites(),
            },
        // {
        //     key: 'hide',
        //     label: <Link to={TIMER}>{t('Hide')}</Link>,
        //     icon: <EyeInvisibleOutlined/>
        // },
        {
            key: 'author',
            label: <Link to={userList(asset.attributes?.author?.data?.attributes?.UID!)}>{t('All offers from this user')}</Link>,
            icon: <UserOutlined/>
        },
    ];

    return (
        <div className={classNames([
            cls.offerCard,
            inFavorites && cls.favorites,
            maxInRow && maxInRow === 4 && cls.row4,
            closed && cls.disabled,
        ])}>
            <div className={cls.left} onClick={closed ? undefined : (() => navigate(goToAsset(asset.attributes?.UID || '')))}>
                <span className={cls.title}>
                    <CoinsOutlined/>
                    <span className={cls.percent}>{CurrencyFormat(asset.attributes?.asset.cost!)} {asset.attributes?.asset.currency?.data?.attributes?.name!}</span>
                </span>
                <span className={cls.subTitle}>
                         {type}
                </span>
            </div>
            <div className={cls.right}>
                <div className={cls.rightTop}>
                    <Dropdown menu={{items}} disabled={closed}>
                        <Button type={'text'} icon={<MoreOutlined/>} disabled={closed}/>
                    </Dropdown>
                </div>
                <div className={cls.rightBottom}>
                    {asset.attributes?.author?.data &&
                        <SultanAvatar
                            size={40}
                            color={asset.attributes?.author?.data?.attributes?.color || '#ffffff'}
                            photo={asset.attributes?.author?.data?.attributes?.photo || undefined}
                            firstName={asset.attributes?.author?.data?.attributes?.firstName || ''}
                            lastName={asset.attributes?.author?.data?.attributes?.lastName || ''}
                        />
                    }
                </div>

            </div>
        </div>
    );
});