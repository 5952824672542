import { ReactNode } from 'react';
import { useOutletContext } from 'react-router-dom';

export interface TrainerLayoutStateType {
    header?: boolean;
    bg?: boolean;
    title?: ReactNode;
    footer?: ReactNode;
}

export interface ContextType {
    setLayout: (data: TrainerLayoutStateType) => void;
}

export const useLayout = () => useOutletContext<ContextType>();
