import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {INDEX} from 'shared/config/routes';

function NotFound () {

    const navigate = useNavigate()

    useEffect(() => {
        navigate(INDEX)
    }, [navigate])

    return null
};

export default NotFound;