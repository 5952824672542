import React from 'react';
import {ConfigProvider,Modal} from 'antd';

import cls from './BigModal.module.scss'

interface IAdaptiveModalProps {
    children?: React.ReactNode;
    open: boolean;
    onClose: () => void;
}

export const BigModal = (props: IAdaptiveModalProps) => {

    const {open, children, onClose} = props

    return (
        <ConfigProvider
            theme={{
                token: {
                    borderRadius: 0,
                },
                components: {
                    Modal: {
                        borderRadius: 0,
                    },
                },
            }}
        >

            <Modal open={open} onCancel={onClose} footer={null} className={cls.modal}>
                <div className={cls.content}>

                    {children}

                </div>
            </Modal>
        </ConfigProvider>
    )
}