import React, {useEffect,useState} from 'react';
import {useTranslation} from 'react-i18next';
import Sheet from 'react-modal-sheet';
import {CloseOutlined} from '@ant-design/icons';
import {Badge,Button, Popover} from 'antd';

import cls from 'pages/Index/ui/Layout/IndexLayout.module.scss';

interface IFilter {
    open: boolean
    onOpen: () => void
    onClose: () => void
    children?: React.ReactNode
    badge?: boolean
}

export const Filter = ({open, onOpen, onClose, children, badge}:IFilter) => {

    const {t} = useTranslation()

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {setWindowSize([window.innerWidth, window.innerHeight]);};
        window.addEventListener('resize', handleWindowResize);
        return () => {window.removeEventListener('resize', handleWindowResize);};
    }, []);

    const handleClickChange = (value: boolean) => {
        if (value) {onOpen()} else {onClose()}
    };

    const popoverContent =
        <div className={cls.popoverContent}>
            <div className={cls.close}>
                <Button type={'text'} icon={<CloseOutlined/>} onClick={() => onClose()}/>
            </div>
            {children}
        </div>

    const popoverElement =
        <Popover content={popoverContent} placement="bottomLeft" trigger="click" open={open}
                 onOpenChange={handleClickChange} arrow={false}>

            <Button>{t('Filters')} {badge && <Badge status={'error'} style={{marginLeft: 4}}/>}</Button>
        </Popover>

    const sheetElement =
        <>
            <Button onClick={() => onOpen()}>{t('Filters')} {badge && <Badge status={'error'} style={{marginLeft: 4}}/>}</Button>
            <Sheet isOpen={open} onClose={() => onClose()} tweenConfig={{ease: 'easeInOut', duration: 0.3}}
                   detent={'content-height'} className={cls.sheet}
            >
                <Sheet.Container>
                    <Sheet.Header/>
                    <Sheet.Content>
                        <div className={cls.sheetWrapper}>
                            {children}
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
            </Sheet>
        </>


    if (windowSize[0] > 500) {return popoverElement}
    return sheetElement

}