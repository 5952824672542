import {gql} from '@apollo/client';

export const QUERY_ASSETS = gql`
    query assets ($filters: AssetFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        assets (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    UID
                    status
                    asset {
                        type
                        cost
                        currency {
                            data {
                                attributes {
                                    name
                                }
                            }
                        }
                        country {
                            data {
                                attributes {
                                    name
                                }
                            }
                        }
                        region {
                            data {
                                attributes {
                                    name
                                }
                            }
                        }
                        link
                    }
                    author {
                        data {
                            id
                            attributes {
                                photo
                                firstName
                                lastName
                                color
                                UID
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    total
                }
            }
        }
    }
`