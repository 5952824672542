import { CountryStore,initialCountryStore } from 'app/store/CountryStore';
import { DialogStore,initialDialogStore } from 'app/store/DialogStore';
import { FilterStore,initialFilterStore } from 'app/store/FilterStore';
import { initialLangStore, LangStore } from 'app/store/LangStore';
import { initialPriceStore, PriceStore } from 'app/store/PriceStore';
import { initialOfferState, OfferStore } from 'features/Offer/model/store/OfferStore';
import { type Instance, type SnapshotIn, type SnapshotOut, types as t } from 'mobx-state-tree';

import { AuthStore, initialAuthStore } from './AuthStore';

export const RootStore = t
    .model({
        hydrated: t.optional(t.boolean, false), // метка что состояние хранилища восстановлено из сохраненного в localstorage
        auth: t.optional(AuthStore, initialAuthStore),
        offer: t.optional(OfferStore, initialOfferState),
        lang: t.optional(LangStore, initialLangStore),
        filters: t.optional(FilterStore, initialFilterStore),
        countries: t.optional(CountryStore, initialCountryStore),
        dialogs: t.optional(DialogStore, initialDialogStore),
        price: t.optional(PriceStore, initialPriceStore),
    })
    .actions((self) => ({
        setHydrated: (value: boolean) => {
            self.hydrated = value;
        },
    }));

export interface IRootStoreModel extends Instance<typeof RootStore> {}

export interface IRootStoreModelSnapshotIn extends SnapshotIn<typeof RootStore> {}

export interface IRootStoreModelSnapshotOut extends SnapshotOut<typeof RootStore> {}

