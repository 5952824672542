export const CountryRegionRus = (input:string) => {
    switch (input) {
        case 'Russia': return 'Россия';
        case 'Moscow': return 'Москва';
        case 'Tatarstan': return 'Татарстан';
        case 'Bashkortostan': return 'Башкортостан';
        case 'Chechnya': return 'Чечня';
        case 'Dagestan': return 'Дагестан';

        case 'South Africa': return 'ЮАР';
        case 'Cape Town': return 'Кейптаун';
        case 'Johannesburg': return 'Йоханнесбург';
        case 'Durban': return 'Дурбан';

        case 'Belarus': return 'Беларусь';
        case 'Kazakhstan': return 'Казахстан';
        case 'Azerbaijan': return 'Азербайджан';
        case 'Uzbekistan': return 'Узбекистан';
        case 'Kyrgyzstan': return 'Кыргызстан';

        case 'Turkey': return 'Турция';
        case 'Israel': return 'Израиль';

        case 'UK': return 'Великобритания';
        case 'Spain': return 'Испания';
        case 'France': return 'Франция';
        case 'Germany': return 'Германия';
        case 'Australia': return 'Австралия';

        default: return '';
    }
}