import {gql} from '@apollo/client';

export const QUERY_OFFER = gql`
    query offer (
        $UID: ID!
    ) {
        offer (UID: $UID) {
            id
            attributes {
                asset {
                    data {
                        id
                    }
                }
                type
                country {
                    data {
                        id
                        attributes {
                            name
                            code2
                        }
                    }
                }
                region {
                    data {
                        id
                        attributes {
                            name
                            logo {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
                activeCost
                currency {
                    data {
                        id
                        attributes {
                            name
                        }
                    }
                }
                author {
                    data {
                        id
                        attributes {
                            color
                            firstName
                            lastName
                            photo
                            UID
                        }
                    }
                }
                countryUse {
                    data {
                        id
                        attributes {
                            name
                            code2
                        }
                    }
                }
                regionUse {
                    data {
                        id
                        attributes {
                            name
                            logo {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
                activePayment
                investmentSum
                link
                totalDuration
                partsCount
                partCost
                partPaymentPeriod
                calcData
                rentCost
            }
        }
    }
    
`