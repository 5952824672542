import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useParams} from 'react-router-dom';
import {MenuLayout} from 'app/Layout/MenuLayout';
import {Store} from 'app/store/Store';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import {goToDialog} from 'shared/config/routes';
import {useLayout} from 'shared/hooks/useTrainerLayout';
import {Chat} from 'widgets/Chat/Chat';
import {EmptyChat} from 'widgets/Chat/EmptyChat';
import {IDialogsItems,IGroupsType, IMenuItems, Menu} from 'widgets/Menu/Menu';
import {MenuDialogs} from 'widgets/Menu/MenuDialogs';
import {PageTitle} from 'widgets/PageTitle';

export const Dialogues = observer(() => {

    const {t} = useTranslation();
    const {setLayout} = useLayout();
    const {UID} = useParams()
    const {dialogs} = useInstance(Store);

    useEffect(() => {
        setLayout({
            title: (
                <PageTitle title={t('Dialogues')}/>
            ),
        });
    }, [setLayout, t]);

    const buyingUsers = _.groupBy(dialogs.buying, 'userUID')
    const buyingItems: IMenuItems[] = _.map(buyingUsers, user => ({
        key: user[0]?.userUID!,
        route: goToDialog(user[0]?.UID!),
        avatar: {
            firstName: user[0]?.firstName!,
            lastName: user[0]?.lastName!,
            color: user[0]?.color!,
            photo: user[0]?.photo!,
            UID: user[0]?.userUID!,
        },
        count: _.reduce(user, function(sum, n) {
            return sum + n?.unreadMessages!;
        }, 0),
        dialogs: _.map(user, item => ({key: item?.UID!, UID: item?.UID!, offerUID: item?.offerUID!, userUID: item?.userUID!, unreadMessages: item?.unreadMessages!})),
    }))

    const sellingUsers = _.groupBy(dialogs.selling, 'userUID')
    const sellingItems: IMenuItems[] = _.map(sellingUsers, user => ({
        key: user[0]?.userUID!,
        route: goToDialog(user[0]?.UID!),
        avatar: {
            firstName: user[0]?.firstName!,
            lastName: user[0]?.lastName!,
            color: user[0]?.color!,
            photo: user[0]?.photo!,
            UID: user[0]?.userUID!,
        },
        count: _.reduce(user, function(sum, n) {
            return sum + n?.unreadMessages!;
        }, 0),
        dialogs: _.map(user, item => ({key: item?.UID!, UID: item?.UID!, offerUID: item?.offerUID!, userUID: item?.userUID!, unreadMessages: item?.unreadMessages!})),
    }))

    const investingUsers = _.groupBy(dialogs.investing, 'userUID')
    const investingItems: IMenuItems[] = _.map(investingUsers, user => ({
        key: user[0]?.userUID!,
        route: goToDialog(user[0]?.UID!),
        avatar: {
            firstName: user[0]?.firstName!,
            lastName: user[0]?.lastName!,
            color: user[0]?.color!,
            photo: user[0]?.photo!,
            UID: user[0]?.userUID!,
        },
        count: _.reduce(user, function(sum, n) {
            return sum + n?.unreadMessages!;
        }, 0),
        dialogs: _.map(user, item => ({key: item?.UID!, UID: item?.UID!, offerUID: item?.offerUID!, userUID: item?.userUID!, unreadMessages: item?.unreadMessages!})),
    }))

    const menu: IGroupsType[] = [
        {
            key: 'buying',
            title: `${t('Buying')}`,
            items: buyingItems,
            unreadMessages: _.reduce(dialogs.buying, function(sum, n) {return sum + n?.unreadMessages!;}, 0),
        },
        {
            key: 'selling',
            title: `${t('Selling')}`,
            items: sellingItems,
            unreadMessages: _.reduce(dialogs.selling, function(sum, n) {return sum + n?.unreadMessages!;}, 0),
        },
        {
            key: 'investing',
            title: `${t('Investing')}`,
            items: investingItems,
            unreadMessages: _.reduce(dialogs.investing, function(sum, n) {return sum + n?.unreadMessages!;}, 0),
        },
    ]

    // определить, к чему относится текущий UID - к строчке или группе
    // если к группе то нарисовать выбор диалогов
    let menuDialogs:IDialogsItems[] = []
    _.forEach(buyingItems, item => {
        if (item.key === UID) return false
        if (item.dialogs && _.find(item.dialogs, item => item.UID === UID)) {
            menuDialogs = item.dialogs
            return
        }
    })
    _.forEach(sellingItems, item => {
        if (item.key === UID) return false
        if (item.dialogs && _.find(item.dialogs, item => item.UID === UID)) {
            menuDialogs = item.dialogs
            return
        }
    })
    _.forEach(investingItems, item => {
        if (item.key === UID) return false
        if (item.dialogs && _.find(item.dialogs, item => item.UID === UID)) {
            menuDialogs = item.dialogs
            return
        }
    })

    return (
        <MenuLayout menu={<Menu groups={menu} selected={UID || ''}/>} dialogs={<MenuDialogs dialogs={menuDialogs} selected={UID!}/>}>

            {UID ? <Chat UID={UID}/> : <EmptyChat/>}

        </MenuLayout>
    );
})