import {useTranslation} from 'react-i18next';

import img from './images/cover.jpg';

import cls from './Pres.module.scss';
export const Slide1 = () => {

    const {t} = useTranslation();

    return (
        <div className={cls.slide}>
            <div className={cls.headline}>
                {t('+1 Tool for buying and selling assets')}

            </div>
            <div className={cls.content}>
                <img className={cls.img} src={img} alt={'Cover'}/>
            </div>
        </div>
    )
}