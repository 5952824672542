// INDEX

export const ROOT = '';
export const INDEX = '/';
export const INDEX_INVEST = '/invest/:mode';
export const INDEX_BUY = '/buy/:mode';
export function goIndexInvest(mode:'list'|'map', params?: string) {
    return params ? `/invest/${mode}?${params}` : `/invest/${mode}`
}
export function goIndexBuy(mode:'list'|'map', params?: string) {
    return params ? `/buy/${mode}?${params}` : `/buy/${mode}`
}
export const USER_LIST = '/author/:UID';
export const userList = (UID: string) => `/author/${UID}`
export const TIMER = '/timer';

export const CUSTOMER = '/customer';
export const CALC = '/calc';
export const OFFER = '/offer/:id';
export const ASSET_CREATE = '/asset/create';
export const ASSET = '/asset/:id';
export const CREATE_OFFER_OVER_ASSET = '/asset/:id/calc';
export function createOfferOverAsset (id: string) {
    return `/asset/${id}/calc`
}

export const FEEDBACK = '/feedback';
export const PRESENTATION = '/presentation';
export const PRESENTATION_GLOBAL = '/presentation/global';
export const FORGET_PASSWORD = '/forgetPassword'
export const PAYMENT_TABLE = '/paymentTable'
// export const PAYMENT_TABLE = '/paymentTable'

export const goToOffer = (UID: string) => `/offer/${UID}`
export const goToAsset = (UID: string) => `/asset/${UID}`


export const ACCOUNT = '/account';
export const MY_OFFERS = '/account/my-offers';
export const myOffers = (status: 'drafts' | 'published' | 'closed') => `${MY_OFFERS}/${status}`;
export const MY_ASSETS = '/account/my-assets';
export const myAssets = (status: 'drafts' | 'published' | 'closed') => `${MY_ASSETS}/${status}`;

export const EDIT_OFFER = '/account/edit-offer/:UID';
export const editOffer = (UID: string) => `/account/edit-offer/${UID}`
export const EDIT_ASSET = '/account/edit-asset/:UID';
export const editAsset = (UID: string) => `/account/edit-asset/${UID}`

export const DIALOGUES = '/account/dialogues';
export const goToDialog = (UID: string) => `${DIALOGUES}/${UID}`
export const FAVORITES = '/account/favorites';

export const AGREEMENT = '/agreement';
export const goToAgreement = (UID: string) => `${AGREEMENT}/${UID}`





// 404
export const NOT_FOUND = '/404';
