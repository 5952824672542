import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {SendOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {Button, Input} from 'antd';
import {Store} from 'app/store/Store';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import {continueDialog, continueDialogVariables} from 'shared/graphql/__generated__/continueDialog';
import {createMessage, createMessageVariables} from 'shared/graphql/__generated__/createMessage';
import {declineDialog, declineDialogVariables} from 'shared/graphql/__generated__/declineDialog';
import {dialog, dialogVariables} from 'shared/graphql/__generated__/dialog';
import {CONTINUE_DIALOG} from 'shared/graphql/continueDialog';
import {CREATE_MESSAGE} from 'shared/graphql/createMessage';
import {DECLINE_DIALOG} from 'shared/graphql/declineDialog';
import {QUERY_DIALOG} from 'shared/graphql/queryDialog';
import {AgreementStatus} from 'widgets/Chat/AgreementStatus/AgreementStatus';
import {Bar} from 'widgets/Chat/Bar';
import {Message} from 'widgets/Chat/Message';

import {ENUM_DIALOG_STATUS} from '../../__generated__/global-types';

import cls from './Chat.module.scss';

export interface IMessage {
    key: string,
    id: string,
    date: string,
    text: string,
    author: 'INCOME' | 'ME',
    type: 'HUMAN' | 'SYSTEM',
    status?: 'SENT' | 'DELIVERED' | 'READ',
}

export interface IBar {
    text: string,
}

interface IProps {
    UID: string,
}

export const Chat = observer(({UID}: IProps) => {

    const {t} = useTranslation();
    const {price} = useInstance(Store);

    const refEndMessages = useRef<HTMLDivElement>(null)
    const refMessages = useRef<HTMLDivElement>(null)

    const query = useQuery<dialog,dialogVariables>(QUERY_DIALOG, {
        variables: {UID: UID},
        pollInterval: 5000,
    })

    const scrollToBottom = () => {
        refMessages.current?.scroll({top: refMessages.current?.scrollHeight, behavior: 'smooth'})
        refEndMessages.current?.scrollIntoView({behavior: 'smooth'})
    }

    // скролл мне изминении высоты блока
    useEffect(() => {
        scrollToBottom()
    }, [refMessages.current?.offsetHeight])

    // скролл при новых данных
    useEffect(() => {
        scrollToBottom()
    }, [query.data])

    const [continueDialog] = useMutation<continueDialog,continueDialogVariables>(CONTINUE_DIALOG)
    const [declineDialog] = useMutation<declineDialog,declineDialogVariables>(DECLINE_DIALOG)

    const [create] = useMutation<createMessage,createMessageVariables>(CREATE_MESSAGE)
    const [message, setMessage] = useState<string>('')

    const onSendMessage = async () => {
        if (message.trim() !== '') {
            await create({
                variables: {
                    UID: UID,
                    message: message.trim(),
                },
                onCompleted: () => {
                    query.refetch();
                    setMessage('');
                }
            })
        }
    }

    const onPressEnter = async (e:any) => {
        if (!e.shiftKey) {
            await onSendMessage()
        }
    }

    const onContinueDialog = async () => {
        await continueDialog({
            variables: {UID: UID},
            onCompleted: () => {
                query.refetch()
            }
        })
    }

    const onDeclineDialog = async () => {
        await declineDialog({
            variables: {UID: UID},
            onCompleted: () => {
                query.refetch()
            }
        })
    }

    return (
        <div className={cls.wrapper}>
            <div className={cls.content}>
                <div className={cls.messages} ref={refMessages}>
                    {_.map(query.data?.dialog?.messages, item => {
                        if (item?.type === 'BAR') {
                            return <Bar text={item.text!}/>
                        } else {
                            return <Message key={item?.id!} date={item?.date!} text={item?.text!}
                                     author={item?.author === 'ME' ? 'ME' : 'INCOME'}
                                     type={item?.type === 'SYSTEM' ? 'SYSTEM' : 'HUMAN'}
                                // @ts-ignore
                                     status={item?.status} id={item?.id!}
                            />
                        }
                    })}
                    <hr className={cls.gap}/>
                    <div ref={refEndMessages}/>
                </div>
            </div>
            {query.data?.dialog?.status === ENUM_DIALOG_STATUS.active &&
            <div className={cls.form}>
                <Input.TextArea autoSize={{minRows: 1, maxRows: 3}} value={message} size={'large'} className={cls.input} placeholder={t('Message') || undefined}
                       onChange={(event) => setMessage(event.target.value)}
                       onPressEnter={onPressEnter}
                />
                <Button size={'large'} type={'primary'} icon={<SendOutlined/>} onClick={onSendMessage}/>
            </div>
            }
            {(!query.data?.dialog?.isClient && query.data?.dialog?.status === ENUM_DIALOG_STATUS.created) &&
                <div className={cls.buttons}>
                    {/* eslint-disable-next-line no-template-curly-in-string */}
                    <Button size={'large'} type={'primary'} onClick={onContinueDialog}>{t('Continue the dialogue — ${{X}}', {X: price.createDialog})}</Button>
                    <Button size={'large'} onClick={onDeclineDialog}>{t('Decline the offer')}</Button>
                </div>
            }
            {query.data?.dialog?.agreement?.attributes?.UID &&
                <AgreementStatus
                    UID={query.data?.dialog?.agreement?.attributes?.UID!}
                    status={query.data?.dialog.agreement.attributes?.status!}
                    offer={query.data?.dialog?.agreement.attributes.offer!}
                    isClient={query.data?.dialog?.isClient!}
                />
            }
        </div>
    )
})