import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import ym from 'react-yandex-metrika';
import { useMutation} from '@apollo/client';
import {Button, Divider} from 'antd';
import {Store} from 'app/store/Store';
import classNames from 'classnames';
import currency from 'currency.js';
import {CreateOffer, PaymentTable} from 'features/Offer';
import {ICreateOfferFormValues} from 'features/Offer/model/types/form';
import {createOffer, createOfferVariables} from 'features/Offer/ui/graph/__generated__/createOffer';
import {observer} from 'mobx-react-lite';
import { myOffers,} from 'shared/config/routes';
import { CREATE_OFFER } from 'shared/graphql/createOffer';
import {OfferData} from 'widgets/Offer';
import {ModalDraft} from 'widgets/Offer/ui/ModalDraft';
import {ModalPublish} from 'widgets/Offer/ui/ModalPublish';

import {ENUM_OFFER_STATUS, OfferInput} from '../../../../__generated__/global-types';

import cls from './OfferGraph.module.scss';

interface IOfferGraphProps {
    className?: string;
}

const CurrencyFormat = (value: number | string) =>
    currency(value, {
        symbol: '',
        separator: ' ',
        precision: 0,
    }).format();

export const OfferGraph = observer((props: IOfferGraphProps) => {
    const {className} = props;

    const {t} = useTranslation();
    const store = useInstance(Store);
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTableOpen, setIsTableOpen] = useState(false);
    const [isSuccessOpen, setIsSuccesssOpen] = useState(false);
    const [isDraftOpen, setIsDraftOpen] = useState(false);

    const {offer} = store;
    const {auth} = store;
    const {isHasChanges, calculatedValues, isCalculated} = offer;
    const {dealCost, investPayment, investSellProfit, investRentProfit, paymentTable} = calculatedValues;

    const [createOffer] = useMutation<createOffer, createOfferVariables>(CREATE_OFFER);

    const onAddCreate = async (values: ICreateOfferFormValues, status: ENUM_OFFER_STATUS) => {

        if (auth.hasProfile) {
            const data: OfferInput = {
                type: values.type,
                country: values.country,
                region: values.region,
                countryUse: values.countryUse,
                regionUse: values.regionUse,
                currency: values.currency,
                link: values.link,
                status: status,

                activeCost: offer.assetCost,
                activePayment: offer.firstPayment,
                rentCost: offer.amountToRent,
                partCost: offer.amountToBuy,
                partsCount: offer.numberOfSubShares,
                partPaymentPeriod: offer.paymentPeriod,
                investmentSum: offer.calculatedValues.investPayment,
                incomePercent: offer.calculatedValues.investProfitPercent,
                totalDuration: offer.calculatedValues.dealPeriod,
                calcData: offer.calculatedValues,

            }

            await createOffer({
                variables: {
                    data: data
                },
                onCompleted: () => {
                    if (status === ENUM_OFFER_STATUS.DRAFT_1) {
                        setIsDraftOpen(true)
                    }
                    if (status === ENUM_OFFER_STATUS.PUBLIC_2) {
                        setIsSuccesssOpen(true)
                    }
                    setIsModalOpen(false);
                },
            })
        } else {
            auth.openAuth()
        }
    };

    const openTable = () => {
        if (isCalculated) {
            setIsTableOpen(true);
            ym('reachGoal', 'click_review');
        }
    };

    const closeTabel = () => {
        setIsTableOpen(false);
    };

    const onOkHandler = () => {
        if (isSuccessOpen) {
            setIsSuccesssOpen(false);
            navigate(myOffers('published'));
        }
        if (isDraftOpen) {
            setIsDraftOpen(false)
            navigate(myOffers('drafts'))
        }
        //todo добавить переход в зависиомсти от статуса
    }

    const onClickSubmit = () => {
        ym('reachGoal', 'click_submit');
        setIsModalOpen(true);
    }

    return (
        <div className={classNames(cls.OfferGraph, {}, [className])}>
            <div className={cls.wrap}>
                <div className={cls.calculated}>
                    <OfferData
                        name={t('Payment frequency')}
                        value={
                            <span
                                onClick={openTable}
                                className={classNames([cls.getTabel], {[cls.disabled]: !paymentTable})}
                            >
                                {t('Review')}
                            </span>
                        }
                    />
                    <Divider className={cls.divider}/>
                    <OfferData name={t('The amount you pay for 100% of the asset')} value={CurrencyFormat(dealCost)}/>
                    <Divider className={cls.divider}/>
                    <OfferData name={t('The amount investor pays upon purchasing')} value={CurrencyFormat(investPayment)}/>
                    <Divider className={cls.divider}/>
                    <OfferData name={t('Investor\'s earnings (buying&selling)')} value={CurrencyFormat(investSellProfit)}/>
                    <Divider className={cls.divider}/>
                    <OfferData name={t('Investor\'s earnings (renting out)')} value={CurrencyFormat(investRentProfit)}/>
                    <Divider className={cls.divider}/>

                    <Button
                        size={'large'}
                        type={'primary'}
                        onClick={onClickSubmit}
                        disabled={!isCalculated || isHasChanges}
                    >
                        {t('Submit the offer')}
                    </Button>
                </div>
            </div>

            <ModalPublish open={isSuccessOpen} onOk={onOkHandler}/>
            <ModalDraft open={isDraftOpen} onOk={onOkHandler}/>

            <CreateOffer isOpen={isModalOpen && !auth.inAuthProcess} onCancel={() => setIsModalOpen(false)} onComplete={onAddCreate}/>
            <PaymentTable isOpen={isTableOpen} onCancel={closeTabel}/>
        </div>
    );
});