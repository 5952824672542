import {useCallback} from 'react';
import {useInstance} from 'react-ioc';
import {useLocation, useNavigate} from 'react-router-dom';
import {Store} from 'app/store/Store';

export const useChangeType = () => {

    const store = useInstance(Store);
    const location = useLocation();
    const navigate = useNavigate();

    return useCallback(async (type: 'invest' | 'buy') => {

        store.filters.setType(type)

        // для остальных страниц
        let newLocation = '';
        if (type === 'invest') {
            newLocation = `${location.pathname.replace('buy', 'invest')}`
        } else {
            newLocation = `${location.pathname.replace('invest', 'buy')}`
        }
        navigate(newLocation)


    }, [store, navigate, location])
}