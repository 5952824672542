import react from 'react';
import {useNavigate} from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';

import cls from './PageTitle.module.scss';

interface IClientTitleProps {
    className?: string;
    title: react.ReactNode;
    control?: react.ReactNode;
}

export const PageTitle = (props: IClientTitleProps) => {
    const {className, title, control} = props;

    const navigate = useNavigate();

    return (
        <div className={classNames(cls.PageTitle, {}, [className])}>
            <div className={cls.headline}>
                <div className={cls.back}>
                    <Button type={'text'} icon={<ArrowLeftOutlined/>} onClick={() => navigate(-1)}/>
                </div>
                <div className={cls.title}>
                    {title}

                </div>
            </div>
            <div className={cls.control}>
                {control}
            </div>

        </div>
    );
};
