import {gql} from '@apollo/client';

export const QUERY_OFFERS = gql`
    query offers ($filters: OfferFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        offers (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    UID
                    investmentSum
                    type
                    totalDuration
                    incomePercent
                    link
                    status
                    rentCost
                    partsCount
                    partPaymentPeriod
                    activeCost
                    activePayment
                    partCost
                    author {
                        data {
                            id
                            attributes {
                                photo
                                firstName
                                lastName
                                color
                                UID
                            }
                        }
                    }
                    currency {
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                    country {
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                    region {
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    total
                }
            }
        }
    }
`