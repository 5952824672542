import {useTranslation} from 'react-i18next';
import {ISlide} from 'pages/Pres/Pres';

import img_deu from './images/monolith-deu.jpg';
import img_eng from './images/monolith-eng.jpg';
import img_esp from './images/monolith-esp.jpg';
import img_fra from './images/monolith-fra.jpg';
import img_kaz from './images/monolith-kaz.jpg';
import img_rus from './images/monolith-rus.jpg';
import img_tur from './images/monolith-tur.jpg';

import cls from './Pres.module.scss';

export const Slide5 = ({platform}:ISlide) => {

    const {t, i18n} = useTranslation();

    let src = img_eng;

    switch (i18n.language) {
        case 'rus':
            src = img_rus;
            break;
        case 'eng':
            src = img_eng;
            break;
        case 'tur':
            src = img_tur;
            break;
        case 'kaz':
            src = img_kaz;
            break;
        case 'fra':
            src = img_fra;
            break;
        case 'deu':
            src = img_deu;
            break;
        case 'esp':
            src = img_esp;
            break;
    }

    return (
        <div className={cls.slide}>
            <div className={cls.headline}>
                {t('Architecture of {{platform}} platform', {platform: platform})}
            </div>
            <div className={cls.content}>
                <img className={cls.img} src={src} alt={'Cover'}/>
            </div>
        </div>
    )
}