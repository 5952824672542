import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {gql, useMutation} from '@apollo/client';
import {Button, Form, Input, message, Select, Upload} from 'antd';
import type {RcFile} from 'antd/es/upload/interface';
import ImgCrop from 'antd-img-crop';
import {Store} from 'app/store/Store';
import {upload, uploadVariables} from 'features/Auth/__generated__/upload';
import {
    updateUsersPermissionsUser,
    updateUsersPermissionsUserVariables
} from 'features/Auth/graphql/__generated__/updateUsersPermissionsUser';
import {UPDATE_ME} from 'features/Auth/graphql/updateMe';
import {IAnketFormValues} from 'features/Auth/model/form';
import useChangeLanguage from 'features/Language/useChangeLanguage';
import {observer} from 'mobx-react-lite';
import { nameRegex } from 'shared/helpers/regex';
import { AdaptiveModal } from 'widgets/AdaptiveModal/AdaptiveModal';

import {getApiBase} from '../../env';

import cls from './Auth.module.scss'

const UPLOAD_FILE_MUTATION = gql`
    mutation upload($file: Upload!) {
        upload(file: $file) {
            data {
                id
                attributes {
                    url
                    formats
                }
            }
        }
    }
`;


export const ModalAnket = observer(() => {

    const {auth} = useInstance(Store);
    const {t, i18n} = useTranslation();

    const [update, {loading}] = useMutation<updateUsersPermissionsUser, updateUsersPermissionsUserVariables>(UPDATE_ME);
    const [upload, uploadData] = useMutation<upload, uploadVariables>(UPLOAD_FILE_MUTATION);

    const [photoUrl, setPhotoUrl] = useState<string | undefined>(auth.data?.photo || undefined)
    
    const changeLanguage = useChangeLanguage();

    const onSubmit = async (values: IAnketFormValues) => {
        await update({
            variables: {
                data: {
                    ...values,
                    photo: photoUrl,
                },
                id: auth.id || '',
            },
            onCompleted: async (data) => {
                if (data.updateUsersPermissionsUser.data?.id) {
                    const attributes = data.updateUsersPermissionsUser.data.attributes
                    const userData = {
                        firstName: attributes?.firstName || '',
                        lastName: attributes?.lastName || '',
                        color: attributes?.color || '',
                        photo: attributes?.photo || '',
                        email: attributes?.email || '',
                    }
                    auth.setData(userData)
                    await changeLanguage(attributes?.language || null)
                    auth.closeModalAnket()
                }
            }
        });
    };

    // const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    //     if (info.file.status === 'uploading') {
    //         setLoading(true);
    //         return;
    //     }
    //     if (info.file.status === 'done') {
    //         // Get this url from response in real world.
    //         getBase64(info.file.originFileObj as RcFile, (url) => {
    //             setLoading(false);
    //             setImageUrl(url);
    //         });
    //     }
    // };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error(t('You can only upload JPG/PNG file!'));
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error(t('Image must smaller than 10MB'));
        }
        return isJpgOrPng && isLt10M;
    };

    const uploadButton = (
        <div>
            {uploadData.loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>{t('Upload')}</div>
        </div>
    );

    return (
        <AdaptiveModal
            open={auth.modalAnketOpen || false}
            onClose={() => auth.closeModalAnket()}
            title={t('User profile')}
        >
            <Form layout={'vertical'} onFinish={onSubmit}
                  initialValues={{...auth.data}}
                  validateTrigger={'onSubmit'}
            >

                <Form.Item label={t('Name')} name={'firstName'}
                           rules={[
                               {required: true, message: `${t('Please enter the data')}`},
                               {pattern: nameRegex, message: `${t('Only latin letters')}`},
                           ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item label={t('Last name')} name={'lastName'}
                           rules={[
                               {required: true, message: `${t('Please enter the data')}`},
                               {pattern: nameRegex, message: `${t('Only latin letters')}`},
                           ]}
                >
                    <Input/>
                </Form.Item>
                <div className={cls.photoLabel}>{t('Photo')}</div>
                <ImgCrop rotationSlider cropShape={'round'} modalTitle={' '}>
                    <Upload
                        name="avatar"
                        listType="picture-circle"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={async ({file}) => {
                            await upload({
                                variables: {file: file},
                                onCompleted: (data) => {
                                    const photo = `${getApiBase()}${data.upload.data?.attributes?.url}`
                                    setPhotoUrl(photo)
                                },
                            });
                        }}
                        beforeUpload={beforeUpload}
                        // onChange={handleChange}
                    >
                        {photoUrl ? <img src={photoUrl} alt="avatar" className={cls.preview}/> : uploadButton}
                    </Upload>
                </ImgCrop>
                <Form.Item label={t('Language')} name={'language'}
                           rules={[
                               {required: true, message: `${t('Required')}`},
                           ]}
                           initialValue={i18n.language}
                >
                    <Select>
                        <Select.Option key={'tur'}>{t('Turkish')}</Select.Option>
                        <Select.Option key={'eng'}>{t('English')}</Select.Option>
                        <Select.Option key={'fra'}>{t('French')}</Select.Option>
                        <Select.Option key={'deu'}>{t('German')}</Select.Option>
                        <Select.Option key={'kaz'}>{t('Kazakh')}</Select.Option>
                        <Select.Option key={'rus'}>{t('Russian')}</Select.Option>
                        <Select.Option key={'esp'}>{t('Spanish')}</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType={'submit'} loading={loading} disabled={loading}>
                        {t('Save')}
                    </Button>
                </Form.Item>

            </Form>


        </AdaptiveModal>
    );
});