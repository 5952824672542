import {AssetFiltersInput} from '__generated__/global-types';
import {toNumber} from 'lodash';
import {ITypeOfActive, typeToQuery, typeToUrl, urlToType} from 'shared/types/types';

export interface IAssetsFilters {
    amountMin: number | null
    amountMax: number | null
    currency: string | null
    country: string | null
    region: string | null
    type: ITypeOfActive
}

export const defaultAssetFilter:IAssetsFilters = {
    amountMin: null,
    amountMax: null,
    currency: null,
    country: null,
    region: null,
    type: {estate: true, tech: true, car: true},
}

export const isChangedFilter = (filter: IAssetsFilters) => {
    if (filter.amountMin !== defaultAssetFilter.amountMin) return true
    if (filter.amountMax !== defaultAssetFilter.amountMax) return true
    if (filter.currency !== defaultAssetFilter.currency) return true
    if (filter.country !== defaultAssetFilter.country) return true
    if (filter.region !== defaultAssetFilter.region) return true
    if (filter.type !== defaultAssetFilter.type) return true
}

export const filtersToSearchParams = (filter: IAssetsFilters) => ({
        ...(filter.amountMin && {amountMin: filter.amountMin.toString()}),
        ...(filter.amountMax && {amountMax: filter.amountMax.toString()}),
        ...(filter.currency && {currency: filter.currency}),
        ...(filter.country && {country: filter.country}),
        ...(filter.region && {region: filter.region}),
        ...(filter.type !== defaultAssetFilter.type && {type: typeToUrl(filter.type)}),
    })

export const searchParamsToFilters = (params: URLSearchParams) => ({
        amountMin: params.get('amountMin') ? toNumber(params.get('amountMin')) : null,
        amountMax: params.get('amountMax') ? toNumber(params.get('amountMax')) : null,
        currency: params.get('currency') ? params.get('currency') : null,
        country: params.get('country') ? params.get('country') : null,
        region: params.get('region') ? params.get('region') : null,
        type: params.get('type') ? urlToType(params.get('type')!) : defaultAssetFilter.type,
    }) as IAssetsFilters

export const filtersToQuery = (filter: IAssetsFilters) => ({
    type: {in: typeToQuery(filter.type)},
    ...(filter.amountMin && {cost: {gte: filter.amountMin}}),
    ...(filter.amountMax && {cost: {lte: filter.amountMax}}),
    ...((filter.amountMin && filter.amountMax) && {cost: {between: [filter.amountMin, filter.amountMax]}}),
    ...(filter.currency && {currency: {name: {eq: filter.currency}}}),
}) as AssetFiltersInput

