import {useTranslation} from 'react-i18next';

import cls from './EmptyChat.module.scss';


export const EmptyChat = () => {
    
    const {t} = useTranslation()
    
    return (
        <div className={cls.wrapper}>
            <div className={cls.message}>
                {t('Select a chat to start messaging')}
            </div>
        </div>
    )
}