import { ReactNode } from 'react';
import classNames from 'classnames';

import cls from './CalcData.module.scss';

interface IOfferDataProps {
    className?: string;
    name: string;
    value: ReactNode;
}

export const CalcData = (props: IOfferDataProps) => {
    const { className, name, value } = props;

    return (
        <div className={classNames(cls.CalcData, {}, [className])}>
            <span className={cls.name}>{name}</span>
            <span className={cls.value}>{value}</span>
        </div>
    );
};