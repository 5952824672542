import cn from 'classnames';

import { getApiBase } from '../../env';

import cls from './regionIcon.module.scss'

interface IIconProps {
    url: string
    className?: string
    onClick?: () => void
}




const RegionIcon = (props: IIconProps) => {

    const {url, className, onClick} = props

    const fileUrl = `${getApiBase()}${url}`

    return (
        // <Icon component={<svg/>} className={className || undefined} onClick={onClick || undefined} />
        <div className={cn([cls.regionIcon, className])} onClick={onClick || undefined} style={{backgroundImage: `url(${fileUrl})`}}/>
        // <img src={fileUrl} alt={'Region'} className={cn([cls.regionIcon, className])} onClick={onClick || undefined} />
    )
}

export default RegionIcon