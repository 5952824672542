import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import {Space} from 'antd';
import classNames from 'classnames';
import {FEEDBACK, PRESENTATION} from 'shared/config/routes';

import cls from './MainFooter.module.scss';

export const MainFooter = ({ className = '' }) => {

    const { t } = useTranslation()

    return (
        <footer className={classNames(cls.MainFooter, {}, [className])}>
            <Space size={48}>
                <Link to={PRESENTATION}>{t('Platform presentation')}</Link>
                <Link to={FEEDBACK}>{t('Ask a question')}</Link>
            </Space>
        </footer>
    )};
