import {useEffect} from 'react';
import {useInstance} from 'react-ioc';
import {Store} from 'app/store/Store';
import { observer } from 'mobx-react-lite';
import {useLayout} from 'shared/hooks/useTrainerLayout';

function NeedAuth () {

    const {setLayout} = useLayout();
    const {auth} = useInstance(Store);

    useEffect(() => {
        setLayout({
            header: false,
            footer: false,
            bg: true,
            title: null,
        });
    }, [setLayout]);

    useEffect(() => {
        auth.openAuth()
    }, [auth])

    return null;
};

export default observer(NeedAuth);