import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {Button, Form, Input} from 'antd';
import {Store} from 'app/store/Store';
import {observer} from 'mobx-react-lite';
import {INDEX} from 'shared/config/routes';
import {feedback, feedbackVariables} from 'shared/graphql/__generated__/feedback';
import {MUTATION_FEEDBACK} from 'shared/graphql/feedback';
import {emailRegex} from 'shared/helpers/regex';
import {ModalSuccess} from 'widgets/Feedback/ModalSuccess';

interface IFormData {
    name: string;
    email: string;
    subject: string;
    message: string;
}

interface IFeedback {
    platform: string
}

export const Feedback = observer(({platform}:IFeedback) => {

    const {auth} = useInstance(Store);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
    const [feedback, feedbackData] = useMutation<feedback, feedbackVariables>(MUTATION_FEEDBACK)


    const initData = {
        name: auth.data?.firstName,
        email: auth.data?.email,
    }

    const onFinish = async (values: IFormData) => {
        await feedback({
            variables: {...values, subject: ''},
            onCompleted: () => {
                setIsSuccessOpen(true)
            }
        })
    }

    const onOk = () => {
        setIsSuccessOpen(false)
        navigate(INDEX)
    }

    return (
        <Form layout={'vertical'} onFinish={onFinish} validateTrigger={'onSubmit'} initialValues={initData}>

            <Form.Item label={t('Name')} name={'name'}
                       rules={[
                           {required: true, message: `${t('Please enter the data')}`},
                       ]}>
                <Input/>
            </Form.Item>

            <Form.Item label={t('Email')} name={'email'}
                       rules={[
                           {required: true, message: `${t('Please enter the data')}`},
                           {pattern: emailRegex, message: `${t('Please enter correct email')}`},
                       ]}>
                <Input placeholder="example@site.com"/>
            </Form.Item>

            <Form.Item label={t('Message')} name={'message'}
                       rules={[
                           {required: true, message: `${t('Please enter the data')}`},
                       ]}>
                <Input.TextArea autoSize={{minRows: 2, maxRows: 6}}/>
            </Form.Item>

            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} loading={feedbackData.loading}>
                    {t('Send')}
                </Button>
            </Form.Item>

            <ModalSuccess open={isSuccessOpen} onOk={onOk} platform={platform}/>

        </Form>
    );
});