
import img from './pattern.svg'

import cls from './Agreement.module.scss'

interface IAgreementHeaderProps {
    platform: string,
    UID: string,
    date: string
}

export const AgreementHeader = ({platform, UID, date}:IAgreementHeaderProps) => (
        <div className={cls.header} style={{backgroundImage: `url(${img})`}}>
            <div className={cls.platform}>{platform}</div>
            <div className={cls.UID}>{UID}</div>
            <div className={cls.date}>{date}</div>
        </div>
    )