import {useInstance} from 'react-ioc';
import {Store} from 'app/store/Store';
import {observer} from 'mobx-react-lite';
import {FavoritesAssets} from 'pages/Account/Favorites/FavoritesAssets';
import {FavoritesOffers} from 'pages/Account/Favorites/FavoritesOffers';

export const Favorites = observer(() => {

    const { filters } = useInstance(Store);

    if (filters.type === 'invest') {
        return <FavoritesOffers/>
    } else {
        return <FavoritesAssets/>
    }
})