import {useTranslation} from 'react-i18next';
import {ISlide} from 'pages/Pres/Pres';

import ava1 from './images/ava1.jpg';
import ava2 from './images/ava2.jpg';
import ava3 from './images/ava3.jpg';
import ava4 from './images/ava4.jpg';
import ava5 from './images/ava5.jpg';

import cls from './Pres.module.scss';

export const Slide6 = ({platform}:ISlide) => {

    const {t} = useTranslation();

    return (
        <div className={cls.slide}>
            <div className={cls.headline}>
                {t('Who we see as partners of {{platform}}', {platform: platform})}
            </div>
            <div className={cls.content}>
                <div className={cls.slide3items}>

                    <div className={cls.item}>
                        <div className={cls.ava}><img src={ava2} alt={'avatar'}/></div>
                        <div>
                            {t('the economic development of the region/territory decision-makers')}
                        </div>
                    </div>

                    <div className={cls.item}>
                        <div className={cls.ava}><img src={ava1} alt={'avatar'}/></div>
                        <div>
                            {t('owners of assets without financial encumbrances (pledges)')}
                        </div>
                    </div>

                    <div className={cls.item}>
                        <div className={cls.ava}><img src={ava3} alt={'avatar'}/></div>
                        <div>
                            {t('investment funds')}
                        </div>
                    </div>

                    <div className={cls.item}>
                        <div className={cls.ava}><img src={ava4} alt={'avatar'}/></div>
                        <div>
                            {t('financial managers')}
                        </div>
                    </div>

                    <div className={cls.item}>
                        <div className={cls.ava}><img src={ava5} alt={'avatar'}/></div>
                        <div>
                            {t('private investors (individuals)')}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}