import react from 'react';

import cls from './Cards.module.scss'

interface ICardsProps {
    children?: react.ReactNode;
}

export const Cards = (props: ICardsProps) => {

    const {children} = props

    return (
        <div className={cls.wrapper}>
            {children}
        </div>
    );

}