import {useTranslation} from 'react-i18next';
import img_deu from 'pages/Pres/images/scheme-deu.jpg';
import img_eng from 'pages/Pres/images/scheme-eng.jpg';
import img_esp from 'pages/Pres/images/scheme-esp.jpg';
import img_fra from 'pages/Pres/images/scheme-fra.jpg';
import img_kaz from 'pages/Pres/images/scheme-kaz.jpg';
import img_rus from 'pages/Pres/images/scheme-rus.jpg';
import img_tur from 'pages/Pres/images/scheme-tur.jpg';

import cls from './Pres.module.scss';

export const Slide3 = () => {

    const {t, i18n} = useTranslation();

    let src = img_eng;

    switch (i18n.language) {
        case 'rus':
            src = img_rus;
            break;
        case 'eng':
            src = img_eng;
            break;
        case 'tur':
            src = img_tur;
            break;
        case 'kaz':
            src = img_kaz;
            break;
        case 'fra':
            src = img_fra;
            break;
        case 'deu':
            src = img_deu;
            break;
        case 'esp':
            src = img_esp;
            break;
    }

    return (
        <div className={cls.slide}>
            <div className={cls.headline}>
                {t('Algorithm of "+1 Tool" of financing for the asset market')}
            </div>
            <div className={cls.content}>

                <div className={cls.slide3content}>
                    <div>
                        <img className={cls.img} src={src} alt={'Cover'}/>

                    </div>
                    <div className={cls.slide3items}>

                        <div className={cls.item}>
                            <div className={cls.title}>{'1'}</div>
                            <div>
                                {t('client meets seller\'s offer in the market')}
                            </div>
                        </div>

                        <div className={cls.item}>
                            <div className={cls.title}>{'2'}</div>
                            <div>
                                {t('client makes an offer to investor')}
                            </div>
                        </div>

                        <div className={cls.item}>
                            <div className={cls.title}>{'3'}</div>
                            <div>
                                {t('investor accepts client\'s offer')}
                            </div>
                        </div>

                        <div className={cls.item}>
                            <div className={cls.title}>{'4'}</div>
                            <div>
                                {t('client and investor buy shares from seller')}
                            </div>
                        </div>

                        <div className={cls.item}>
                            <div className={cls.title}>{'5'}</div>
                            <div>
                                {t('investor leases his share to client')}
                            </div>
                        </div>

                        <div className={cls.item}>
                            <div className={cls.title}>{'6'}</div>
                            <div>
                                {t('investor gradually sells his share to client')}
                            </div>
                        </div>

                        <div className={cls.item}>
                            <div className={cls.title}>{'7'}</div>
                            <div>
                                {t('client buys investor\'s share, cost of rent decreases')}
                            </div>
                        </div>




                    </div>
                </div>

            </div>
        </div>
    )
}