import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {
    CarOutlined, CheckOutlined, CloseOutlined,
    DownloadOutlined, EditOutlined,
    HistoryOutlined,
    HomeOutlined,
    MoreOutlined,
    RiseOutlined,
} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Button, Dropdown} from 'antd';
import classNames from 'classnames';
// import { ReactComponent as IconFlat } from 'shared/assets/icons/iconFlat.svg';
import {editOffer, goToOffer} from 'shared/config/routes';
import {offers_offers_data} from 'shared/graphql/__generated__/offers';
import {CurrencyFormat} from 'shared/helpers/CurrencyFormat';
import {SultanAvatar} from 'widgets/Avatar/SultanAvatar';
import ExcavatorOutlined from 'widgets/Icons/ExcavatorOutlined';

import cls from './OfferCard.module.scss';


interface IOfferCardProps {
    className?: string;
    offer: offers_offers_data;
    onPublish: () => void;
    onClose: () => void;
}

export const AccountOfferCard = memo(function OfferCard(props: IOfferCardProps) {
    const {className, offer, onClose, onPublish} = props;

    const {t} = useTranslation();
    const navigate = useNavigate();
    
    let type
    switch (offer.attributes?.type) {
        case 'estate':
            type = <span><HomeOutlined className={cls.icon}/> {t('Real estate')}</span>;
            break;
        case 'tech':
            type = <span><ExcavatorOutlined className={cls.icon}/> {t('Construction vehicles')}</span>;
            break;
        case 'car':
            type = <span><CarOutlined className={cls.icon}/> {t('Transport')}</span>;
            break;
    }

    const items: MenuProps['items'] = [
        {
            key: 'edit',
            label: <Link to={editOffer(offer.attributes?.UID!)} className={cls.bold}>{t('Edit')}</Link>,
            icon: <EditOutlined/>
        },
        ...(offer?.attributes?.status === 'PUBLIC_2' ? [
            {
                key: 'close',
                label: t('Remove from published'),
                icon: <CloseOutlined/>,
                onClick: onClose,
            }
        ] : [
            {
                key: 'publish',
                label: t('Publish'),
                icon: <CheckOutlined/>,
                onClick: onPublish,
            }
        ]),
    ];



    return (
        <div className={classNames(cls.offerCard, {}, [className])}>
            <div className={cls.left} onClick={() => navigate(goToOffer(offer.attributes?.UID || ''))}>
                <span className={cls.title}>
                    <RiseOutlined/>
                    <span className={cls.percent}>{offer.attributes?.incomePercent} %</span>
                </span>
                <span className={cls.subTitle}>
                        <DownloadOutlined
                            className={cls.icon}/> {CurrencyFormat(offer.attributes?.investmentSum || 0)} {offer.attributes?.currency?.data?.attributes?.name}
                    </span>
                <span className={cls.subTitle}>
                         {type}
                    </span>
                <span className={cls.subTitle}>
                        <HistoryOutlined className={cls.icon}/> {offer.attributes?.totalDuration} {t('Months')}
                    </span>
            </div>
            <div className={cls.right}>
                <div className={cls.rightTop}>
                    <Dropdown menu={{items}}>
                        <Button type={'text'} icon={<MoreOutlined/>}/>
                    </Dropdown>
                </div>
                <div className={cls.rightBottom}>
                    {offer.attributes?.author?.data &&
                        <SultanAvatar
                            size={40}
                            color={offer.attributes?.author?.data?.attributes?.color || '#ffffff'}
                            photo={offer.attributes?.author?.data?.attributes?.photo || undefined}
                            firstName={offer.attributes?.author?.data?.attributes?.firstName || ''}
                            lastName={offer.attributes?.author?.data?.attributes?.lastName || ''}
                        />
                    }
                </div>

            </div>
        </div>
    );
});