import React from 'react';

import cls from './IndexLayout.module.scss'

interface IIndexlayoutProps {
    children?: React.ReactNode
    filterElement?: React.ReactNode
    typeElement?: React.ReactNode
    modeElement?: React.ReactNode
}

const IndexLayout = ({children, filterElement, typeElement, modeElement}: IIndexlayoutProps) => (
        <div className={cls.IndexPage}>
            <div className={cls.header}>
                <div className={cls.left}>
                    {filterElement}
                </div>
                <div className={cls.right}>
                    {modeElement}
                    {typeElement}
                </div>
            </div>

            <div>
                {children}
            </div>
        </div>

    )

export default IndexLayout