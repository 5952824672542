import {useTranslation} from 'react-i18next';
import {Feedback} from 'features/Feedback/Feedback';
import {ISlide} from 'pages/Pres/Pres';

import cls from './Pres.module.scss';

export const Slide9 = ({platform}:ISlide) => {

    const {t} = useTranslation();

    return (
        <div className={cls.slide}>
            <div className={cls.headline}>
                {t('We are open for communication through the feedback form')}
            </div>
            <div className={cls.content}>
                
                <Feedback platform={platform}/>
                
            </div>
        </div>
    )
}