import {gql} from '@apollo/client';

export const UPDATE_ASSET = gql`
    mutation updateAsset ($data: AssetInput!, $UID: ID!) {
        updateAsset (UID: $UID, data: $data) {
            data {
                id
            }
        }
    }
`