import {useTranslation} from 'react-i18next';

import { IBar } from './Chat';
import img from './pattern-blue.svg'

import cls from './Bar.module.scss';

export const Bar = ({text}: IBar) => {

    const {t} = useTranslation()

    return (
        <div className={cls.bar} style={{backgroundImage: `url(${img})`}}>
            <div className={cls.title}>
                {t(text)}
            </div>
        </div>
    )
}