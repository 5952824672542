/* eslint-disable */

import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
// import detector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';
import getLang from 'shared/helpers/getLang';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: getLang(),
        fallbackLng: getLang(),
        debug: false,
        interpolation: {
            escapeValue: false,
        },

        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
    });


export default i18n;
