import {useTranslation} from 'react-i18next';
import {CheckOutlined} from '@ant-design/icons';
import {Button, Modal} from 'antd';
import cn from 'classnames';

import cls from './modal.module.scss';

interface IModalProps {
    open: boolean;
    onOk: () => void;
    platform: string;
}

export const ModalSuccess = ({open, onOk, platform}: IModalProps) => {
    
    const { t } = useTranslation()
    
    return (
        <Modal title={undefined} open={open} footer={[]} closable={false}>
            <div className={cls.modal}>
                <CheckOutlined className={cn([cls.icon, cls.success])}/>
                <p>{t('Thank you for your message!')}</p>
                <p>{t('We will answer within 24 hours.')}</p>
                <p></p>
                <p>{t('{{platform}} Team', {platform: platform})}</p>
                <Button type={'primary'} onClick={onOk} className={cls.button}>{t('OK')}</Button>
            </div>
        </Modal>
    )
}