import {gql} from '@apollo/client';

export const DECLINE_DIALOG = gql`
    mutation declineDialog (
        $UID: ID!,
    ) {
        declineDialog (UID: $UID) {
            UID
        }
    }
`