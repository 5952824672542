import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {Button, Form, Input, message, Modal} from 'antd';
import { Store } from 'app/store/Store';
import useGetCodeMutation from 'features/Auth/hooks/useGetCodeMutation';
import {IGetCodeFormValues} from 'features/Auth/model/form';
import {observer} from 'mobx-react-lite';
import {emailRegex} from 'shared/helpers/regex';



export const ModalEmail = observer(() => {

    const { auth } = useInstance(Store);
    const { t, i18n } = useTranslation();

    const getCode = useGetCodeMutation()
    const [loading, setLoading] = useState<boolean>(false)

    const onSubmit = async (values: IGetCodeFormValues) => {
        try {

            setLoading(true)

            const email = values.email;
            const lang = i18n.language;

            const res = await getCode(email, lang)
            const done = res.data?.getCode?.done;
            if (done) {
                auth.enterCode(email)
            }
            setLoading(false)

        } catch (error: any) {
            message.error(error.message)
            console.error(error)
            setLoading(false)
        }
    };

    return (
        <Modal
            open={auth.modalEmailOpen || false}
            onCancel={() => auth.closeModalEmail()}
            footer={[]}
            title={t('Log in/Register')}
        >
            <Form layout={'vertical'} onFinish={onSubmit} validateTrigger={'onSubmit'}>

                <Form.Item label={t('Email')} name={'email'}
                           rules={[
                               {required: true, message: `${t('Please enter the data')}`},
                               {pattern: emailRegex, message: `${t('Please enter correct email')}`},
                           ]}>
                    <Input placeholder="example@site.com"/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType={'submit'} loading={loading} disabled={loading}>
                        {t('Get a code')}
                    </Button>
                </Form.Item>

            </Form>
        </Modal>
    );
});