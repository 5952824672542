import _, {toInteger} from 'lodash';
import {IPinContent} from 'pages/Index/types';
import {assets_assets_data} from 'shared/graphql/__generated__/assets';

export function ConvertAssetsToPin (assets: assets_assets_data[]) {

    const groups = _.groupBy(assets, 'attributes.asset.type')

    let result:IPinContent[] = []

    _.forEach(groups, (group, key) => {
        result.push({
            type: key,
            count: group.length,
            totalCost: _.reduce(group, (r, i) => r + toInteger(i.attributes?.asset.cost), 0),
        })
    })

    return  result

}