import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {WarningOutlined} from '@ant-design/icons';
import {Button, Divider} from 'antd';
import {Store} from 'app/store/Store';
import classNames from 'classnames';
import {PaymentTable} from 'features/Offer';
import {observer} from 'mobx-react-lite';
import {CurrencyFormat} from 'shared/helpers/CurrencyFormat';
import {OfferData} from 'widgets/Offer';
import {ModalConfirmPublish} from 'widgets/Offer/ui/ModalConfirmPublish';

import cls from 'features/Offer/ui/graph/OfferGraph.module.scss';

interface IFormResultProps {
    onPublish: () => void;
    onDraft: () => void;
    maxPeriod: number,
    minProfit: number,
    loading?: boolean,
    disabled?: boolean,
}

export const FormResultOverAsset = observer(({onPublish, onDraft, minProfit, maxPeriod, loading, disabled}: IFormResultProps) => {

    const {t} = useTranslation();

    const store = useInstance(Store);
    const {offer} = store;
    const {isHasChanges, calculatedValues, isCalculated} = offer;
    const {dealCost, investSellProfit, investRentProfit, paymentTable, dealPeriod} = calculatedValues;

    const [isTableOpen, setIsTableOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);


    const openTable = () => {
        if (isCalculated) {
            setIsTableOpen(true);
        }
    };

    const closeTabel = () => {
        setIsTableOpen(false);
    };

    const onClickPublish = () => {
        if (checkWarning()) {
            setConfirmOpen(true)
        } else {
            onPublish()
        }
    }

    const onConfirmPublish = () => {
        setConfirmOpen(false)
        onPublish()
    }

    const onClickDraft = () => {
        onDraft()
    }

    function checkWarning () {
        const investorExpectedEarningsMonth = minProfit / maxPeriod
        const investorProposedEarningsMonth = (investRentProfit + investSellProfit) / dealPeriod
        return  investorProposedEarningsMonth < investorExpectedEarningsMonth
    }


    return (
        <div className={cls.calculated}>

            <OfferData name={t('Expected income')} value={CurrencyFormat(minProfit)} status={checkWarning() ? undefined : 'success'}/>

            {checkWarning() &&
                <>
                    <OfferData name={<WarningOutlined/>} value={t('Your offer does not meet the seller\'s expectations in terms of income')}
                               status={'warning'}/>
                </>
            }
            <Divider className={cls.divider}/>


            <OfferData name={t('Investor\'s earnings (buying&selling)')} value={CurrencyFormat(investSellProfit)}/>
            <Divider className={cls.divider}/>
            <OfferData name={t('Investor\'s earnings (renting out)')} value={CurrencyFormat(investRentProfit)}/>
            <Divider className={cls.divider}/>

            <OfferData
                name={t('Payment frequency')}
                value={
                    <span
                        onClick={openTable}
                        className={classNames([cls.getTabel], {[cls.disabled]: !paymentTable})}
                    >
                                {t('Review')}
                            </span>
                }
            />
            <Divider className={cls.divider}/>
            <OfferData name={t('The amount you pay for 100% of the asset')} value={CurrencyFormat(dealCost)}/>

            {!disabled &&
                <>
                    <Divider className={cls.divider}/>

                    <div className={cls.buttonsRow}>
                        <Button
                            size={'large'}
                            type={'primary'}
                            onClick={onClickPublish}
                            disabled={!(isCalculated && isHasChanges)}
                            loading={loading}
                        >
                            {t('Publish')}
                        </Button>
                        <Button
                            size={'large'}
                            onClick={onClickDraft}
                            disabled={!(isCalculated && isHasChanges)}
                            loading={loading}
                        >
                            {t('Save draft')}
                        </Button>
                    </div>
                </>
            }

            <ModalConfirmPublish open={confirmOpen} onOk={onConfirmPublish} onCancel={() => setConfirmOpen(false)}/>
            <PaymentTable isOpen={isTableOpen} onCancel={closeTabel}/>

        </div>
    );
});