import {gql} from '@apollo/client';

export const QUERY_ASSET = gql`
    query asset ($UID: ID!) {
        asset (UID: $UID) {
            id
            attributes {
                UID
                asset {
                    type
                    country {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    region {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    cost
                    currency {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    link
                }
                minProfit
                maxPeriod
                author {
                    data {
                        id
                        attributes {
                            UID
                            firstName
                            lastName
                            color
                            photo
                        }
                    }
                }
            }
        }
    }
`