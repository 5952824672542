import {gql} from '@apollo/client';

export const UPDATE_OFFER = gql`
    mutation updateOffer ($data: OfferInput!, $UID: ID!) {
        updateOffer (UID: $UID, data: $data) {
            data {
                id
            }
        }
    }
`