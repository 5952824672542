import {gql} from '@apollo/client';

export const CONTINUE_DIALOG = gql`
    mutation continueDialog (
        $UID: ID!,
    ) {
        continueDialog (UID: $UID) {
            UID
        }
    }
`