import React from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import ym from 'react-yandex-metrika';
import {Avatar, Button,Divider, Dropdown, theme} from 'antd';
import {Store} from 'app/store/Store';
import {observer} from 'mobx-react-lite';
import { INDEX } from 'shared/config/routes';

import cls from './profile.module.scss'

const { useToken } = theme;

export const AvatarMenu = observer(() => {

    const {auth} = useInstance(Store);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const { token } = useToken();

    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    };

    const letter = `${auth.data?.firstName.charAt(0)}${auth.data?.lastName.charAt(0)}`
    
    const onClickProfile = () => {
        auth.fillAnket();
        ym('reachGoal', 'click_profile');
    }

    const onLogOut = () => {
        auth.clearState();
        navigate(INDEX);
    }

    return (
        <div>
            <Dropdown
                placement="bottomRight"
                dropdownRender={() => (
                    <div style={contentStyle} className={cls.menuContent}>
                        <div className={cls.menuHeadline}>{auth.data?.firstName} {auth.data?.lastName}</div>
                        <div className={cls.menuEmail}>{auth.data?.email}</div>
                        <Button type={'link'} onClick={onClickProfile}>{t('User profile')}</Button>
                        <Divider style={{ margin: 0 }} />
                        <Button type={'link'} onClick={onLogOut}>{t('Log out')}</Button>
                    </div>
                )}
            >
                <Avatar style={{backgroundColor: auth.data?.photo ? '#FFFFFF' : auth.data?.color}} src={auth.data?.photo || undefined}>{letter}</Avatar>
            </Dropdown>
        </div>
    );
});