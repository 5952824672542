
import cls from './Agreement.module.scss'

export interface IStamp {
    platformUrl: string,
    registerUrl: string,
    UID: string,
}
export const AgreementStamp = ({platformUrl, registerUrl, UID}:IStamp) => (
        <div className={cls.stamp}>
            <div>{`Certification Authority / Удостоверяющий центр: ${platformUrl}`}</div>
            <div>{`Public Register / Публичный реестр: ${registerUrl}`}</div>
            <div>{`Record of the Agreement conclusion in the Register of the Certification Authority / Запись о заключении Соглашения в реестре удостоверяющего центра: ${UID}`}</div>
        </div>
    )