import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {Link} from 'react-router-dom';
import {LoginOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import type {MenuProps} from 'antd';
import {Button, ConfigProvider, Dropdown, Tooltip} from 'antd';
import {Store} from 'app/store/Store';
import classNames from 'classnames';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import {ASSET_CREATE,CALC} from 'shared/config/routes';
import { dialogs } from 'shared/graphql/__generated__/dialogs';
import {QUERY_DIALOGUES} from 'shared/graphql/queryDialogues';
import {LanguageSelect} from 'shared/ui/LanguageSelect/LanguageSelect';
import {LogoHeader} from 'widgets/Logo/ui/LogoHeader';
import {AvatarMenu} from 'widgets/Profile/AvatarMenu';
import {Menu} from 'widgets/Profile/Menu';

import cls from './Header.module.scss';

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

const Header = ({className = ''}) => {

    const {t} = useTranslation();
    const {auth, dialogs} = useInstance(Store);

    const query = useQuery<dialogs>(QUERY_DIALOGUES, {
        pollInterval: 5000,
        skip: !auth.hasProfile,
        onCompleted: () => {dialogs.setLoading(false)},
    })

    const unredMessages =
        _.reduce(query.data?.dialogs?.buying, function(sum, n) {return sum + n?.unreadMessages!;}, 0) +
        _.reduce(query.data?.dialogs?.investing, function(sum, n) {return sum + n?.unreadMessages!;}, 0) +
        _.reduce(query.data?.dialogs?.selling, function(sum, n) {return sum + n?.unreadMessages!;}, 0)

    const menuSubmit: MenuProps['items'] = [
        {
            key: 'buy',
            label: (<Link to={CALC}>{t('Buy an asset?')}</Link>),
        },
        {
            key: 'sell',
            label: (<Link to={ASSET_CREATE}>{t('Sell an asset?')}</Link>),
        },
    ];

    const buttonSubmit =
        <Dropdown menu={{items: menuSubmit}} trigger={['click']}>
            <Button className={cls.buttonSubmit} type={'primary'}>{t('Submit the offer')}</Button>
        </Dropdown>

    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        dialogs.setData(query.data?.dialogs)
    }, [query, dialogs])

    return (
        <div className={cls.container}>
            <header className={classNames(cls.header, {}, [className])}>
                <div className={cls.topRow}>
                    <div className={cls.left}>
                        {auth.hasProfile
                            ?
                            <Menu unreadMessages={unredMessages}/>
                            :
                            <LanguageSelect/>
                        }
                        <LogoHeader width={width}/>
                    </div>
                    <div className={cls.right}>
                        {width >= 500 && buttonSubmit}
                        {auth.hasProfile
                            ?
                            <AvatarMenu/>
                            :
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorTextLightSolid: '#ffffff',
                                    },
                                }}
                            >
                                <Tooltip title={t('Log in/Register')}>
                                    <Button type={'text'} icon={<LoginOutlined/>} onClick={() => auth.openAuth()}/>
                                </Tooltip>
                            </ConfigProvider>
                        }
                    </div>
                </div>
                {width < 500 && <div className={cls.bottomRow}>{buttonSubmit}</div>}
            </header>

        </div>
    );
};

export default observer(Header)
