import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import {getCode, getCodeVariables} from 'features/Auth/__generated__/getCode';

const GETCODE_MUTATION = gql`
    mutation getCode ($email: String!, $lang: String!) {
        getCode(email: $email, lang: $lang) {
            done
        }
    }
`;
const useGetCodeMutation = () => {
    const [getCodeMustation] = useMutation<getCode, getCodeVariables>(GETCODE_MUTATION);


    return useCallback(
        async (email: string, lang: string) => getCodeMustation({
                variables: {
                    email,
                    lang,
                },
            }),
        [getCodeMustation],
    );
};

export default useGetCodeMutation;
