import {gql} from '@apollo/client';

export const SIGN_AGREEMENT_INVESTOR = gql`
    mutation signAgreementInvestor (
        $UID: ID!,
    ) {
        signAgreementInvestor (UID: $UID) {
            UID
        }
    }
`