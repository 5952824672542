import {useCallback, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {useLayout} from 'shared/hooks/useTrainerLayout';

import cls from './Timer.module.scss';

const releaseDate = dayjs('2023-06-20');

export function Timer() {
    // const { t } = useTranslation();

    const [estimateSeconds, setEstimateSeconds] = useState(releaseDate.diff(dayjs(), 'seconds'));

    const choose = useCallback(() => {
        setEstimateSeconds(dayjs().diff(releaseDate, 'seconds'));
    }, []);

    const { setLayout } = useLayout();

    useEffect(() => {
        const ref = setInterval(() => choose(), 1000); // Refresh all of the state every 5 seconds
        return () => {
            clearInterval(ref);
        };
        // This is a mount effect because its dependency list is empty
    }, [choose]);

    const text = estimateSeconds.toLocaleString('ru-Ru');

    useEffect(() => {
        setLayout({
            title: null,
        });
    }, [setLayout]);

    return (
        <div className={cls.container}>
            <div className={cls.headline}>
                {text.slice(0, -1)}
                <span className={cls.last}>{text.slice(-1)}</span>
            </div>
        </div>
    );
}