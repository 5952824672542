import {Skeleton} from 'antd';
import {Cards} from 'app/Layout/Cards';
import _ from 'lodash';
import {offers_offers_data} from 'shared/graphql/__generated__/offers';
import {OfferCard} from 'widgets/OfferCard';
import {EmptyCard} from 'widgets/OfferCard/ui/EmptyCard';

interface IListProps {
    offers: offers_offers_data[]
    loading?: boolean
}

export const List = (props: IListProps) => {

    const {offers, loading} = props

    if (loading) return <Skeleton active/>

    return (
        <Cards>

            {offers.length === 0 && <EmptyCard maxInRow={4}/>}

            {_.map(offers, (item) => (
                <OfferCard key={item.id} offer={item} maxInRow={4}/>
            ))}

        </Cards>
    )
}