import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
// import ym from 'react-yandex-metrika';
import {Button} from 'antd';
import {Store} from 'app/store/Store';
// import {useCreateDialog} from 'features/Dialog/hook/useCreateDialog';
import {observer} from 'mobx-react-lite';
import { goToDialog } from 'shared/config/routes';
import {continueDialog, continueDialogVariables} from 'shared/graphql/__generated__/continueDialog';
import {createDialog, createDialogVariables} from 'shared/graphql/__generated__/createDialog';
import {declineDialog, declineDialogVariables} from 'shared/graphql/__generated__/declineDialog';
import {CONTINUE_DIALOG} from 'shared/graphql/continueDialog';
import {CREATE_DIALOG} from 'shared/graphql/createDialog';
import {DECLINE_DIALOG} from 'shared/graphql/declineDialog';


interface IOfferDialog {
    offerUID: string
    isClient: boolean
}


export const OfferDialog = observer(({offerUID, isClient}:IOfferDialog) => {

    const {auth, dialogs, price} = useInstance(Store);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [continueDialog] = useMutation<continueDialog,continueDialogVariables>(CONTINUE_DIALOG)
    const [declineDialog] = useMutation<declineDialog,declineDialogVariables>(DECLINE_DIALOG)
    const [createDialog] = useMutation<createDialog, createDialogVariables>(CREATE_DIALOG)


    // const createDialog = useCreateDialog({offer: id || '', message: message})

    // const onClickSend = async () => {
    //     await createDialog();
    //     ym('reachGoal', 'click_dialog');
    // }

    // для всех неавторизованных рисуем кнопку "Начать диалог"
    if (!auth.hasProfile) {
        return <Button type={'primary'} size={'large'} onClick={() => auth.openAuth()}>{t('Start Dialogue')}</Button>
    }

    // для клиента
    if (isClient) {
        const dialog = dialogs.statusInBuying(offerUID)
        // если есть активный диалог
        if (dialog && (dialog.status === 'created' || dialog.status === 'active')) {
            return <Button size={'large'} onClick={() => navigate(goToDialog(dialog.UID))}>{t('Go to Dialogue')}</Button>
        }
    }

    const onContinueDialog = async (UID: string) => {
        await continueDialog({
            variables: {UID: UID},
            onCompleted: () => {
                // todo пока беплатно просто перекидываем на диалог
                navigate(goToDialog(UID))
            }
        })
    }

    const onDeclineDialog = async (UID: string) => {
        await declineDialog({
            variables: {UID: UID},
        })
    }

    const onCreateDialog = async () => {
        await createDialog({
            variables: {
                offer: offerUID,
            },
            onCompleted: (data) => {
                // todo пока бесплатно создаем и перекидываем на созданный
                navigate(goToDialog(data.createDialog?.UID!))
            }
        })
    }


    // для инвестора/продавца
    if (!isClient) {
        // для продавца
        const dialogSelling = dialogs.statusInSelling(offerUID)
        // если есть диалог в статусе создан
        if (dialogSelling && dialogSelling.status === 'created') {
            return (
                <>
                    {/* eslint-disable-next-line no-template-curly-in-string */}
                    <Button size={'large'} type={'primary'} onClick={() => onContinueDialog(dialogSelling.UID)}>{t('Continue Dialogue — ${{X}}', {X: price.createDialog})}</Button>
                    <Button size={'large'} onClick={() => onDeclineDialog(dialogSelling.UID)}>{t('Decline the Offer')}</Button>
                </>
            )
        }
        // если есть диалог в статусе актив
        if (dialogSelling && dialogSelling.status === 'active') {
            return <Button size={'large'} onClick={() => navigate(goToDialog(dialogSelling.UID))}>{t('Go to Dialogue')}</Button>
        }

        // для инсвестора
        const dialog = dialogs.statusInInvesting(offerUID)
        // если есть диалог в статусе актив
        if (dialog && dialog.status === 'active') {
            return <Button size={'large'} onClick={() => navigate(goToDialog(dialog.UID))}>{t('Go to Dialogue')}</Button>
        } else {
            // eslint-disable-next-line no-template-curly-in-string
            return <Button type={'primary'} size={'large'} onClick={onCreateDialog}>{t('Start Dialogue – ${{X}}', {X: price.createDialog})}</Button>
        }

    }
    
    return null

});