import {gql} from '@apollo/client';

export const QUERY_COUNTRIES = gql`
    query countries (
        $filters: CountryFiltersInput,
        $pagination: PaginationArg = {},
        $sort: [String] = [],
        $regionsFilters: RegionFiltersInput,
        $regionsPagination: PaginationArg = {},
        $currenciesFilters: CurrencyFiltersInput,
        $currenciesPagination: PaginationArg = {},
    ) {
        countries (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                    map {
                        latitude
                        longitude
                    }
                    regions (filters: $regionsFilters, pagination: $regionsPagination) {
                        data {
                            id
                            attributes {
                                name
                                map {
                                    latitude
                                    longitude
                                }
                                logo {
                                    data {
                                        attributes {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                    code2
                    currencies (filters: $currenciesFilters, pagination: $currenciesPagination) {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`