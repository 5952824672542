import {useState} from 'react';
import {Outlet} from 'react-router-dom';
import LanguageProvider from 'app/provider/LanguageProvider';
import {Auth} from 'features/Auth';
import Header from 'widgets/ClientHeader/Header';
import {MainFooter} from 'widgets/MainFooter/MainFooter';

import bg_img from './bg-tile.png';

import cls from './Layout.module.scss';

export default function Layout() {

    const [state, setState] = useState({
        header: true,
        title: null,
        footer: true,
        bg: false,
    });

    let bgStyle = {}

    if (state.bg) {
        bgStyle = {
            backgroundImage: `url(${bg_img})`,
        }
    }

    return (
        <LanguageProvider>
            <div className={cls.background} style={bgStyle}>
                <Auth/>
                {state.header === false ? null : <Header/>}
                <div className={cls.layout}>

                    {state.title}

                    <div className={cls.content}>
                        <Outlet context={{setLayout: setState}}/>
                    </div>

                </div>
                {state.footer === false ? null : <MainFooter/>}
            </div>
        </LanguageProvider>
    );
}
