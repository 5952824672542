import { useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';

import { ISlideProps } from './types';

import cls from './slides.module.scss';

export default function One(props:ISlideProps) {

    const {width, height} = props

    const {t} = useTranslation()

    const sourceTxt = t('Are you interested in an apartment or a vehicle?'); /* The text */

    const [txt, setTxt] = useState('');

    // eslint-disable-next-line
    const type = () => {
        const letter = sourceTxt.substring(txt.length, sourceTxt.length).charAt(0);
        setTxt(txt + letter);
    };

    useEffect(() => {
        if (txt.length !== sourceTxt.length) {
            const ref = setInterval(() => type(), 30);
            return () => {
                clearInterval(ref);
            };
        }
    }, [type, txt, sourceTxt.length]);

    const slideStyle = {
        width: width,
        height: height,
        fontSize: height! / 18,
        padding: height! / 24,
    }

    const questionStyle = {
        marginTop: height! / 4,
    }

    const answerStyle = {
        marginTop: height! / 24,
    }

    return (
        <div style={slideStyle}>
            <div style={questionStyle}>{txt}</div>
            <div className={cls.fadeId} style={answerStyle}>{t('Make offer to investor')}</div>
        </div>
    );
}
