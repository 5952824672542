import {Skeleton} from 'antd';
import {Cards} from 'app/Layout/Cards';
import _ from 'lodash';
import {assets_assets_data} from 'shared/graphql/__generated__/assets';
import {AssetCard} from 'widgets/OfferCard/ui/AssetCard';
import {EmptyCard} from 'widgets/OfferCard/ui/EmptyCard';

interface IAssetsProps {
    data: assets_assets_data[]
    loading?: boolean
}

export const Assets = (props: IAssetsProps) => {

    const {data, loading} = props

    if (loading) return <Skeleton active/>

    return (
        <Cards>

            {data.length === 0 && <EmptyCard maxInRow={4}/>}

            {_.map(data, (item) => (
                <AssetCard key={item.id} asset={item} maxInRow={4}/>
            ))}

        </Cards>
    )
}