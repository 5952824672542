import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import { Skeleton} from 'antd';
import { Cards } from 'app/Layout/Cards';
import _ from 'lodash';
import {TypeElement} from 'pages/Index/ui/Layout/TypeElement';
import {assets, assetsVariables} from 'shared/graphql/__generated__/assets';
import {QUERY_ASSETS} from 'shared/graphql/queryAssets';
import {useLayout} from 'shared/hooks/useTrainerLayout';
import {AssetCard} from 'widgets/OfferCard/ui/AssetCard';
import {EmptyCard} from 'widgets/OfferCard/ui/EmptyCard';
import {PageTitle} from 'widgets/PageTitle';

export interface IUserListProps {
    UID: string,
}
export const UserListAssets = (props: IUserListProps) => {
    
    const {UID} = props;
    const {t} = useTranslation();
    const {setLayout} = useLayout();

    const query = useQuery<assets,assetsVariables>(QUERY_ASSETS, {
        variables: {
            filters: {
                author: {UID: {eq: UID}},
                status: {in: ['PUBLIC_2']},
            },
            pagination: {
                limit: 1000,
            }
        },
    })

    const firstName = (query.data && query.data?.assets?.data.length! > 0) ? query.data?.assets?.data[0].attributes?.author?.data?.attributes?.firstName : ''
    const lastName = (query.data && query.data?.assets?.data.length! > 0)  ? query.data?.assets?.data[0].attributes?.author?.data?.attributes?.lastName : ''

    useEffect(() => {
        setLayout({
            title: (
                <PageTitle title={`${firstName} ${lastName}`} control={<TypeElement/>}/>
            ),
        });
    }, [setLayout, t, firstName, lastName]);
    
    return (
        <Cards>
            {query.data?.assets?.data.length === 0 && <EmptyCard maxInRow={4}/>}
            {query.loading && <Skeleton active/>}

            {_.map(query.data?.assets?.data, (item) => (
                <AssetCard key={item.id} asset={item} maxInRow={4}/>
            ))}
        </Cards>
    );
}