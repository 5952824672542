interface IOfferCalcSellIncome {
    activeCost: number
    activePayment: number
    partCost: number
}

export function OfferCalcSellIncome (param:IOfferCalcSellIncome) {

    const {activeCost, activePayment, partCost} = param

    // сумма инвестиций
    const investSum = activeCost - activePayment

    // доход от купли продажи
    return partCost - investSum;

}