import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {Skeleton} from 'antd';
import {Store} from 'app/store/Store';
import {observer} from 'mobx-react-lite';
import {FormAsset} from 'pages/Asset/FormAsset';
import {IAsset} from 'pages/Asset/types';
// import {INDEX} from 'shared/config/routes';
import {asset, assetVariables} from 'shared/graphql/__generated__/asset';
import {QUERY_ASSET} from 'shared/graphql/queryAsset';
import {UPDATE_ASSET} from 'shared/graphql/updateAsset';
import {useLayout} from 'shared/hooks/useTrainerLayout';
import {PageTitle} from 'widgets/PageTitle';

import {ENUM_ASSET_STATUS} from '../../__generated__/global-types';

import cls from './style.module.scss'

export const EditAsset = observer(() => {

    const {setLayout} = useLayout();
    const {t} = useTranslation();
    const {UID} = useParams();
    // const navigate = useNavigate();
    const store = useInstance(Store);
    const {auth} = store;

    useEffect(() => {
        setLayout({
            title: (
                <PageTitle
                    title={t('Edit asset')}
                />
            ),
        });
    }, [setLayout, t]);

    const query = useQuery<asset, assetVariables>(QUERY_ASSET, {
        variables: {
            UID: UID || '',
        },
    })
    const [updateAsset, {loading}] = useMutation(UPDATE_ASSET)

    const onComplete = (data: IAsset) => {
        if (auth.hasProfile) {
            updateAsset({
                variables: {
                    UID: UID,
                    data: {
                        asset: {
                            type: data.type,
                            cost: data.cost,
                            currency: data.currency,
                            country: data.country,
                            region: data.region,
                            link: data.link,
                        },
                        status: ENUM_ASSET_STATUS.PUBLIC_2,
                        maxPeriod: data.maxPeriod,
                        minProfit: Number(data.minProfit),
                    }
                },
                onCompleted: () => {
                    // query.refetch()
                    // navigate(INDEX)
                }
            })
        } else {
            auth.openAuth()
        }
    }

    if (query.loading) {
        return <Skeleton active/>;
    }

    const initialData: IAsset = {
        type: query.data?.asset?.attributes?.asset.type!,
        cost: query.data?.asset?.attributes?.asset.cost!,
        currency: query.data?.asset?.attributes?.asset.currency?.data?.id!,
        country: query.data?.asset?.attributes?.asset.country?.data?.id!,
        region: query.data?.asset?.attributes?.asset.region?.data?.id!,
        link: query.data?.asset?.attributes?.asset.link,
        minProfit: query.data?.asset?.attributes?.minProfit!,
        maxPeriod: query.data?.asset?.attributes?.maxPeriod!,
    }

    return (
        <div className={cls.collapse}>
            <div className={cls.panel}>
                <div className={cls.formCreate}>
                    <FormAsset data={initialData} onSave={onComplete} loading={loading}/>
                </div>
            </div>
        </div>
    )
})