import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import ym from 'react-yandex-metrika';
import {useMutation} from '@apollo/client';
import {Store} from 'app/store/Store';
import {observer} from 'mobx-react-lite';
import {FormAsset} from 'pages/Asset/FormAsset';
import {IAsset} from 'pages/Asset/types';
import { myAssets} from 'shared/config/routes';
import {createAsset, createAssetVariables} from 'shared/graphql/__generated__/createAsset';
import {CREATE_ASSET} from 'shared/graphql/createAsset';
import {useLayout} from 'shared/hooks/useTrainerLayout';
import {ModalDraft} from 'widgets/Offer/ui/ModalDraft';
import {ModalPublish} from 'widgets/Offer/ui/ModalPublish';
import {PageTitle} from 'widgets/PageTitle';

import {ENUM_ASSET_STATUS} from '../../__generated__/global-types';

import cls from './style.module.scss'

export const CreateAsset = observer(() => {

    const {setLayout} = useLayout();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const store = useInstance(Store);
    const {auth} = store;

    const [modalSuccessOpen, setModalSuccessOpen] = useState<boolean>(false)
    const [modalDraftOpen, setModalDraftOpen] = useState<boolean>(false)

    useEffect(() => {
        setLayout({
            title: (
                <PageTitle
                    title={t('Sell an asset')}
                />
            ),
        });
    }, [setLayout, t]);

    const [createAsset] =  useMutation<createAsset,createAssetVariables>(CREATE_ASSET)

    const onComplete = (data: IAsset, status: ENUM_ASSET_STATUS) => {
        if (auth.hasProfile) {
            createAsset({
                variables: {
                    data: {
                        asset: {
                            type: data.type,
                            cost: data.cost,
                            currency: data.currency,
                            country: data.country,
                            region: data.region,
                            link: data.link,
                        },
                        status: status,
                        maxPeriod: data.maxPeriod,
                        minProfit: Number(data.minProfit),
                    }
                },
                onCompleted: () => {
                    if (status === ENUM_ASSET_STATUS.PUBLIC_2) {
                        setModalSuccessOpen(true)
                    } else {
                        setModalDraftOpen(true)
                    }
                }
            })
        } else {
            auth.openAuth()
        }
    }

    const onPublish = (data: IAsset) => {
        ym('reachGoal', 'asset_publish');
        onComplete(data, ENUM_ASSET_STATUS.PUBLIC_2)
    }
    
    const onDraft = (data: IAsset) => {
        ym('reachGoal', 'asset_draft');
        onComplete(data, ENUM_ASSET_STATUS.DRAFT_1)
    }

    const onOkSuccessHandler = () => {
        navigate(myAssets('published'))
    }
    const onOkDraftHandler = () => {
        navigate(myAssets('drafts'))
    }

    const initialData: IAsset = {
        type: undefined,
        cost: undefined,
        currency: undefined,
        country: undefined,
        region: undefined,
        link: undefined,
        minProfit: undefined,
        maxPeriod: undefined,
    }

    return (
        <div className={cls.collapse}>
            <div className={cls.panel}>
                <div className={cls.formCreate}>
                    <FormAsset data={initialData} onComplete={onPublish} onDraft={onDraft}/>
                    <ModalPublish open={modalSuccessOpen} onOk={onOkSuccessHandler}/>
                    <ModalDraft open={modalDraftOpen} onOk={onOkDraftHandler}/>
                </div>
            </div>
        </div>
    )
})