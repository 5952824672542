import {gql} from '@apollo/client';

export const CREATE_MESSAGE = gql`
    mutation createMessage (
        $UID: ID!,
        $message: String!,
    ) {
        createMessage (UID: $UID, message: $message) {
            done
        }
    }
`