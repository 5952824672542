import {useState} from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {INDEX} from 'shared/config/routes';

import globalBlack from './Logo-Global-Black.svg';
import globalBlue from './Logo-Global-Blue.svg';
import globalGreen from './Logo-Global-Green.svg';
import globalRed from './Logo-Global-Red.svg';
import halalBlack from './Logo-Halal-Black.svg';
import halalBlue from './Logo-Halal-Blue.svg';
import halalGreen from './Logo-Halal-Green.svg';
import halalRed from './Logo-Halal-Red.svg';

import cls from './logo.module.scss';

const colorsVars = ['#000000', '#00965E', '#F9423A', '#0077C8'];

interface ILogoHeader {
    width: number
}

export function LogoHeader({width}:ILogoHeader) {

    const [colors, setColors] = useState([colorsVars[2], colorsVars[1]])

    function updateColor() {

        const oneVars = _.without(colorsVars, colors[0])
        const one = oneVars[Math.floor(Math.random() * oneVars.length)]

        const twoVars = _.without(_.without(colorsVars, colors[1]), one)
        const two = twoVars[Math.floor(Math.random() * twoVars.length)]

        setColors([one, two,])

    }

    let imgOne = globalRed
    let imgTwo = halalGreen
    switch (colors[0]) {
        case '#000000': imgOne = globalBlack; break;
        case '#00965E': imgOne = globalGreen; break;
        case '#F9423A': imgOne = globalRed; break;
        case '#0077C8': imgOne = globalBlue; break;
    }
    switch (colors[1]) {
        case '#000000': imgTwo = halalBlack; break;
        case '#00965E': imgTwo = halalGreen; break;
        case '#F9423A': imgTwo = halalRed; break;
        case '#0077C8': imgTwo = halalBlue; break;
    }

    return (
        <div className={cls.logo} onMouseEnter={() => updateColor()} onClick={() => updateColor()}>
            <Link to={INDEX}>
                <div className={cls.wrapper}>
                    <div className={cls.name}>
                        <img src={imgOne} alt={'Global'}/>
                        <img src={imgTwo} alt={'Halal'}/>
                    </div>
                    {width > 500 &&
                    <div className={cls.caption}>
                        {'ethical financial marketplace'}
                    </div>
                    }
                </div>
            </Link>
        </div>
    );
}
