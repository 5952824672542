import {useEffect, useMemo} from 'react';
import {useInstance} from 'react-ioc';
import {useNavigate,useParams, useSearchParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {Store} from 'app/store/Store';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import {filtersToQuery, filtersToSearchParams, IOffersFilters,searchParamsToFilters} from 'pages/Index/Invest/filter';
import {OffersFilter} from 'pages/Index/Invest/OffersFilter';
import {IMapItem} from 'pages/Index/types';
import {List} from 'pages/Index/ui/Content/List';
import {SultanMap} from 'pages/Index/ui/Content/SultanMap'
import IndexLayout from 'pages/Index/ui/Layout/IndexLayout';
import {ModeElement} from 'pages/Index/ui/Layout/ModeElement';
import {TypeElement} from 'pages/Index/ui/Layout/TypeElement';
import {goIndexInvest} from 'shared/config/routes';
import {countries,countriesVariables} from 'shared/graphql/__generated__/countries';
import {offers, offersVariables} from 'shared/graphql/__generated__/offers';
import {QUERY_COUNTRIES} from 'shared/graphql/queryCountries';
import {QUERY_OFFERS} from 'shared/graphql/queryOffers';
import {ConvertOffersToPin} from 'shared/helpers/ConvertOffersToPin';
import {useLayout} from 'shared/hooks/useTrainerLayout';

import { getApiBase } from '../../../env';

export const Invest = observer(() => {

    // определяем что в url - карта или список
    const {mode} = useParams();
    // подключаем навигатор
    const navigate = useNavigate();

    // подключаем стор
    const store = useInstance(Store);

    // ставим глобальный лэйаут без тайтла
    const { setLayout } = useLayout();
    useEffect(() => {
        setLayout({
            title: null,
        });
    }, [setLayout]);

    // работа с фильтрами
    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);

    // главный запрос
    const queryOffers = useQuery<offers, offersVariables>(QUERY_OFFERS, {
        variables: {
            filters: {
                ...filtersToQuery(filters),
                status: {eq: 'PUBLIC_2'},
            },
            pagination: {
                limit: 1000,
            }
        },
    })

    // запрос всех стран
    const queryCountries = useQuery<countries, countriesVariables>(QUERY_COUNTRIES, {
        variables: {
            filters: {enabled: {eq: true}},
            pagination: {limit: 100},
            regionsFilters: {enabled: {eq: true}},
            regionsPagination: {limit: 100},
            currenciesPagination: {limit: 100},
        },
        onCompleted: (data) => {
            const countryData = _.map(data.countries?.data, item => ({
                    name: item.attributes?.name,
                    code: item.attributes?.code2,
                    currencies: _.map(item.attributes?.currencies?.data, cur => ({
                        name: cur.attributes?.name
                    })),
                    regions: _.map(item.attributes?.regions?.data, reg => ({
                        name: reg.attributes?.name,
                        logo: reg.attributes?.logo.data?.attributes?.url
                    }))
                }))
            store.countries.setCountries(countryData)
        },
    })

    // offers - country, region, length
    const offersData = _.map(queryOffers.data?.offers?.data, offer => ({
        UID: offer?.attributes?.UID!,
        country: offer.attributes?.country?.data?.attributes?.name!,
        region: offer.attributes?.region?.data?.attributes?.name,
    }))

    // конвертируем данные для карты
    const countriesData = _.map(queryCountries.data?.countries?.data, item => ({
        name: item.attributes?.name!,
        code: item.attributes?.code2!,
        latitude: item.attributes?.map?.latitude!,
        longitude: item.attributes?.map?.longitude!,
        hasOffers: _.findIndex(offersData, {'country': item.attributes?.name!}) >= 0,
        regions: _.map(item.attributes?.regions?.data, region => ({
            name: region.attributes?.name!,
            logo: region.attributes?.logo.data?.attributes?.url!,
            latitude: region.attributes?.map?.latitude!,
            longitude: region.attributes?.map?.longitude!,
            hasOffers: _.findIndex(offersData, {'region': region.attributes?.name!}) >= 0,
        })),
    }))

    // фильтруем массив офферов по стране и региону из фильтра
    const displayOffers = _.filter(queryOffers.data?.offers?.data, offer => {
        const countryMatch = filters.country ? offer.attributes?.country?.data?.attributes?.name === filters.country : true
        const regionMatch = filters.region ? offer.attributes?.region?.data?.attributes?.name === filters.region : true
        return countryMatch && regionMatch
    })

    const onOpenGeoPin = (countryId: string|null, regionId: string|null) => {
        const newFilter = {
            ...filters,
            country: countryId,
            region: regionId,
        }
        setSearchParams(filtersToSearchParams(newFilter))
    }

    const onChangeFilter = (filters:IOffersFilters) => {
        setSearchParams(filtersToSearchParams(filters))
    }

    const onChangeMode = (value:'map'|'list') => {
        navigate(goIndexInvest(value, searchParams.toString()))
    }


    const mapData: IMapItem[] = useMemo(() => {
        const items: IMapItem[] = [];
        _.forEach(countriesData, (country) => {

            if (country.regions.length) {

                _.forEach(country.regions, (region) => {
                    const latitude = region.latitude;
                    const longitude = region.longitude;
                    const name = region.name;

                    if (!!region && !!latitude && !!longitude && !!name) {
                        const x = parseFloat(latitude);
                        const y = parseFloat(longitude);

                        if (!isNaN(x) && !isNaN(y)) {

                            items.push({
                                country: country.name,
                                region: region.name,
                                name,
                                latitude: x,
                                longitude: y,
                                url: `${getApiBase()}${region.logo}`,
                                disabled: !region.hasOffers,
                                open: filters.country === country.name && filters.region === region.name,
                                onOpen: onOpenGeoPin,
                                onClick: () => onChangeMode('list'),
                                content: ConvertOffersToPin(displayOffers)
                            });
                        }
                    }

                })

            } else {
                const latitude = country.latitude;
                const longitude = country.longitude;
                const name = country.name;

                if (!!country && !!latitude && !!longitude && !!name) {
                    const x = parseFloat(latitude);
                    const y = parseFloat(longitude);

                    if (!isNaN(x) && !isNaN(y)) {

                        items.push({
                            country: country.name!,
                            region: null,
                            name,
                            latitude: x,
                            longitude: y,
                            url: `https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.code}.svg`,
                            disabled: !country.hasOffers,
                            open: filters.country === country.name,
                            onOpen: onOpenGeoPin,
                            onClick: () => onChangeMode('list'),
                            content: ConvertOffersToPin(displayOffers)
                        });
                    }
                }
            }

        });
        return items;
    // eslint-disable-next-line
    }, [countriesData, filters])

    const modeValue = mode === 'map' ? 'map' : 'list'

    return (
        <IndexLayout filterElement={<OffersFilter filters={filters} onChange={onChangeFilter}/>} modeElement={<ModeElement mode={modeValue} onChange={onChangeMode}/>} typeElement={<TypeElement/>}>
            {mode !== 'list' && <SultanMap data={mapData} loading={queryCountries.loading}/>}
            {mode === 'list' && <List offers={displayOffers} loading={queryOffers.loading}/>}
        </IndexLayout>
    )
})