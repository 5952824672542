import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {
    CarOutlined,
CheckOutlined,
CloseOutlined,
EditOutlined,
    HomeOutlined,
    MoreOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Button, Dropdown} from 'antd';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
// import { ReactComponent as IconFlat } from 'shared/assets/icons/iconFlat.svg';
import {editAsset, goToAsset} from 'shared/config/routes';
import {assets_assets_data} from 'shared/graphql/__generated__/assets';
import {CurrencyFormat} from 'shared/helpers/CurrencyFormat';
import {SultanAvatar} from 'widgets/Avatar/SultanAvatar';
import CoinsOutlined from 'widgets/Icons/CoinsOutlined';
import ExcavatorOutlined from 'widgets/Icons/ExcavatorOutlined';

import cls from './OfferCard.module.scss';


interface IAssetCardProps {
    className?: string;
    asset: assets_assets_data;
    onPublish: () => void;
    onClose: () => void;
}

export const AccountAssetCard = observer(function AssetCard(props: IAssetCardProps) {
    const {asset, onPublish, onClose} = props;

    const {t} = useTranslation();
    const navigate = useNavigate();

    let type
    switch (asset.attributes?.asset.type) {
        case 'estate':
            type = <span><HomeOutlined className={cls.icon}/> {t('Real estate')}</span>;
            break;
        case 'tech':
            type = <span><ExcavatorOutlined className={cls.icon}/> {t('Construction vehicles')}</span>;
            break;
        case 'car':
            type = <span><CarOutlined className={cls.icon}/> {t('Transport')}</span>;
            break;
    }

    const items: MenuProps['items'] = [
        {
            key: 'edit',
            label: <Link to={editAsset(asset.attributes?.UID!)} className={cls.bold}>{t('Edit')}</Link>,
            icon: <EditOutlined/>
        },
        ...(asset?.attributes?.status === 'PUBLIC_2' ? [
            {
                key: 'close',
                label: t('Remove from published'),
                icon: <CloseOutlined/>,
                onClick: onClose,
            }
        ] : [
            {
                key: 'publish',
                label: t('Publish'),
                icon: <CheckOutlined/>,
                onClick: onPublish,
            }
        ]),
    ];

    return (
        <div className={classNames([cls.offerCard,])}>
            <div className={cls.left} onClick={() => navigate(goToAsset(asset.attributes?.UID!))}>
                <span className={cls.title}>
                    <CoinsOutlined/>
                    <span className={cls.percent}>{CurrencyFormat(asset.attributes?.asset.cost!)} {asset.attributes?.asset.currency?.data?.attributes?.name!}</span>
                </span>
                <span className={cls.subTitle}>
                         {type}
                </span>
            </div>
            <div className={cls.right}>
                <div className={cls.rightTop}>
                    <Dropdown menu={{items}}>
                        <Button type={'text'} icon={<MoreOutlined/>}/>
                    </Dropdown>
                </div>
                <div className={cls.rightBottom}>
                    {asset.attributes?.author?.data &&
                        <SultanAvatar
                            size={40}
                            color={asset.attributes?.author?.data?.attributes?.color || '#ffffff'}
                            photo={asset.attributes?.author?.data?.attributes?.photo || undefined}
                            firstName={asset.attributes?.author?.data?.attributes?.firstName || ''}
                            lastName={asset.attributes?.author?.data?.attributes?.lastName || ''}
                        />
                    }
                </div>

            </div>
        </div>
    );
});