import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Slide1} from 'pages/Pres/Silde1';
import {Slide2} from 'pages/Pres/Silde2';
import {Slide3} from 'pages/Pres/Silde3';
import {Slide4} from 'pages/Pres/Silde4';
import {Slide5} from 'pages/Pres/Silde5';
import {Slide6} from 'pages/Pres/Silde6';
import {Slide7} from 'pages/Pres/Silde7';
import {Slide8} from 'pages/Pres/Silde8';
import {Slide9} from 'pages/Pres/Silde9';
import {useLayout} from 'shared/hooks/useTrainerLayout';
import {MainFooter} from 'widgets/MainFooter/MainFooter';
import {PageTitle} from 'widgets/PageTitle';

import cls from './Pres.module.scss';

export interface ISlide {
    platform: string
}

export const Pres = () => {

    const {setLayout} = useLayout();
    const {t} = useTranslation();

    useEffect(() => {
        setLayout({
            title: (
                <PageTitle
                    title={
                        <div className={cls.pageTitle}>
                            {t('Platform presentation')}
                        </div>
                    }
                />
            ),
            footer: (<MainFooter/>)
        });
    }, [setLayout, t]);

    const platform = 'Global Halal Market'

    return (
        <div className={cls.wrapper}>

            <Slide1/>
            <Slide2/>
            <Slide3/>
            <Slide4 platform={platform}/>
            <Slide5 platform={platform}/>
            <Slide6 platform={platform}/>
            <Slide7 platform={platform}/>
            <Slide8/>
            <Slide9 platform={platform}/>

        </div>
    )
}
