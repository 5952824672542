import {gql} from '@apollo/client';

export const QUERY_DIALOG = gql`
    query dialog ($UID: ID!) {
        dialog (UID: $UID) {
            status
            isClient
            messages {
                id
                date
                text
                author
                type
                status
            }
            agreement {
                attributes {
                    UID
                    status
                    offer {
                        data {
                            attributes {
                                UID
                                asset {
                                    data {
                                        attributes {
                                            UID
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`