import React from 'react';
import {useTranslation} from 'react-i18next';
import {Placemark, useYMaps} from '@pbe/react-yandex-maps'
import _ from 'lodash'
import { IMapItem } from 'pages/Index/types';
import {CurrencyFormat} from 'shared/helpers/CurrencyFormat';

import car from './Car.svg'
import cost from './Cost.svg'
import spinner from './cupertino_activity_indicator.gif'
import download from './Download.svg'
import empty from './Empty.svg'
import estate from './Home.svg'
import rise from './Rise.svg'
import tech from './Tech.svg'

import './GeoPin.css'

export const GeoPin = ({country, region, name, url, onClick, onOpen, open, content, loading, disabled, latitude, longitude}: IMapItem) => {

    const ymaps = useYMaps();
    const {t} = useTranslation();

    if (!ymaps) return null

    let layout = undefined

    if (open) {

        let contentBlock = ''
        if (content && content.length > 0) {
            _.forEach(content, (item) => {

                const {type, count, totalInvest, totalEarn, totalCost} = item

                let icon = ''
                switch (type) {
                    case 'estate': icon = `<img src=${estate} alt='${t(type)}'></img>`; break
                    case 'car': icon = `<img src=${car} alt='${t(type)}'></img>`; break
                    case 'tech': icon = `<img src=${tech} alt='${t(type)}'></img>`; break
                }

                contentBlock += `
                    <div class='pin-group'>
                        <div class='pin-type'><span class='pin-type-icon'>${icon}</span><span>${t('{{x}} offers', {x: count})}</span></div>                    
                        ${totalInvest ? `<div class='pin-type pin-gray'><span class='pin-type-icon'><img src=${download} alt='Invest'></img></span><span>${CurrencyFormat(totalInvest!)}</span></div>` : ''}
                        ${totalEarn ? `<div class='pin-type pin-gray'><span class='pin-type-icon'><img src=${rise} alt='Earn'></img></span><span>${CurrencyFormat(totalEarn!)}</span></div>` : ''}
                        ${totalCost ? `<div class='pin-type pin-gray'><span class='pin-type-icon'><img src=${cost} alt='Cost'></img></span><span>${CurrencyFormat(totalCost!)}</span></div>` : ''}
                    </div>
                `
            })
        } else {
            contentBlock += `
                    <div class='pin-group'>
                        <img class='pin-empty' src='${empty}' alt='no data'></img>                    
                    </div>
                `
        }

        const spinnerBlock = `<img class='pin-spinner' src='${spinner}' alt='spinner'></img>`

        layout = ymaps.templateLayoutFactory.createClass(
            `
<div class='pin-mark ${disabled ? 'pin-disabled' : ''}'>
    <div class='pin-content'>
        <div class='pin-title'>
            <div class='pin-icon'><img class='pin-icon' src='${url}' alt='${name}'></div>
            <div class='pin-name'>${name}</div>
        </div>
            ${loading ? spinnerBlock : contentBlock}
    </div>
    <div class='pin-arrow'></div>
</div>`,
            {

            }
        );
    } else {
        layout = ymaps.templateLayoutFactory.createClass(
            `
<div class='pin-mark ${disabled ? 'pin-disabled' : ''}'>
    <div class='pin-point'>
        <div class='pin-title'>
            <div class='pin-icon'><img class='pin-icon' src='${url}' alt='${name}'></div>
<!--            <div class='pin-name'>${name}</div>-->
        </div>
    </div>
    <div class='pin-arrow-point'></div>
</div>`,
            {

            }
        );
    }

    const onHandleClick = async () => {
        if (open) {
            if (onClick) {onClick(country, region)}
        } else {
            if (onOpen) {onOpen(country, region)}
        }
    }

    // рассчет высоты в зависимости от контента
    const shapeWidth = open && content?.length! > 0 ? 135 : 64
    const shapeHeight = open ? content?.length! > 0 ? (content?.length!) * 65 + 52 : 82 : 52

    return (
        <Placemark
            onClick={onHandleClick}
            options={{
                iconLayout: layout,
                ...!disabled && {
                    iconShape: {
                        type: 'Rectangle',
                        // @ts-ignore
                        coordinates: [
                            [-30, 0], [shapeWidth - 30, -shapeHeight]
                        ],
                    },
                },
                zIndex: open ? 1000 : 0
            }}
            geometry={[latitude, longitude]}
        />
    );
}