import {gql} from '@apollo/client';

export const CREATE_DIALOG = gql`
    mutation createDialog (
        $offer: ID!,
        $message: String,
    ) {
        createDialog (offer: $offer, message: $message) {
            UID
        }
    }
`