import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {Button, Divider} from 'antd';
import {Store} from 'app/store/Store';
import classNames from 'classnames';
import {PaymentTable} from 'features/Offer';
import {observer} from 'mobx-react-lite';
import {CurrencyFormat} from 'shared/helpers/CurrencyFormat';
import {OfferData} from 'widgets/Offer';

import cls from 'features/Offer/ui/graph/OfferGraph.module.scss';

interface IFormResultProps {
    onSave: () => void;
}

export const FormResult = observer(({onSave}: IFormResultProps) => {

    const {t} = useTranslation();

    const store = useInstance(Store);
    const {offer} = store;
    const {isHasChanges, calculatedValues, isCalculated} = offer;
    const {dealCost, investPayment, investSellProfit, investRentProfit, paymentTable} = calculatedValues;

    const [isTableOpen, setIsTableOpen] = useState(false);

    const openTable = () => {
        if (isCalculated) {
            setIsTableOpen(true);
        }
    };

    const closeTabel = () => {
        setIsTableOpen(false);
    };

    const onClickSave = () => {
        if (onSave) {
            onSave()
        }
    }

    return (
        <div className={cls.calculated}>
            <OfferData
                name={t('Payment frequency')}
                value={
                    <span
                        onClick={openTable}
                        className={classNames([cls.getTabel], {[cls.disabled]: !paymentTable})}
                    >
                                {t('Review')}
                            </span>
                }
            />
            <Divider className={cls.divider}/>
            <OfferData name={t('The amount you pay for 100% of the asset')} value={CurrencyFormat(dealCost)}/>
            <Divider className={cls.divider}/>
            <OfferData name={t('The amount investor pays upon purchasing')} value={CurrencyFormat(investPayment)}/>
            <Divider className={cls.divider}/>
            <OfferData name={t('Investor\'s earnings (buying&selling)')} value={CurrencyFormat(investSellProfit)}/>
            <Divider className={cls.divider}/>
            <OfferData name={t('Investor\'s earnings (renting out)')} value={CurrencyFormat(investRentProfit)}/>
            <Divider className={cls.divider}/>

            <Button
                size={'large'}
                type={'primary'}
                onClick={onClickSave}
                disabled={!(isCalculated && isHasChanges)}
            >
                {t('Save')}
            </Button>

            <PaymentTable isOpen={isTableOpen} onCancel={closeTabel}/>
        </div>
    );
});