import {useCallback} from 'react';
import {useInstance} from 'react-ioc';
import {useMutation} from '@apollo/client';
import {Store} from 'app/store/Store';
import {
    updateUsersPermissionsUser,
    updateUsersPermissionsUserVariables
} from 'features/Auth/graphql/__generated__/updateUsersPermissionsUser';
import {UPDATE_ME} from 'features/Auth/graphql/updateMe';

interface IProps {
    UID: string
}

export const useRemoveFromFavoritesOffers = (props: IProps) => {

    const {UID} = props
    const { auth } = useInstance(Store);
    const [update] = useMutation<updateUsersPermissionsUser, updateUsersPermissionsUserVariables>(UPDATE_ME)

    return useCallback(async () => {

        if (auth.hasProfile) {

            auth.removeFromFavoritesOffers(UID)
            await update({
                variables: {
                    id: auth.id || '',
                    data: {
                        favoritesOffers: auth.favoritesOffers
                    }
                },
            })
        } else {
            auth.openAuth()
        }

    }, [UID, auth, update])

}