import {useTranslation} from 'react-i18next';
import {Button, Modal, Space} from 'antd';

import cls from './modal.module.scss';

interface IModalProps {
    open: boolean;
    onOk: () => void;
    onCancel: () => void;
}

export const ModalConfirmPublish = ({open, onOk, onCancel}: IModalProps) => {
    
    const { t } = useTranslation()
    
    return (
        <Modal title={undefined} open={open} footer={[]} closable={false}>
            <div className={cls.modal}>
                {t('Your offer does not meet the seller\'s expectations in terms of income')}
                <Space>
                    <Button onClick={onCancel}>{t('Edit')}</Button>
                    <Button type={'primary'} onClick={onOk}>{t('Publish anyway')}</Button>
                </Space>
            </div>
        </Modal>
    )
}