/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ENUM_AGREEMENT_STATUS {
  concluded = "concluded",
  draft = "draft",
  signed = "signed",
}

export enum ENUM_ASSET_STATUS {
  CLOSED_3 = "CLOSED_3",
  DRAFT_1 = "DRAFT_1",
  PUBLIC_2 = "PUBLIC_2",
}

export enum ENUM_COMPONENTASSETASSET_TYPE {
  car = "car",
  estate = "estate",
  tech = "tech",
}

export enum ENUM_DIALOG_STATUS {
  active = "active",
  closed = "closed",
  created = "created",
  declined = "declined",
}

export enum ENUM_OFFER_STATUS {
  CLOSED_3 = "CLOSED_3",
  DRAFT_1 = "DRAFT_1",
  PUBLIC_2 = "PUBLIC_2",
}

export enum ENUM_OFFER_TYPE {
  car = "car",
  estate = "estate",
  tech = "tech",
}

export interface AssetFiltersInput {
  id?: IDFilterInput | null;
  author?: UsersPermissionsUserFiltersInput | null;
  UID?: StringFilterInput | null;
  publishAt?: DateTimeFilterInput | null;
  publishDays?: IntFilterInput | null;
  asset?: ComponentAssetAssetFiltersInput | null;
  status?: StringFilterInput | null;
  minProfit?: FloatFilterInput | null;
  maxPeriod?: IntFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (AssetFiltersInput | null)[] | null;
  or?: (AssetFiltersInput | null)[] | null;
  not?: AssetFiltersInput | null;
}

export interface AssetInput {
  author?: string | null;
  UID?: string | null;
  publishAt?: any | null;
  publishDays?: number | null;
  asset?: ComponentAssetAssetInput | null;
  status?: ENUM_ASSET_STATUS | null;
  minProfit?: number | null;
  maxPeriod?: number | null;
}

export interface BooleanFilterInput {
  and?: (boolean | null)[] | null;
  or?: (boolean | null)[] | null;
  not?: BooleanFilterInput | null;
  eq?: boolean | null;
  eqi?: boolean | null;
  ne?: boolean | null;
  startsWith?: boolean | null;
  endsWith?: boolean | null;
  contains?: boolean | null;
  notContains?: boolean | null;
  containsi?: boolean | null;
  notContainsi?: boolean | null;
  gt?: boolean | null;
  gte?: boolean | null;
  lt?: boolean | null;
  lte?: boolean | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (boolean | null)[] | null;
  notIn?: (boolean | null)[] | null;
  between?: (boolean | null)[] | null;
}

export interface ComponentAssetAssetFiltersInput {
  type?: StringFilterInput | null;
  country?: CountryFiltersInput | null;
  region?: RegionFiltersInput | null;
  currency?: CurrencyFiltersInput | null;
  link?: StringFilterInput | null;
  cost?: FloatFilterInput | null;
  and?: (ComponentAssetAssetFiltersInput | null)[] | null;
  or?: (ComponentAssetAssetFiltersInput | null)[] | null;
  not?: ComponentAssetAssetFiltersInput | null;
}

export interface ComponentAssetAssetInput {
  id?: string | null;
  type?: ENUM_COMPONENTASSETASSET_TYPE | null;
  country?: string | null;
  region?: string | null;
  currency?: string | null;
  link?: string | null;
  cost?: number | null;
}

export interface ComponentMapMapFiltersInput {
  latitude?: StringFilterInput | null;
  longitude?: StringFilterInput | null;
  and?: (ComponentMapMapFiltersInput | null)[] | null;
  or?: (ComponentMapMapFiltersInput | null)[] | null;
  not?: ComponentMapMapFiltersInput | null;
}

export interface CountryFiltersInput {
  id?: IDFilterInput | null;
  name?: StringFilterInput | null;
  enabled?: BooleanFilterInput | null;
  regions?: RegionFiltersInput | null;
  code2?: StringFilterInput | null;
  currencies?: CurrencyFiltersInput | null;
  map?: ComponentMapMapFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (CountryFiltersInput | null)[] | null;
  or?: (CountryFiltersInput | null)[] | null;
  not?: CountryFiltersInput | null;
}

export interface CurrencyFiltersInput {
  id?: IDFilterInput | null;
  name?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (CurrencyFiltersInput | null)[] | null;
  or?: (CurrencyFiltersInput | null)[] | null;
  not?: CurrencyFiltersInput | null;
}

export interface DateTimeFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: DateTimeFilterInput | null;
  eq?: any | null;
  eqi?: any | null;
  ne?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface FloatFilterInput {
  and?: (number | null)[] | null;
  or?: (number | null)[] | null;
  not?: FloatFilterInput | null;
  eq?: number | null;
  eqi?: number | null;
  ne?: number | null;
  startsWith?: number | null;
  endsWith?: number | null;
  contains?: number | null;
  notContains?: number | null;
  containsi?: number | null;
  notContainsi?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (number | null)[] | null;
  notIn?: (number | null)[] | null;
  between?: (number | null)[] | null;
}

export interface IDFilterInput {
  and?: (string | null)[] | null;
  or?: (string | null)[] | null;
  not?: IDFilterInput | null;
  eq?: string | null;
  eqi?: string | null;
  ne?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (string | null)[] | null;
  notIn?: (string | null)[] | null;
  between?: (string | null)[] | null;
}

export interface IntFilterInput {
  and?: (number | null)[] | null;
  or?: (number | null)[] | null;
  not?: IntFilterInput | null;
  eq?: number | null;
  eqi?: number | null;
  ne?: number | null;
  startsWith?: number | null;
  endsWith?: number | null;
  contains?: number | null;
  notContains?: number | null;
  containsi?: number | null;
  notContainsi?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (number | null)[] | null;
  notIn?: (number | null)[] | null;
  between?: (number | null)[] | null;
}

export interface JSONFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: JSONFilterInput | null;
  eq?: any | null;
  eqi?: any | null;
  ne?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface OfferFiltersInput {
  id?: IDFilterInput | null;
  type?: StringFilterInput | null;
  activeCost?: FloatFilterInput | null;
  activePayment?: FloatFilterInput | null;
  rentCost?: FloatFilterInput | null;
  partCost?: FloatFilterInput | null;
  partsCount?: IntFilterInput | null;
  partPaymentPeriod?: IntFilterInput | null;
  author?: UsersPermissionsUserFiltersInput | null;
  link?: StringFilterInput | null;
  status?: StringFilterInput | null;
  region?: RegionFiltersInput | null;
  regionUse?: RegionFiltersInput | null;
  currency?: CurrencyFiltersInput | null;
  publishAt?: DateTimeFilterInput | null;
  investmentSum?: FloatFilterInput | null;
  incomePercent?: FloatFilterInput | null;
  totalDuration?: IntFilterInput | null;
  publishDurationDays?: IntFilterInput | null;
  country?: CountryFiltersInput | null;
  countryUse?: CountryFiltersInput | null;
  calcData?: JSONFilterInput | null;
  UID?: StringFilterInput | null;
  asset?: AssetFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (OfferFiltersInput | null)[] | null;
  or?: (OfferFiltersInput | null)[] | null;
  not?: OfferFiltersInput | null;
}

export interface OfferInput {
  activeCost?: number | null;
  activePayment?: number | null;
  incomePercent?: number | null;
  investmentSum?: number | null;
  partCost?: number | null;
  partPaymentPeriod?: number | null;
  partsCount?: number | null;
  rentCost?: number | null;
  totalDuration?: number | null;
  type?: ENUM_OFFER_TYPE | null;
  region?: string | null;
  country?: string | null;
  countryUse?: string | null;
  regionUse?: string | null;
  currency?: string | null;
  link?: string | null;
  author?: string | null;
  status?: ENUM_OFFER_STATUS | null;
  publishAt?: any | null;
  publishDurationDays?: number | null;
  calcData?: any | null;
  UID?: string | null;
  asset?: string | null;
}

export interface PaginationArg {
  page?: number | null;
  pageSize?: number | null;
  start?: number | null;
  limit?: number | null;
}

export interface RegionFiltersInput {
  id?: IDFilterInput | null;
  name?: StringFilterInput | null;
  country?: CountryFiltersInput | null;
  enabled?: BooleanFilterInput | null;
  map?: ComponentMapMapFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (RegionFiltersInput | null)[] | null;
  or?: (RegionFiltersInput | null)[] | null;
  not?: RegionFiltersInput | null;
}

export interface StringFilterInput {
  and?: (string | null)[] | null;
  or?: (string | null)[] | null;
  not?: StringFilterInput | null;
  eq?: string | null;
  eqi?: string | null;
  ne?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (string | null)[] | null;
  notIn?: (string | null)[] | null;
  between?: (string | null)[] | null;
}

export interface UsersPermissionsLoginInput {
  identifier: string;
  password: string;
  provider: string;
}

export interface UsersPermissionsPermissionFiltersInput {
  id?: IDFilterInput | null;
  action?: StringFilterInput | null;
  role?: UsersPermissionsRoleFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (UsersPermissionsPermissionFiltersInput | null)[] | null;
  or?: (UsersPermissionsPermissionFiltersInput | null)[] | null;
  not?: UsersPermissionsPermissionFiltersInput | null;
}

export interface UsersPermissionsRoleFiltersInput {
  id?: IDFilterInput | null;
  name?: StringFilterInput | null;
  description?: StringFilterInput | null;
  type?: StringFilterInput | null;
  permissions?: UsersPermissionsPermissionFiltersInput | null;
  users?: UsersPermissionsUserFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (UsersPermissionsRoleFiltersInput | null)[] | null;
  or?: (UsersPermissionsRoleFiltersInput | null)[] | null;
  not?: UsersPermissionsRoleFiltersInput | null;
}

export interface UsersPermissionsUserFiltersInput {
  id?: IDFilterInput | null;
  username?: StringFilterInput | null;
  email?: StringFilterInput | null;
  provider?: StringFilterInput | null;
  password?: StringFilterInput | null;
  resetPasswordToken?: StringFilterInput | null;
  confirmationToken?: StringFilterInput | null;
  confirmed?: BooleanFilterInput | null;
  blocked?: BooleanFilterInput | null;
  role?: UsersPermissionsRoleFiltersInput | null;
  firstName?: StringFilterInput | null;
  color?: StringFilterInput | null;
  lastName?: StringFilterInput | null;
  language?: StringFilterInput | null;
  photo?: StringFilterInput | null;
  UID?: StringFilterInput | null;
  favoritesOffers?: JSONFilterInput | null;
  favoritesAssets?: JSONFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (UsersPermissionsUserFiltersInput | null)[] | null;
  or?: (UsersPermissionsUserFiltersInput | null)[] | null;
  not?: UsersPermissionsUserFiltersInput | null;
}

export interface UsersPermissionsUserInput {
  username?: string | null;
  email?: string | null;
  provider?: string | null;
  password?: string | null;
  resetPasswordToken?: string | null;
  confirmationToken?: string | null;
  confirmed?: boolean | null;
  blocked?: boolean | null;
  role?: string | null;
  firstName?: string | null;
  color?: string | null;
  lastName?: string | null;
  language?: string | null;
  photo?: string | null;
  UID?: string | null;
  favoritesOffers?: any | null;
  favoritesAssets?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
