import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CustomIcon = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 36 36">
        <path d="M31.7348 7H29.422C29.0977 7 28.79 7.15376 28.5915 7.41687L14.9498 25.2636L8.40849 16.7041C8.30953 16.5744 8.18341 16.4695 8.03957 16.3973C7.89574 16.3251 7.73793 16.2875 7.57801 16.2873H5.26523C5.04355 16.2873 4.92113 16.5504 5.05678 16.728L14.1193 28.5848C14.5428 29.1384 15.3567 29.1384 15.7836 28.5848L31.9432 7.43737C32.0789 7.2631 31.9565 7 31.7348 7Z"/>
    </svg>

)

const CheckMessage = (props: Partial<CustomIconComponentProps>) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
        <Icon component={CustomIcon} {...props} />
    )

export default CheckMessage