import { applySnapshot, types as t} from 'mobx-state-tree';

export const initialLangStore = {
    lang: '',
};

export const LangStore = t
    .model({
        lang: t.string,
    })
    .actions((self) => ({
        clearState: () => {
            applySnapshot(self, initialLangStore);
        },
        setLang: (lang: string) => {
            self.lang = lang;
        },
    }));