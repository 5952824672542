import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import { OfferForm, OfferGraph } from 'features/Offer';
import { useLayout } from 'shared/hooks/useTrainerLayout';
import { PageTitle } from 'widgets/PageTitle';

import cls from './Calc.module.scss';

const { Panel } = Collapse;

export const Calc = () => {
    const { t } = useTranslation();

    const { setLayout } = useLayout();

    useEffect(() => {
        setLayout({
            title: (
                <PageTitle
                    title={
                        <>
                            <div>{t('Calculate the offer')}</div>
                            <div>{t('for Investor')}</div>
                        </>
                    }
                />
            ),
        });
    }, [setLayout, t]);

    const [isFirstOpen, setIsFirstOpen] = useState('form');
    const [isSecondOpen, setIsSecondOpen] = useState('');

    const toggleFirst = () => {
        setIsFirstOpen((prevState) => (prevState === '' ? 'form' : ''));
    };

    const toggleSecond = () => {
        setIsSecondOpen((prevState) => (prevState === '' ? 'graph' : ''));
    };

    const setCollapsesOnFinish = () => {
        setIsFirstOpen('')
        setIsSecondOpen('graph')
    }

    return (
        <div className={cls.Offer}>
            <Collapse bordered={false} className={cls.collapse} onChange={toggleFirst} activeKey={isFirstOpen}>
                <Panel key={'form'} header={t('Calculation parameters')} forceRender>
                    <OfferForm toggleCollapses={setCollapsesOnFinish}/>
                </Panel>
            </Collapse>

            <Collapse bordered={false} className={cls.collapse} onChange={toggleSecond} activeKey={isSecondOpen}>
                <Panel key={'graph'} header={t('Calculation results')} forceRender>
                    <OfferGraph />
                </Panel>
            </Collapse>
        </div>
    );
};
