import {useQuery} from '@apollo/client';
import {Skeleton, Watermark} from 'antd';
import dayjs from 'dayjs';
import {DataType} from 'features/Offer/ui/table/PaymentTable';
import _ from 'lodash';
import {AgreementFooter} from 'pages/Agreement/AgreementFooter';
import {AgreementFrequency} from 'pages/Agreement/AgreementFrequency';
import {AgreementHeader} from 'pages/Agreement/AgreementHeader';
import {AgreementStamp, IStamp} from 'pages/Agreement/AgreementStamp';
import {CountryRegionRus} from 'pages/Agreement/CountryRegionRus';
import {TablePage} from 'pages/Agreement/TablePage/TablePage';
import {agreement, agreementVariables} from 'shared/graphql/__generated__/agreement';
import {QUERY_AGREEMENT} from 'shared/graphql/queryAgreement';
import {CurrencyFormat} from 'shared/helpers/CurrencyFormat';

import {ENUM_AGREEMENT_STATUS,ENUM_OFFER_TYPE} from '../../__generated__/global-types';

import cls from './Agreement.module.scss'

export interface IWatermark {
    name: string,
    gap: number,
}

export interface IPage {
    current: number,
    total: number,
}

export interface IHeader {
    platform: string,
    UID: string,
    date: string,
}

interface IAgreement {
    UID: string,
    status: ENUM_AGREEMENT_STATUS,

}

export const Agreement = ({UID, status}: IAgreement) => {
    
    const platform = 'Global Halal'
    // исправть картинку узора

    const query = useQuery<agreement, agreementVariables>(QUERY_AGREEMENT, {
        variables: {
            UID: UID,
        }
    })

    const currency = query.data?.agreement?.attributes?.offer?.data?.attributes?.currency?.data?.attributes?.name!

    const header: IHeader = {
        platform,
        UID,
        date: status === ENUM_AGREEMENT_STATUS.concluded ? dayjs(query.data?.agreement?.attributes?.updatedAt!).format('DD.MM.YYYY') : '',
    }
    const investor = {
        name: `${query.data?.agreement?.attributes?.dialog?.data?.attributes?.investor?.data?.attributes?.firstName!} ${query.data?.agreement?.attributes?.dialog?.data?.attributes?.investor?.data?.attributes?.lastName!}`,
        email: query.data?.agreement?.attributes?.dialog?.data?.attributes?.investor?.data?.attributes?.email!,
    }
    const client = {
        name: `${query.data?.agreement?.attributes?.dialog?.data?.attributes?.client?.data?.attributes?.firstName!} ${query.data?.agreement?.attributes?.dialog?.data?.attributes?.client?.data?.attributes?.lastName!}`,
        email: query.data?.agreement?.attributes?.dialog?.data?.attributes?.client?.data?.attributes?.email!,
    }
    const inverstorPercent = Math.round((query.data?.agreement?.attributes?.offer?.data?.attributes?.activeCost! - query.data?.agreement?.attributes?.offer?.data?.attributes?.activePayment!) / query.data?.agreement?.attributes?.offer?.data?.attributes?.activeCost! * 100)
    const firstPayment = {
        percent: inverstorPercent,
        amount: query.data?.agreement?.attributes?.offer?.data?.attributes?.activeCost! - query.data?.agreement?.attributes?.offer?.data?.attributes?.activePayment!,
        currency,
    }
    let assetTypeEn = ''
    let assetTypeRu = ''
    switch (query.data?.agreement?.attributes?.offer?.data?.attributes?.type!) {
        case ENUM_OFFER_TYPE.estate:
            assetTypeEn = 'Real estate';
            assetTypeRu = 'Недвижимость';
            break
        case ENUM_OFFER_TYPE.car:
            assetTypeEn = 'Transport';
            assetTypeRu = 'Транспорт';
            break
        case ENUM_OFFER_TYPE.tech:
            assetTypeEn = 'Construction vehicles';
            assetTypeRu = 'Техника';
            break
    }
    const assetEn = {
        type: assetTypeEn,
        price: query.data?.agreement?.attributes?.offer?.data?.attributes?.activeCost!,
        currency,
        country: query.data?.agreement?.attributes?.offer?.data?.attributes?.country?.data?.attributes?.name!,
        region: query.data?.agreement?.attributes?.offer?.data?.attributes?.region?.data?.attributes?.name!,
        countryUse: query.data?.agreement?.attributes?.offer?.data?.attributes?.countryUse?.data?.attributes?.name!,
        regionUse: query.data?.agreement?.attributes?.offer?.data?.attributes?.regionUse?.data?.attributes?.name!,
    }
    const assetRu = {
        type: assetTypeRu,
        price: query.data?.agreement?.attributes?.offer?.data?.attributes?.activeCost!,
        currency,
        country: CountryRegionRus(query.data?.agreement?.attributes?.offer?.data?.attributes?.country?.data?.attributes?.name!),
        region: CountryRegionRus(query.data?.agreement?.attributes?.offer?.data?.attributes?.region?.data?.attributes?.name!),
        countryUse: CountryRegionRus(query.data?.agreement?.attributes?.offer?.data?.attributes?.countryUse?.data?.attributes?.name!),
        regionUse: CountryRegionRus(query.data?.agreement?.attributes?.offer?.data?.attributes?.regionUse?.data?.attributes?.name!),
    }
    const rent = {
        amount: CurrencyFormat(query.data?.agreement?.attributes?.offer?.data?.attributes?.rentCost! / inverstorPercent),
        currency,
    }
    const assetSell = {
        percent: inverstorPercent,
        amount: CurrencyFormat(query.data?.agreement?.attributes?.offer?.data?.attributes?.partCost!),
        currency: currency,
    }
    const donate = {
        amount: 100,
        currency: 'USD',
        penaltyUrl: 'https://globalhalal.market/penalty',
    }
    let watermarkName = ''
    switch (status) {
        case ENUM_AGREEMENT_STATUS.draft : watermarkName = 'Sample / Образец'; break
        case ENUM_AGREEMENT_STATUS.signed : watermarkName = 'Signed / Подписано'; break
        case ENUM_AGREEMENT_STATUS.concluded : watermarkName = ''; break
    }
    const watermark: IWatermark = {
        name: watermarkName,
        gap: 50,
    }
    const stamp: IStamp = {
        platformUrl: 'https://globalhalal.market',
        registerUrl: 'https://globalhalal.market/register',
        UID,
    }

    let data: DataType[] = [];
    _.forEach(query.data?.agreement?.attributes?.offer?.data?.attributes?.calcData!.paymentTable, item => {
        if (item.key !== 'upfront' && item.key !== 'sum') {data.push({
            key: `${item.key}`,
            paymentNumber: `${item.key}`,
            investorsSharesBeforePayment: item.investorsSharesBeforePayment.toFixed(1),
            clientSharesBeforePayment: item.clientSharesBeforePayment.toFixed(1),
            rentOfInvestorsShare: CurrencyFormat(item.rentOfInvestorsShare),
            repurchaseOfInvestorsShare: CurrencyFormat(item.repurchaseOfInvestorsShare),
            paymentSum: CurrencyFormat(item.paymentSum),
            investorsSharesAfterPayment: item.investorsSharesAfterPayment.toFixed(1),
            clientSharesAfterPayment: item.clientSharesAfterPayment.toFixed(1),
        })}
    })

    // формируем массив данных для страниц

    // на второй странице помещается 5 строчек + штамп
    // или до 10 строчек без штампа

    // на полностью табличной странице помещается 14 строчек + штамп
    // или 29 строчек без штампа

    // количество дополнительных страниц = если больше 5 строчек,
    // то (длина массива данных + 5(штамп) - 10(первая страница)) разделенное на 29 с округлением до целого

    const addPages = Math.ceil((data.length + 5 - 10) / 40)
    const totalPages = 2 + addPages

    let pages = []
    for (let p = 0; p <= addPages; p++) {
        // для первой страницы
        if (p === 0) {
            // добавляем данные на страницу
            // 0 - 0..10
            const pageData = data.slice(0, 10)
            pages.push({
                data: pageData,
                stamp: status === ENUM_AGREEMENT_STATUS.concluded ? pageData.length <= 5 : false,
            })

            // для остальных страниц
        } else {
            // добавляем данные на страницу
            // 1 - 10..50
            // 2 - 51..90
            // 3 - 91..130...
            const pageData = data.slice((p - 1) * 40 + 10, p * 40 + 10)
            pages.push({
                data: pageData,
                stamp: status === ENUM_AGREEMENT_STATUS.concluded ? (40 - pageData.length) >= 5 : false,
            })
        }

    }

    if (query.loading) return <Skeleton active/>

    return (

        <div className={cls.pages}>

            <Watermark content={watermark.name} gap={[watermark.gap, watermark.gap]}>
                <div className={cls.page}>

                    <AgreementFooter page={1} total={totalPages} UID={UID}/>
                    <AgreementHeader platform={header.platform} UID={header.UID} date={header.date}/>

                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <div className={cls.title}>{'Financing Agreement'}</div>
                        </div>
                        <div className={cls.columnRu}>
                            <div className={cls.title}>{'Соглашение о финансировании'}</div>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>
                                {`The user of ${platform} platform ${investor.name}, ${investor.email}, hereinafter referred to as the Investor and/or the Lessor and/or the Seller, and the user of ${platform} platform ${client.name}, ${client.email}, hereinafter referred to as the Client and/or the Buyer and/or the Lessee, collectively referred to as the Parties, have entered into this Agreement as follows:`}
                            </p>

                        </div>
                        <div className={cls.columnRu}>
                            <p>
                                {`Пользователь платформы ${platform} ${investor.name}, ${investor.email} именуемый в дальнейшем Инвестор и/или Арендодатель и/или Продавец и пользователь платформы ${platform} ${client.name}, ${client.email}, именуемый в дальнейшем Клиент и/или Покупатель и/или Арендатор, совместно именуемые «Стороны», заключили настоящее Соглашение о нижеследующем:`}
                            </p>
                        </div>
                    </div>

                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <div className={cls.title}>{'1. Scope of Agreement'}</div>
                        </div>
                        <div className={cls.columnRu}>
                            <div className={cls.title}>{'1. Предмет соглашения'}</div>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>
                                {`1.1. At the Client’s request, the Investor shall acquire ${firstPayment.percent}% of the property shares from the Supplier for ${CurrencyFormat(firstPayment.amount)} ${firstPayment.currency}:`}
                            </p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>
                                {`1.1. Инвестор по просьбе Клиента приобретает ${firstPayment.percent}% долей имущества у Поставщика за ${CurrencyFormat(firstPayment.amount)} ${firstPayment.currency}:`}
                            </p>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <table className={cls.tableAsset}>
                                <tbody>
                                <tr>
                                    <td>{'Property type'}</td>
                                    <td className={cls.value}>{assetEn.type}</td>
                                </tr>
                                <tr>
                                    <td>{'Property price, currency'}</td>
                                    <td className={cls.value}>{CurrencyFormat(assetEn.price)} {assetEn.currency}</td>
                                </tr>
                                <tr>
                                    <td>{'Country and region of the property purchasing'}</td>
                                    <td className={cls.value}>{assetEn.country ? assetEn.country : '—'}{assetEn.region && `, ${assetEn.region}`}</td>
                                </tr>
                                <tr>
                                    <td>{'Country and region of the property predominant use'}</td>
                                    <td className={cls.value}> {assetEn.countryUse ? assetEn.countryUse : '—'}{assetEn.regionUse && `, ${assetEn.regionUse}`}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={cls.columnRu}>
                            <table className={cls.tableAsset}>
                                <tbody>
                                <tr>
                                    <td>{'Тип имущества'}</td>
                                    <td className={cls.value}>{assetRu.type}</td>
                                </tr>
                                <tr>
                                    <td>{'Стоимость имущества, валюта'}</td>
                                    <td className={cls.value}>{CurrencyFormat(assetEn.price)} {assetEn.currency}</td>
                                </tr>
                                <tr>
                                    <td>{'Страна приобретения имущества, регион'}</td>
                                    <td className={cls.value}>{assetRu.country ? assetRu.country : '—'}{assetRu.region && `, ${assetRu.region}`}</td>
                                </tr>
                                <tr>
                                    <td>{'Страна преимущественной эксплуатации имущества, регион'}</td>
                                    <td className={cls.value}> {assetRu.countryUse ? assetRu.countryUse : '—'}{assetRu.regionUse && `, ${assetRu.regionUse}`}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>{`1.2. The Investor undertakes to lease their share of the property out to the Client, and the Client undertakes to take the Investor’s property on lease at the rate of ${CurrencyFormat(rent.amount)} ${rent.currency} 1% of the share in the asset for each calendar month of the lease.`}</p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>{`1.2. Инвестор обязуется передать свою долю имущества в аренду Клиенту, а Клиент обязуется арендовать имущество Инвестора по ставке ${CurrencyFormat(rent.amount)} ${rent.currency} за 1% доли в активе за каждый календарный месяц аренды.`}</p>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>{`1.3. The Client undertakes to buy from the Investor, and the Investor undertakes to sell ${assetSell.percent}% of their shares of the asset to the Client for ${assetSell.amount} ${assetSell.currency}.`}</p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>{`1.3. Клиент обязуется купить у Инвестора, а Инвестор обязуется продать Клиенту ${assetSell.percent}% своих долей актива за ${assetSell.amount} ${assetSell.currency}.`}</p>
                        </div>
                    </div>

                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <div
                                className={cls.title}>{'2. Mutual settlements between the Parties and transactions with the property:'}</div>
                        </div>
                        <div className={cls.columnRu}>
                            <div
                                className={cls.title}>{'2. Взаиморасчёты между Сторонами и операции с имуществом:'}</div>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>{'2.1. The Client as the Lessee shall make payments to the Investor as the Lessor for the use of the Investor’s share in the property'}</p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>{'2.1. Клиент, как Арендатор, уплачивает платежи Инвестору, как Арендодателю за пользование долей Инвестора в имуществе'}</p>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>{'2.2. The Client as the Buyer shall pay to the Investor as the Seller a fee for the transfer of the Investor’s share in the property to the Client'}</p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>{'2.2. Клиент, как Покупатель, уплачивает Инвестору, как Продавцу плату за переход доли Инвестора в имуществе к Клиенту'}</p>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>{'2.3. The actual dates and amounts of payments under the Agreement are stipulated in the Payment and Share Transfer Schedule being an integral part hereof'}</p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>{'2.3. Фактические даты и суммы платежей по договору зафиксированы в «Графике платежей и перехода долей», являющимся неотъемлемой частью настоящего Соглашения'}</p>
                        </div>
                    </div>

                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <div
                                className={cls.title}>{'3. The property title transfer shall be recorded in the relevant property title documents and state authorities’ records:'}</div>
                        </div>
                        <div className={cls.columnRu}>
                            <div
                                className={cls.title}>{'3. Переход права собственности на имущество фиксируется в соответствующих правоустанавливающих документах на имущество и записях государственных органов:'}</div>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>{'3.1. at the time of the property title transfer from the Supplier'}</p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>{'3.1. в момент перехода прав на имущество от Поставщика'}</p>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>{'3.2. at the time after fulfilment, by the Client, of obligations to make all payments stipulated in the Payment and Share Transfer Schedule being an integral part hereof'}</p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>{'3.2. в момент после исполнения Клиентом обязательств по уплате всех платежей, зафиксированных в «Графике платежей и перехода долей», являющимся неотъемлемой частью настоящего Соглашения'}</p>
                        </div>
                    </div>

                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <div
                                className={cls.title}>{'4. Distribution of risks and financial obligations regarding the property ownership:'}</div>
                        </div>
                        <div className={cls.columnRu}>
                            <div
                                className={cls.title}>{'4. Распределение рисков и финансовых обязательств вокруг владения имуществом:'}</div>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>{'4.1. when purchasing the insurance cover for risks from the Insurer, the amount of the insurance premium shall be paid by the Investor and the Client in direct proportion to the property ownership shares corresponding to the time after making an instalment payment as per the Payment and Share Transfer Schedule on the date of expiration of the insurance cover'}</p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>{'4.1. при покупке страхового покрытия по рискам от Страховщика, сумма страхового взноса уплачивается Инвестором и Клиентом прямопропорционально долям владения имуществом, которые соответствуют моменту после совершения оплаты очередного платежа в «Графике платежей и перехода долей» на дату окончания страхового покрытия'}</p>
                        </div>
                    </div>


                </div>
            </Watermark>

            <Watermark content={watermark.name} gap={[watermark.gap, watermark.gap]}>
                <div className={cls.page}>

                    <AgreementFooter page={2} total={totalPages} UID={UID}/>
                    <AgreementHeader platform={header.platform} UID={header.UID} date={header.date}/>

                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>
                                {'4.2. the costs for the property damage compensation or third party damage compensation obligations incurred in the course of the property ownership for risks that are not covered by the insurance provided by the Insurer shall be reimbursed by the Investor and the Client in direct proportion to the property ownership shares corresponding to the time after making an instalment payment as per the Payment and Share Transfer Schedule on the date of the damage event or the event that has caused the third party damage compensation obligations'}
                            </p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>
                                {'4.2. расходы на возмещение ущерба имуществу или обязательства по возмещению ущерба третьим лицам, возникшие в процессе владения имуществом, по рискам, которые не покрыты страховым покрытием от Страховщика, уплачиваются Инвестором и Клиентом прямопропорционально долям владения имуществом, которые соответствуют моменту после совершения оплаты очередного платежа в «Графике платежей и перехода долей» на дату события возникновения ущерба или события, повлекшее возникновение обязательств по возмещению ущерба третьим лицам'}
                            </p>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>
                                {'4.3. expenses for payment of property taxes shall be borne by the Investor and the Client in direct proportion to the property ownership shares corresponding to the time after making an instalment payment as per the Payment and Share Transfer Schedule on the date of the tax assessment by the tax authority'}
                            </p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>
                                {'4.3. расходы на уплату налогов на владение имуществом уплачиваются Инвестором и Клиентом прямопропорционально долям владения имуществом, которые соответствуют моменту после совершения оплаты очередного платежа в «Графике платежей и перехода долей» на дату начисления налогового платежа налоговым органом'}
                            </p>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>
                                {'4.4. other (current) expenses related to the property use shall be 100% borne by the Client'}
                            </p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>
                                {'4.4. прочие (текущие) расходы, связанные с пользованием имуществом на 100% несёт Клиент'}
                            </p>
                        </div>
                    </div>

                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <div className={cls.title}>
                                {'5. Penalties for the Client’s failure to fulfil their payment obligations according to the Payment and Share Transfer Schedule being an integral part hereof:'}
                            </div>
                        </div>
                        <div className={cls.columnRu}>
                            <div className={cls.title}>
                                {'5. Штрафы за неисполнение Клиентом обязательств по уплате платежей, согласно “Графику платежей и перехода долей”, являющимся неотъемлемой частью настоящего Соглашения:'}
                            </div>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>
                                {'5.1. If the payment date is more than three (3) days in the past, and the Customer as a Lessee and/or Buyer has failed to make the payment, the Client shall:'}
                            </p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>
                                {'5.1. Если дата платежа осталась в прошлом более, чем на 3 (три) дня, а Клиент, как Арендатор и/или Покупатель не совершил платёж, то Клиент обязан:'}
                            </p>
                        </div>
                    </div>
                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <p>
                                {`5.1.1. donate ${CurrencyFormat(donate.amount)} ${donate.currency} to the promotion of ${platform} service, by using ${donate.penaltyUrl} payment form`}
                            </p>
                        </div>
                        <div className={cls.columnRu}>
                            <p>
                                {`5.1.1. пожертвовать ${CurrencyFormat(donate.amount)} ${donate.currency} на продвижение сервиса ${platform} с помощью формы оплаты ${donate.penaltyUrl}`}
                            </p>
                        </div>
                    </div>

                    <div className={cls.columns}>
                        <div className={cls.columnEn}>
                            <div className={cls.title}>
                                {'6. In discharge of the obligations hereunder, the Parties shall have the right to conclude supplementary contracts (lease contract, sale and purchase contract) in a jurisdiction convenient to the Parties, but the terms and conditions of such contracts may not contradict the terms and conditions of this Agreement.'}
                            </div>
                        </div>
                        <div className={cls.columnRu}>
                            <div className={cls.title}>
                                {'6. Во исполнение обязательств по настоящему Соглашению, Стороны вправе заключать дополнительные договоры (договор аренды, договор купли-продажи) на территории юрисдикции, удобной Сторонам, но условия этих договоров не могут противоречить условиям настоящего Соглашения.'}
                            </div>
                        </div>
                    </div>

                    <AgreementFrequency/>

                    <table className={cls.tableAsset}>
                        <thead>
                        <tr>
                            <td></td>
                            <td colSpan={2}>{'Структура долей в активе до платежа'}</td>
                            <td colSpan={3}>{'Слагаемые суммы платежа'}</td>
                            <td colSpan={2}>{'Структура долей в активе после платежа'}</td>
                        </tr>
                        <tr>
                            <td>{'Порядковый номер платежа'}</td>
                            <td>{'Доля Инвестора, %'}</td>
                            <td>{'Доля Клиента, %'}</td>
                            <td>{'Аренда доли Инвестора'}</td>
                            <td>{'Выкуп доли Инвестора'}</td>
                            <td>{`Сумма платежа, ${currency}`}</td>
                            <td>{'Доля Инвестора, %'}</td>
                            <td>{'Доля Клиента, %'}</td>
                        </tr>
                        </thead>
                        <tbody>

                        {_.map(pages[0].data, (item, index) => (
                            <tr key={index}>
                                <td>{item.paymentNumber}</td>
                                <td>{item.investorsSharesBeforePayment}</td>
                                <td>{item.clientSharesBeforePayment}</td>
                                <td>{item.rentOfInvestorsShare}</td>
                                <td>{item.repurchaseOfInvestorsShare}</td>
                                <td>{item.paymentSum}</td>
                                <td>{item.investorsSharesAfterPayment}</td>
                                <td>{item.clientSharesAfterPayment}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {pages[0].stamp &&
                        <AgreementStamp platformUrl={stamp.platformUrl} registerUrl={stamp.registerUrl} UID={UID}/>
                    }

                </div>
            </Watermark>

            {_.map(pages.slice(1), (page, index) => (
                <TablePage key={index} currency={currency} data={page.data} watermark={watermark}
                           page={{current: index + 3, total: totalPages}} header={header}
                           stamp={page.stamp ? stamp : undefined}/>
            ))}


        </div>
    )
}