import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {Radio} from 'antd';
import {Store} from 'app/store/Store';
import {useChangeType} from 'features/Filters';
import {observer} from 'mobx-react-lite';

export const TypeElement = observer(() => {

    const changeType = useChangeType();
    const {t} = useTranslation();
    const store = useInstance(Store);

    const optionsType = [
        { label: t('Invest'), value: 'invest' },
        { label: t('Buy'), value: 'buy' },
    ];

    const onTypeChange = async (event:any) => {
        const type = event.target.value === 'invest' ? 'invest' : 'buy'
        await changeType(type)
    }

    return (
        <Radio.Group options={optionsType} onChange={onTypeChange} defaultValue={'invest'} value={store.filters.type} optionType="button" buttonStyle="solid"/>
    )
})