import _ from 'lodash';
import {applySnapshot, types as t} from 'mobx-state-tree';

export const initialCountryStore = {
    countries: [],
    currencies: [],
    loading: true,
}

export const CountryStore = t
    .model({
        countries: t.array(t.model({
            name: t.string,
            code: t.string,
            currencies: t.array(t.model({
                name: t.string
            })),
            regions: t.array(t.model({
                name: t.string,
                logo: t.string
            })),
        })),
        loading: t.boolean,
    })
    .views((self) => ({
        allowedCountries(currency: string | null) {
            if (currency === null) {
                return _.map(self.countries, con => ({name: con.name, code: con.code}))
            } else {
                // return []
                return _.filter(self.countries, function(o) {
                    const currencies = _.map(o.currencies, item => (item.name))
                    return _.includes(currencies, currency)
                })
            }
        },
        allowedRegions(country: string | null) {
            if (country === null) {
                return []
            } else {
                // спииоск регионов по стране
                const filteredData = _.filter(self.countries, {name: country})
                if (filteredData.length === 1) {
                    return filteredData[0].regions
                } else {
                    return []
                }
            }
        },
        allowedCurrencies(country: string | null) {
            if (country === null) {
                // спиоск всех доступных валют
                const currencies = []
                for (const con of self.countries) {
                    for (const cur of con.currencies) {
                        if (cur.name !== null) {
                            currencies.push({name: cur.name})
                        }
                    }
                }
                return _.uniqBy(currencies, 'name')
            } else {
                // спииоск фильтрованных валют
                const filteredData = _.filter(self.countries, {name: country})
                if (filteredData.length === 1) {
                    return filteredData[0].currencies
                } else {
                    return []
                }
            }
        },
    }))
    .actions((self) => ({
        clearState: () => {
            applySnapshot(self, initialCountryStore);
        },
        setCountries: (countries: any) => {
            self.countries = countries;
            self.loading = false;
        },
    }));
