import Linkify from 'react-linkify';
import cn from 'classnames';
import dayjs from 'dayjs';
import CheckMessage from 'widgets/Icons/CheckMessage';
import DoubleCheck from 'widgets/Icons/DoubleCheck';

import { IMessage } from './Chat';

import cls from './Message.module.scss';

export const Message = (props: IMessage) => {

    const {text, date, status, author, type} = props

    let statusIcon = <></>
    switch (status) {
        case 'SENT': statusIcon = <CheckMessage/>; break;
        case 'DELIVERED': statusIcon = <span className={cls.blue}><CheckMessage/></span>; break;
        case 'READ': statusIcon = <span className={cls.blue}><DoubleCheck/></span>; break;
    }

    return (
        <div className={cn([cls.message, author === 'ME' ? cls.me : cls.income, type === 'SYSTEM' && cls.system])}>
            <div>
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} rel='noreferrer' target={'_blank'}>{decoratedText}</a>}>
                    {text}
                </Linkify>
            </div>
            <div className={cls.time}>
                {dayjs(date).format('HH:mm')}
            </div>
            {author === 'ME' && (
                <div className={cls.time}>
                    {statusIcon}
                </div>
            )
            }
        </div>
    )
}