import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CheckOutlined} from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import {Badge, Button, Tooltip} from 'antd';
import {Agreement} from 'pages/Agreement/Agreement';
import {dialog_dialog_agreement_attributes_offer} from 'shared/graphql/__generated__/dialog';
import {signAgreementClient, signAgreementClientVariables} from 'shared/graphql/__generated__/signAgreementClient';
import {
    signAgreementInvestor,
    signAgreementInvestorVariables
} from 'shared/graphql/__generated__/signAgreementInvestor';
import {SIGN_AGREEMENT_CLIENT} from 'shared/graphql/signAgreementClient';
import {SIGN_AGREEMENT_INVESTOR} from 'shared/graphql/signAgreementInvestor';
import {BigModal} from 'widgets/BigModal/BigModal';

import {ENUM_AGREEMENT_STATUS} from '../../../__generated__/global-types';

import icon from './icon-doc.svg'

import cls from './AgreementStatus.module.scss'

interface IAgreementStatusProps {
    UID: string,
    status: ENUM_AGREEMENT_STATUS,
    isClient: boolean,
    offer: dialog_dialog_agreement_attributes_offer,
}

export const AgreementStatus = ({UID, status, offer, isClient}:IAgreementStatusProps) => {

    const {t} = useTranslation()

    const [open, setOpen] = useState<boolean>(false)

    const [signAgreementInvestor, signAgreementInvestorData] = useMutation<signAgreementInvestor,signAgreementInvestorVariables>(SIGN_AGREEMENT_INVESTOR)
    const [signAgreementClient, signAgreementClientData] = useMutation<signAgreementClient,signAgreementClientVariables>(SIGN_AGREEMENT_CLIENT)

    const isSeller = offer.data?.attributes?.asset?.data

    const authorText = isClient ? isSeller ? t('Signed by Seller') : t('Signed by Investor') : t('Signed by Me')

    const onSignInvestor = async () => {
        await signAgreementInvestor({variables: {UID: UID}})
    }
    const onSignClient = async () => {
        await signAgreementClient({variables: {UID: UID}})
    }

    return (
        <div className={cls.block}>
            <div className={cls.file}>
                {/*<FileOutlined className={cls.icon}/>*/}
                <img className={cls.icon} src={icon} alt={'Agreement Icon'}/>
                <div className={cls.fileName}>
                    <Button type={'link'} onClick={() => setOpen(true)}>
                        {t('Financing Agreement')}<br />
                        {UID}
                    </Button>
                </div>
            </div>
            <div className={cls.status}>
                <div><Badge status={status === ENUM_AGREEMENT_STATUS.concluded ? 'success' : 'processing'}/> {t('Sample')}</div>
                <div><Badge status={status === ENUM_AGREEMENT_STATUS.signed ? 'processing' : status === ENUM_AGREEMENT_STATUS.draft ? 'default' : 'success'}/> {authorText}</div>
                <div><Badge status={status === ENUM_AGREEMENT_STATUS.concluded ? 'success' : 'default'}/> {t('Concluded')}</div>
            </div>
            <div className={cls.action}>
                {!isClient &&
                    <>
                        {status === ENUM_AGREEMENT_STATUS.draft && <Button type={'primary'} size={'large'} loading={signAgreementInvestorData.loading} onClick={onSignInvestor}>{t('Sign Agreement')}</Button>}
                        {status === ENUM_AGREEMENT_STATUS.signed && <Tooltip title={t('Waiting Client\'s sign')}><Button type={'primary'} size={'large'} disabled>{t('Sign Agreement')}</Button></Tooltip>}
                    </>
                }
                {isClient &&
                    <>
                        {status === ENUM_AGREEMENT_STATUS.draft && <Tooltip title={t('Investor first')}><Button type={'primary'} size={'large'} disabled>{t('Sign Agreement')}</Button></Tooltip>}
                        {status === ENUM_AGREEMENT_STATUS.signed && <Button type={'primary'} size={'large'} loading={signAgreementClientData.loading} onClick={onSignClient}>{t('Sign Agreement')}</Button>}
                    </>
                }
                {status === ENUM_AGREEMENT_STATUS.concluded && <div className={cls.successButton}><CheckOutlined/> {t('Agreement Concluded')}</div>}
            </div>

            <BigModal open={open} onClose={() => setOpen(false)}>
                {open && <Agreement UID={UID} status={status}/>}
            </BigModal>

        </div>
    )
}