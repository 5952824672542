import react from 'react';

import cls from './MenuLayout.module.scss'

interface IMenuLayoutProps {
    menu: react.ReactNode;
    dialogs?: react.ReactNode;
    children?: react.ReactNode;
}

export const MenuLayout = ({menu, children, dialogs}: IMenuLayoutProps) => (
        <div className={cls.wrapper}>
            <div className={cls.menu}>
                {menu}
            </div>
            <div className={cls.contentWrapper}>
                <div className={cls.dialogs}>
                    {dialogs}
                </div>
                <div className={cls.content}>
                    {children}
                </div>
            </div>
        </div>
    )