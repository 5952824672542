import { ReactNode } from 'react';
import classNames from 'classnames';

import cls from './OfferData.module.scss';

interface IOfferDataProps {
    className?: string;
    name: string|ReactNode;
    value: ReactNode;
    status?: 'warning'|'success';
}

export const OfferData = (props: IOfferDataProps) => {
    const { className, name, value, status } = props;

    return (
        <div className={classNames([
            cls.OfferData,
            className,
            ...(status === 'warning' ? [cls.warning] : [])
        ])}>
            <span className={cls.name}>{name}</span>
            <span className={classNames([
                cls.value,
                ...(status === 'success' ? [cls.success] : [])
                ])}>{value}</span>
        </div>
    );
};