import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInterval} from 'react-timing-hooks';
import TwoLineOne from 'widgets/Slides/TwoLineOne';
import TwoLineTwo from 'widgets/Slides/TwoLineTwo';
import {ISlideProps} from 'widgets/Slides/types';

import TwoLineThree from './TwoLineThree';

export default function Two(props: ISlideProps) {

    const {width, height} = props

    const {t} = useTranslation();

    const [line, setLine] = useState<number>(0)
    const nextLine = () => {
        if (line < 3) {
            setLine(line + 1)
        } else {
            timer.stop()
        }
    }
    const timer = useInterval(nextLine, 1000, { startOnMount: true });

    const sourceTxt = t('Investor'); /* The text */
    const [txt, setTxt] = useState('');

    const type = useCallback(() => {
        const letter = sourceTxt.substring(txt.length, sourceTxt.length).charAt(0);
        setTxt(txt + letter);
    }, [sourceTxt, txt]);

    useEffect(() => {
        if (txt.length !== sourceTxt.length) {
            const ref = setInterval(() => type(), 50); // Refresh all of the state every 5 seconds
            return () => {
                clearInterval(ref);
            };
        }
        // This is a mount effect because its dependency list is empty
    }, [type, txt, sourceTxt.length]);

    const slideStyle = {
        width: width,
        height: height,
        fontSize: height! / 24,
        padding: height! / 24,
    }

    const contentStyle = {
        marginTop: height! / 8,
    }

    return (
        <div style={slideStyle}>
            <div style={contentStyle}>
                <div>{txt}</div>
                {line > 0 && <TwoLineOne pieSize={height! / 10}/>}
                {line > 1 && <TwoLineTwo pieSize={height! / 10}/>}
                {line > 2 && <TwoLineThree pieSize={height! / 10}/>}
            </div>
        </div>
    );
}
