import {useTranslation} from 'react-i18next';
import {ISlide} from 'pages/Pres/Pres';

import cls from './Pres.module.scss';

export const Slide4 = ({platform}:ISlide) => {

    const {t} = useTranslation();

    return (
        <div className={cls.slide}>
            <div className={cls.content}>
                <div className={cls.slide4highlight}>
                    {t('{{platform}} - platform for launching "+1 Tool" to the market', {platform: platform})}
                </div>
            </div>
        </div>
    )
}