import _ from 'lodash';
import { applySnapshot, types as t} from 'mobx-state-tree';

export const initialDialogStore = {
    buying: [],
    selling: [],
    investing: [],
    loading: true,
};

export const DialogStore = t
    .model({
        buying: t.array(t.model({
            UID: t.string,
            status: t.string,
            offerUID: t.string,
            userUID: t.string,
            firstName: t.string,
            lastName: t.string,
            color: t.string,
            photo: t.maybeNull(t.string),
            unreadMessages: t.number,
        })),
        selling: t.array(t.model({
            UID: t.string,
            status: t.string,
            offerUID: t.string,
            userUID: t.string,
            firstName: t.string,
            lastName: t.string,
            color: t.string,
            photo: t.maybeNull(t.string),
            unreadMessages: t.number,
        })),
        investing: t.array(t.model({
            UID: t.string,
            status: t.string,
            offerUID: t.string,
            userUID: t.string,
            firstName: t.string,
            lastName: t.string,
            color: t.string,
            photo: t.maybeNull(t.string),
            unreadMessages: t.number,
        })),
        loading: t.boolean,
    })
    .views((self) => ({
        statusInBuying(offerUID: string) {
            const item = _.find(self.buying, {offerUID: offerUID})
            return item ? {UID: item.UID, status: item.status} : null
        },
        statusInSelling(offerUID: string) {
            const item = _.find(self.selling, {offerUID: offerUID})
            return item ? {UID: item.UID, status: item.status} : null
        },
        statusInInvesting(offerUID: string) {
            const item = _.find(self.investing, {offerUID: offerUID})
            return item ? {UID: item.UID, status: item.status} : null
        },
    }))
    .actions((self) => ({
        clearState: () => {
            applySnapshot(self, initialDialogStore);
        },
        setData: (data: any) => {
            self.buying = data && data.buying!;
            self.selling = data && data.selling!;
            self.investing = data && data.investing!;
        },
        setLoading: (loading: boolean) => {
            self.loading = loading;
        },
    }));