import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import { Skeleton} from 'antd';
import { Cards } from 'app/Layout/Cards';
import {MenuLayout} from 'app/Layout/MenuLayout';
import {Store} from 'app/store/Store';
import _ from 'lodash';
import {myAssets} from 'shared/config/routes';
import {assets, assetsVariables} from 'shared/graphql/__generated__/assets';
import {QUERY_ASSETS} from 'shared/graphql/queryAssets';
import { UPDATE_ASSET } from 'shared/graphql/updateAsset';
import {useLayout} from 'shared/hooks/useTrainerLayout';
import {IGroupsType, IMenuItems, Menu} from 'widgets/Menu/Menu';
import {ModalClose} from 'widgets/Offer/ui/ModalClose';
import {ModalPublish} from 'widgets/Offer/ui/ModalPublish';
import {AccountAssetCard} from 'widgets/OfferCard/ui/AccountAssetCard';
import {EmptyCard} from 'widgets/OfferCard/ui/EmptyCard';
import {PageTitle} from 'widgets/PageTitle';

import {ENUM_ASSET_STATUS} from '../../../__generated__/global-types';

export const MyAssets = () => {

    const {t} = useTranslation();
    const {setLayout} = useLayout();
    const {status} = useParams()
    const navigate = useNavigate();
    const {auth} = useInstance(Store);

    const [modalPublishOpen, setModalPublishOpen] = useState(false)
    const [modalCloseOpen, setModalCloseOpen] = useState(false)

    let queryStatus = ''
    switch (status) {
        case 'drafts': queryStatus = 'DRAFT_1'; break;
        case 'published': queryStatus = 'PUBLIC_2'; break;
        case 'closed': queryStatus = 'CLOSED_3'; break;
    }
    
    const query = useQuery<assets,assetsVariables>(QUERY_ASSETS, {
        variables: {
            filters: {
                author: {id: {eq: auth.id!}},
                status: {eq: queryStatus},
            },
            pagination: {
                limit: 1000,
            }
        },
        skip: !status
    })

    const [updateAsset] = useMutation(UPDATE_ASSET)

    const onClose = async (UID: string) => {
        await updateAsset({
            variables: {
                UID: UID,
                data: {
                    status: ENUM_ASSET_STATUS.CLOSED_3
                }
            },
            onCompleted: () => {
                setModalCloseOpen(true)
            }
        })
    }

    const onPublish = async (UID: string) => {
        await updateAsset({
            variables: {
                UID: UID,
                data: {
                    status: ENUM_ASSET_STATUS.PUBLIC_2,
                },
            },
            onCompleted: () => {
                setModalPublishOpen(true)
            }
        })
    }


    useEffect(() => {
        setLayout({
            title: (
                <PageTitle title={t('My offers')}/>
            ),
        });
    }, [setLayout, t]);

    const offerItems: IMenuItems[] = [
        {
            key: 'offerPublished',
            title: t('Published')!,
            count: 0,
            route: '/account/my-offers/published'
        },
        {
            key: 'offerClosed',
            title: t('Removed from published')!,
            count: 0,
            route: '/account/my-offers/closed'
        },
        {
            key: 'offerDrafts',
            title: t('Drafts')!,
            count: 0,
            route: '/account/my-offers/drafts'
        },
    ]

    const assetItems: IMenuItems[] = [
        {
            key: 'published',
            title: t('Published')!,
            count: 0,
            route: '/account/my-assets/published'
        },
        {
            key: 'closed',
            title: t('Removed from published')!,
            count: 0,
            route: '/account/my-assets/closed'
        },
        {
            key: 'drafts',
            title: t('Drafts')!,
            count: 0,
            route: '/account/my-assets/drafts'
        },
    ]

    const menu: IGroupsType[] = [
        {
            key: 'offer',
            title: `${t('Buying')}`,
            items: offerItems,
        },
        {
            key: 'asset',
            title: `${t('Selling')}`,
            items: assetItems,
        },
    ]

    const onOkHandler = () => {
        setModalPublishOpen(false);
        setModalCloseOpen(false)
        query.refetch();
    }

    if (!status) {
        navigate(myAssets('published'), {replace: false})
    }

    return (
        <MenuLayout menu={<Menu groups={menu} selected={status || ''}/>}>
            <Cards>
                {query.data?.assets?.data.length === 0 && <EmptyCard/>}
                {query.loading && <Skeleton active/>}

                {_.map(query.data?.assets?.data, (item) => (
                    <AccountAssetCard
                        key={item.id} asset={item}
                        onClose={() => onClose(item.attributes?.UID || '')}
                        onPublish={() => onPublish(item.attributes?.UID || '')}
                    />
                ))}
            </Cards>

            <ModalPublish open={modalPublishOpen} onOk={onOkHandler}/>
            <ModalClose open={modalCloseOpen} onOk={onOkHandler}/>

        </MenuLayout>
    );
}