interface IOfferCalcRentIncome {
    rentCost: number
    partsCount: number
    partPaymentPeriod: number
}

export function OfferCalcRentIncome (param:IOfferCalcRentIncome) {

    const {rentCost, partsCount, partPaymentPeriod} = param

    // доля, на которую уменьшается аренда каждый платежный период
    const subtractShare = rentCost / partsCount;
    const totalDuration = partsCount * partPaymentPeriod;

    let sum = 0;
    let rent = rentCost;
    // проходимся по всей сделке и считаем суммарную сумму аренды
    for ( let i = 0; i < totalDuration; i++ ) {
        // если платеж с выкупом доли
        if (i > 0 && i % partPaymentPeriod === 0) {
            // уменьшаем аренду
            rent -= subtractShare
        }
        sum += rent
    }

    return sum;

}