import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Button} from 'antd';
import {CUSTOMER, INDEX} from 'shared/config/routes';

import cls from './Pres.module.scss';

export const Slide8 = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={cls.slide}>
            <div className={cls.headline}>
                {t('Please familiarize yourself with the work of the platform')}
            </div>
            <div className={cls.content}>
                <div className={cls.slide8buttons}>
                    <Button className={cls.button} onClick={() => navigate(CUSTOMER)} type={'primary'}>{t('As client')}</Button>
                    <Button className={cls.button} onClick={() => navigate(INDEX)} type={'primary'}>{t('As investor')}</Button>
                </div>
            </div>
        </div>
    )
}