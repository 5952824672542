import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pie } from '@ant-design/plots';

interface IThreeLineThree {
    fontSize?: number
    pieSize: number
}

export default function ThreeLineThree(props: IThreeLineThree) {

    const {pieSize} = props;
    const {t} = useTranslation()

    const sourceTxt = t('Will gradually sell his/her share to you'); /* The text */

    const [txt, setTxt] = useState('');

    // eslint-disable-next-line
    const type = () => {
        const letter = sourceTxt.substring(txt.length, sourceTxt.length).charAt(0);
        setTxt(txt + letter);
    };

    useEffect(() => {
        if (txt.length !== sourceTxt.length) {
            const ref = setInterval(() => type(), 30);
            return () => {
                clearInterval(ref);
            };
        }
    }, [type, txt, sourceTxt.length]);

    const data = [
        {
            type: 'Твоя доля',
            value: 100,
        },
        {
            type: 'Доля инвестора',
            value: 0,
        },
    ];

    const lineStyle = {
        display: 'Flex',
        gap: pieSize/4,
        marginTop: pieSize/2,
    }

    return (
        <div style={lineStyle}>
            <div style={{width: pieSize, height: pieSize}}>
                <Pie
                    colorField="type"
                    angleField="value"
                    data={data}
                    radius={1}
                    innerRadius={0.6}
                    legend={false}
                    label={false}
                    statistic={{
                        title: false,
                        content: {
                            style: {
                                fontSize: `${pieSize / 4}px`,
                                fontWeight: 'normal',
                            },
                            content: '3',
                        },
                    }}
                    theme={{
                        colors10: ['#00965e', '#f9423a'],
                    }}
                />
            </div>
            <div>
                {txt}
            </div>
        </div>
    );
}
