import { useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import { Button } from 'antd';
import {CALC} from 'shared/config/routes';

import { ISlideProps } from './types';

import cls from './slides.module.scss';

export default function Three(props:ISlideProps) {

    const {width, height} = props

    const {t} = useTranslation()
    const sourceTxt = t('As your share increases the rent cost goes down'); /* The text */
    const [txt, setTxt] = useState('');
    
    const navigate = useNavigate()

    // eslint-disable-next-line
    const type = () => {
        const letter = sourceTxt.substring(txt.length, sourceTxt.length).charAt(0);
        setTxt(txt + letter);
    };

    useEffect(() => {
        if (txt.length !== sourceTxt.length) {
            const ref = setInterval(() => type(), 30); // Refresh all of the state every 5 seconds
            return () => {
                clearInterval(ref);
            };
        }
        // This is a mount effect because its dependency list is empty
    }, [type, txt, sourceTxt]);

    const slideStyle = {
        width: width,
        height: height,
        fontSize: height! / 18,
        padding: height! / 24,
    }

    const questionStyle = {
        marginTop: height! / 4,
    }

    const buttonStyle = {
        width: '100%',
        marginTop: height! / 6,
        height: height! / 12,
        fontSize: height! / 32,
        zIndex: 1000,
    }

    return (
        <div style={slideStyle}>
            <div style={questionStyle}>{txt}</div>
            <div className={cls.fadeId}><Button type={'primary'} style={buttonStyle} onClick={() => navigate(CALC)}>{t('Calculate')}</Button></div>
        </div>
    );
};