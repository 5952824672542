/* eslint-disable */

const getLang = () => {

    let lang = 'eng'
    const store = localStorage.getItem('sultan-store')
    // @ts-ignore
    if (store) {
        const parsed = JSON.parse(store)
        if (parsed.lang && parsed.lang.lang && parsed.lang.lang !== '') {
            lang = parsed.lang.lang
        }
    }
    return lang

}

export default getLang