import {useTranslation} from 'react-i18next';
import type {MenuProps} from 'antd';
import {Button, Dropdown} from 'antd';
import classNames from 'classnames';
import {DE, ES,FR, GB, KZ,RU, TR} from 'country-flag-icons/react/3x2'
import useChangeLanguage from 'features/Language/useChangeLanguage';

import cls from './LanguageSelect.module.scss'


export const LanguageSelect = () => {

    const {t, i18n} = useTranslation()

    let current = <></>
    switch (i18n.language) {
        case 'rus':
            current = <RU className={classNames([cls.flag, cls.ru])}/>;
            break;
        case 'eng':
            current = <GB className={cls.flag}/>;
            break;
        case 'tur':
            current = <TR className={cls.flag}/>;
            break;
        case 'kaz':
            current = <KZ className={cls.flag}/>;
            break;
        case 'fra':
            current = <FR className={cls.flag}/>;
            break;
        case 'deu':
            current = <DE className={cls.flag}/>;
            break;
        case 'esp':
            current = <ES className={cls.flag}/>;
            break;
    }
    
    const changeLanguage = useChangeLanguage();
    const handleMenuClick: MenuProps['onClick'] = async (e) => {
        await changeLanguage(e.key)
    };

    const items: MenuProps['items'] = [
        {
            label: t('Turkish'),
            key: 'tur',
            icon: <TR className={cls.flag}/>,
        },
        {
            label: t('English'),
            key: 'eng',
            icon: <GB className={cls.flag}/>,
        },
        {
            label: t('French'),
            key: 'fra',
            icon: <FR className={classNames([cls.flag, cls.fr])}/>,
        },
        {
            label: t('German'),
            key: 'deu',
            icon: <DE className={classNames([cls.flag])}/>,
        },
        {
            label: t('Kazakh'),
            key: 'kaz',
            icon: <KZ className={classNames([cls.flag])}/>,
        },
        {
            label: t('Russian'),
            key: 'rus',
            icon: <RU className={classNames([cls.flag, cls.ru])}/>,
        },
        {
            label: t('Spanish'),
            key: 'esp',
            icon: <ES className={classNames([cls.flag])}/>,
        },



    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <>
            <Dropdown menu={menuProps}>
                <Button type={'text'}>
                    {current}
                </Button>
            </Dropdown>
        </>
    );
};
