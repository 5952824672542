import {OfferCalcRentIncome} from 'shared/helpers/OfferCalcRentIncome';
import {OfferCalcSellIncome} from 'shared/helpers/OfferCalcSellIncome';

interface IOfferCalcSumIncome {
    rentCost: number
    partsCount: number
    partPaymentPeriod: number
    activeCost: number
    activePayment: number
    partCost: number

}

export function OfferCalcSumIncome (param:IOfferCalcSumIncome) {

    const {rentCost, partsCount, partPaymentPeriod, activeCost, activePayment, partCost} = param

    // доход с аренды
    const investorsRentIncome = OfferCalcRentIncome({
        rentCost,
        partsCount,
        partPaymentPeriod,
    })

    // доход с купли-продажи
    const investorsSellIncome = OfferCalcSellIncome({
        activeCost,
        partCost,
        activePayment,
    })

    // суммарный доход инвестора
    return investorsRentIncome + investorsSellIncome;

}