import {gql} from '@apollo/client';

export const QUERY_DIALOGUES = gql`
    query dialogs {
        dialogs {
            buying {
                UID
                status
                offerUID
                userUID
                firstName
                lastName
                color
                photo
                unreadMessages
            }
            selling {
                UID
                status
                offerUID
                userUID
                firstName
                lastName
                color
                photo
                unreadMessages
            }
            investing {
                UID
                status
                offerUID
                userUID
                firstName
                lastName
                color
                photo
                unreadMessages
            }
        }
    }
    
`