import {useEffect} from 'react';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import {Store} from 'app/store/Store';
import { observer } from 'mobx-react-lite';
import {goIndexBuy, goIndexInvest} from 'shared/config/routes';

export const IndexRedirect = observer(() => {
    
    const navigate = useNavigate();
    const {filters} = useInstance(Store);

    useEffect(() => {
        if (filters.type === 'invest') {
            navigate(goIndexInvest('map'))
        } else {
            navigate(goIndexBuy('map'))
        }
    }, [filters, navigate]);

    return null
})
