import {gql} from '@apollo/client';

export const SIGN_AGREEMENT_CLIENT= gql`
    mutation signAgreementClient (
        $UID: ID!,
    ) {
        signAgreementClient (UID: $UID) {
            UID
        }
    }
`