import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {ConfigProvider, Modal, Table} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import currency from 'currency.js';

import './antdTweak.scss';
import cls from './PaymentTable.module.scss';

interface IDataType {
    key: React.Key | string;
    investorsSharesBeforePayment: number;
    clientSharesBeforePayment: number;
    rentOfInvestorsShare?: number;
    repurchaseOfInvestorsShare?: number;
    paymentSum: number;
    investorsSharesAfterPayment: number;
    clientSharesAfterPayment: number;
}

interface IPaymentTableProps {
    className?: string;
    isOpen: boolean;
    data: IDataType[];
    onCancel: () => void;
}

interface ITableDataType {
    key: React.Key | string;
    paymentNumber: string;
    investorsSharesBeforePayment: string;
    clientSharesBeforePayment: string;
    rentOfInvestorsShare?: string | number;
    repurchaseOfInvestorsShare?: string | number;
    paymentSum: string | number;
    investorsSharesAfterPayment: string;
    clientSharesAfterPayment: string;
}

const CurrencyFormat = (value: number | string) =>
    currency(value, {
        symbol: '',
        separator: ' ',
        precision: 0,
    }).format();

export const PaymentTableProps = (props: IPaymentTableProps) => {
    const { isOpen, onCancel, data } = props;

    const { t } = useTranslation();

    const columns: ColumnsType<ITableDataType> = useMemo(
        () => [
            {
                title: t('Transaction number'),
                dataIndex: 'paymentNumber',
                width: 90,
                fixed: 'left',
                align: 'right',
                onCell: (_, index) => ({
                    style: index && index % 2 !== 0 ? { backgroundColor: '#F6F7F8' } : {backgroundColor: '#FFFFFF'},
                }),
            },

            {
                title: t('Structure of shares in asset before payment'),
                children: [
                    {
                        title: t('Investor\'s share'),
                        dataIndex: 'investorsSharesBeforePayment',
                        width: 80,
                        align: 'right',
                        onCell: (_, index) => ({
                            colSpan: index === 0 ? 2 : 1,
                            style: index === 0 ? { fontWeight: 'bold' } : {},
                        }),
                    },
                    {
                        title: t('Client\'s share'),
                        dataIndex: 'clientSharesBeforePayment',
                        width: 80,
                        align: 'right',
                        onCell: (_, index) => ({
                            rowSpan: index === 0 ? 0 : 1,
                        }),
                    },
                ],
            },
            {
                title: t('Components of the payment amount'),
                children: [
                    {
                        title: t('Investor\'s share rent'),
                        dataIndex: 'rentOfInvestorsShare',
                        width: 100,
                        align: 'right',
                        className: cls.summand,
                        onCell: (_, index) => ({
                            style: index === 0 ? { fontWeight: 'bold' } : {},
                        }),
                    },
                    {
                        title: t('Investor\'s share redeeming'),
                        dataIndex: 'repurchaseOfInvestorsShare',
                        align: 'right',
                        width: 100,
                        className: cls.summand,
                        onCell: (_, index) => ({
                            style: index === 0 ? { fontWeight: 'bold' } : {},
                        }),
                    },
                ],
            },
            {
                title: t('Amount of payment'),
                dataIndex: 'paymentSum',
                align: 'right',
                width: 100,
                onCell: (_, index) => ({
                    style: index === 0 ? { fontWeight: 'bold' } : {},
                }),
            },
            {
                title: t('Structure of shares in asset after payment'),
                children: [
                    {
                        title: t('Investor\'s share'),
                        dataIndex: 'investorsSharesAfterPayment',
                        width: 80,
                        align: 'right',
                    },
                    {
                        title: t('Client\'s share'),
                        dataIndex: 'clientSharesAfterPayment',
                        align: 'right',
                        width: 80,
                    },
                ],
            },
        ],
        [t],
    );

    const dataSource: ITableDataType[] = data.map((item) => {
        if (item.key === 'sum') {
            return {
                key: item.key,
                paymentNumber: '',
                investorsSharesBeforePayment: t('Total'),
                clientSharesBeforePayment: '',
                rentOfInvestorsShare: item.rentOfInvestorsShare && CurrencyFormat(item.rentOfInvestorsShare),
                repurchaseOfInvestorsShare: item.repurchaseOfInvestorsShare && CurrencyFormat(item.repurchaseOfInvestorsShare),
                paymentSum: CurrencyFormat(item.paymentSum),
                investorsSharesAfterPayment: '',
                clientSharesAfterPayment: '',
            }
        } else {
            return {
                key: item.key,
                paymentNumber: item.key === 'upfront' ? t('Advanced payment') : `${item.key}`,
                investorsSharesBeforePayment: item.investorsSharesBeforePayment.toFixed(1) + ' %',
                clientSharesBeforePayment: item.clientSharesBeforePayment.toFixed(1) + ' %',
                rentOfInvestorsShare: item.rentOfInvestorsShare && CurrencyFormat(item.rentOfInvestorsShare),
                repurchaseOfInvestorsShare: item.repurchaseOfInvestorsShare && CurrencyFormat(item.repurchaseOfInvestorsShare),
                paymentSum: CurrencyFormat(item.paymentSum),
                investorsSharesAfterPayment: item.investorsSharesAfterPayment.toFixed(1) + ' %',
                clientSharesAfterPayment: item.clientSharesAfterPayment.toFixed(1) + ' %',
            }
        }
    })

    return (
        <Modal
            open={isOpen}
            onCancel={onCancel}
            footer={[]}
            className={cls.modal}
        >
            <ConfigProvider theme={{
                token: {
                    fontSize: 12,
                    borderRadius: 0,
                    colorBorder: '#000000',
                },
            }}>
                <Table
                    rootClassName={cls.root}
                    rowClassName={cls.row}
                    bordered
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{ x: 690, y: window.innerWidth > 780 ? window.innerHeight - 40-40-32-12-91 : window.innerHeight - 40-32-12-91}}
                />
            </ConfigProvider>
        </Modal>
    );
};