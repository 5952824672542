import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useMutation} from '@apollo/client';
import {Button, Form, Input, message, Modal} from 'antd';
import {Store} from 'app/store/Store';
import useGetCodeMutation from 'features/Auth/hooks/useGetCodeMutation';
import {ILoginFormValues} from 'features/Auth/model/form';
import useChangeLanguage from 'features/Language/useChangeLanguage';
import {observer} from 'mobx-react-lite';
import {login, loginVariables} from 'shared/graphql/__generated__/login';
import { LOGIN } from 'shared/graphql/login';
import {codeRegex} from 'shared/helpers/regex';

// import {InputPin} from 'widgets/InputPin/InputPin';
import cls from './Auth.module.scss';

export const ModalCode = observer(() => {

    const {auth} = useInstance(Store);
    const {t, i18n} = useTranslation();

    const [loginMutation, {loading}] = useMutation<login, loginVariables>(LOGIN);
    const [codeError, setCodeError] = useState<boolean>(false)

    const [form] = Form.useForm();

    const getCode = useGetCodeMutation()
    const [loadingCode, setLoadingCode] = useState<boolean>(false)

    const changeLanguage = useChangeLanguage();

    const onSubmit = async (values: ILoginFormValues) => {
        try {

            const identifier = auth.email || '';
            const password = values.code;

            await loginMutation({
                variables: {
                    input: {
                        identifier,
                        password,
                        provider: 'local',
                    }
                },
                onCompleted: (data) => {
                    const login = data.login;

                    // очищаем форму
                    form.resetFields()

                    if (login.jwt && !login.user.blocked) {
                        auth.setTokenAndId(login.jwt, login.user.id)
                        auth.closeModalCode()
                        if (login.user.firstName && login.user.lastName && login.user.color) {
                            auth.setData({
                                firstName: login.user.firstName,
                                lastName: login.user.lastName,
                                color: login.user.color,
                                photo: login.user.photo || '',
                                email: login.user.email || '',
                            })
                            auth.setFavoritesOffers(login.user.favoritesOffers || [])
                            changeLanguage(login.user.language)
                        } else {
                            auth.fillAnket()
                        }
                    }
                }
            });

        } catch (error: any) {
            if (error.message === 'Invalid identifier or password') {
                setCodeError(true)
            } else {
                message.error(error.message)
                console.error(error)
            }
        }
    };

    const onResendCode = async () => {
        try {

            setLoadingCode(true)

            const email = auth.email || '';
            const lang = i18n.language;

            const res = await getCode(email, lang)
            const done = res.data?.getCode?.done;
            if (done) {
                auth.enterCode(email)
            }
            setLoadingCode(false)

        } catch (error: any) {
            message.error(error.message)
            console.error(error)
            setLoadingCode(false)
        }
    }

    return (
        <Modal
            open={auth.modalCodeOpen || false}
            closable={false}
            footer={[]}
            title={t('Please check your email {{ email }}', {email: auth.email})}
        >
            <Form layout={'vertical'} onFinish={onSubmit} validateTrigger={'onSubmit'} form={form} initialValues={{code: ''}}>

                <Form.Item label={t('Copy and paste the code from email sent by "SP Robot"')} name={'code'}
                           rules={[
                               {required: true, message: `${t('Please enter the data')}`},
                               {pattern: codeRegex, message: `${t('The code is incorrect')}`},
                           ]}
                           validateStatus={codeError ? 'error' : undefined}
                           help={codeError ? t('The code is incorrect') : undefined}
                >
                    {/*<InputPin/>*/}
                    <Input placeholder="0000-AAA" className={cls.input}/>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType={'submit'} loading={loading} disabled={loading} className={cls.input}>
                        {t('Continue')}
                    </Button>
                </Form.Item>

            </Form>

            <div className={cls.codeLinks}>
                {codeError &&
                    <Button type={'link'} onClick={() => onResendCode()} loading={loadingCode}>{t('Resend the code')}</Button>
                }
                <Button type={'link'} onClick={() => auth.openAuth()}>{t('Correct the email')}</Button>
            </div>


        </Modal>
    );
});