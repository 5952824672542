import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {MenuOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Badge,Button, Dropdown} from 'antd';
import {observer} from 'mobx-react-lite';
import {DIALOGUES, FAVORITES, myOffers} from 'shared/config/routes';

interface IProfileMenu {
    unreadMessages: number
}

export const Menu = observer(({unreadMessages}: IProfileMenu) => {

    const {t} = useTranslation();

    const items: MenuProps['items'] = [
        {
            key: 'Dialogues',
            label: (
                <Link to={DIALOGUES}>{t('Dialogues')} <Badge count={unreadMessages}/></Link>
            ),
        },
        {
            key: 'My offers',
            label: (
                <Link to={myOffers('published')}>{t('My offers')}</Link>
            ),
        },
        {
            key: 'Favorites',
            label: (
                <Link to={FAVORITES}>{t('Favorites')}</Link>
            ),
        },
    ];

    return (
        <Dropdown menu={{items}}>
            <Button type={'text'} icon={<Badge dot={unreadMessages > 0}><MenuOutlined/></Badge>}/>
        </Dropdown>
    );
});