import {applySnapshot,types as t} from 'mobx-state-tree';

export const initialPriceStore = {
    createDialog: 0,
    createOffer: 0,
    createAsset: 0,
};

export const PriceStore = t
    .model({
        createDialog: t.number,
        createOffer: t.number,
        createAsset: t.number,
    })
    .actions((self) => ({
        clearState: () => {
            applySnapshot(self, initialPriceStore);
        },
        setPrice: (createDialog: number, createOffer: number, createAsset: number) => {
            self.createDialog = createDialog;
            self.createOffer = createOffer;
            self.createAsset = createAsset;
        },
    }));
