import {gql} from '@apollo/client';

export const LOGIN = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
        login(input: $input) {
            jwt
            user {
                id
                blocked
                firstName
                lastName
                color
                photo
                favoritesOffers
                email
                language
            }
        }
    }
`;