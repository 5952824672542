import {gql} from '@apollo/client';

export const QUERY_AGREEMENT = gql`
    query agreement ($UID: ID!) {
        agreement (UID: $UID) {
            attributes {
                updatedAt
                status
                dialog {
                    data {
                        attributes {
                            investor {
                                data {
                                    attributes {
                                        firstName
                                        lastName
                                        email
                                    }
                                }
                            }
                            client {
                                data {
                                    attributes {
                                        firstName
                                        lastName
                                        email
                                    }
                                }
                            }
                        }
                    }
                }
                offer {
                    data {
                        attributes {
                            currency {
                                data {
                                    attributes {
                                        name
                                    }
                                }
                            }
                            type
                            activePayment
                            activeCost
                            rentCost
                            partCost
                            calcData
                            country {
                                data {
                                    attributes {
                                        name
                                    }
                                }
                            }
                            region {
                                data {
                                    attributes {
                                        name
                                    }
                                }
                            }
                            countryUse {
                                data {
                                    attributes {
                                        name
                                    }
                                }
                            }
                            regionUse {
                                data {
                                    attributes {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`