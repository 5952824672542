import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CarOutlined, HomeOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {Button, Form, Input, InputNumber, Select} from 'antd'
/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as Flags from 'country-flag-icons/react/3x2';
import _ from 'lodash';
import {countries, countriesVariables} from 'shared/graphql/__generated__/countries';
import {QUERY_COUNTRIES} from 'shared/graphql/queryCountries';
import ExcavatorOutlined from 'widgets/Icons/ExcavatorOutlined';

import {IAsset, ICountriesOptions} from './types';

import cls from 'features/Offer/ui/createOffer/CreateOffer.module.scss';


interface IfromAssetProps {
    data: IAsset
    onComplete?: (values: IAsset) => void
    onDraft?: (values: IAsset) => void
    onSave?: (values: IAsset) => void
    loading?: boolean
}

export const FormAsset = (props: IfromAssetProps) => {

    const {t} = useTranslation();
    const [form] = Form.useForm();

    const [country, setCountry] = useState<string>(props.data.country!)
    const [countries, setCountries] = useState<ICountriesOptions>([])
    const [currency, setCurrency] = useState<string>('')

    const query = useQuery<countries, countriesVariables>(QUERY_COUNTRIES, {
        variables: {
            filters: {enabled: {eq: true}},
            pagination: {limit: 100},
            regionsFilters: {enabled: {eq: true}},
            regionsPagination: {limit: 100},
            currenciesPagination: {limit: 100},
        },
        onCompleted: (data) => {
            let countriesData: ICountriesOptions = []
            _.map(data.countries?.data, item => {

                countriesData.push({
                    id: item.id || '',
                    name: item.attributes?.name || '',
                    code: item.attributes?.code2 || '',
                    currencies: _.map(item.attributes?.currencies?.data, cur => {
                        if (props.data.currency && props.data.currency === cur.id) {
                            setCurrency(cur.attributes?.name!)
                        }
                        return {
                        id: cur.id || '',
                        name: cur.attributes?.name || ''
                    }}),
                    regions: _.map(item.attributes?.regions?.data, reg => ({
                        id: reg.id || '',
                        name: reg.attributes?.name || ''
                    })),
                })
            })
            setCountries(countriesData)

        },
    })

    const onChangeCountry = (value: string) => {
        setCountry(value)
        form.setFieldValue('region', null)
        form.setFieldValue('currency', null)
        setCurrency('')
    }

    const onChangeCurrency = (value: string) => {
        const currentCountry = _.find(countries, {id: country})
        const currentCurrency = _.find(currentCountry?.currencies, {id: value})
        setCurrency(currentCurrency?.name!)
    }

    const onFinish = async (values: IAsset) => {
        if (props.onComplete) {props.onComplete(values)}
        // form.resetFields();
    };

    const onDraft = async () => {
        try {
            await form.validateFields()
            const values: IAsset = form.getFieldsValue()
            if (props.onDraft) {
                props.onDraft(values);
            }
            form.resetFields();
        } catch (e) {
            console.error(e)
        }
    };

    const onSave = async () => {
        try {
            await form.validateFields()
            const values: IAsset = form.getFieldsValue()
            if (props.onSave) {
                props.onSave(values);
            }
        } catch (e) {
            console.error(e)
        }
    };

    const data = _.find(countries, {id: country})
    const currencies = data && data.currencies
    const regions = data && (data.regions.length ? data.regions : undefined)

    return (
        <Form layout={'vertical'} onFinish={onFinish} form={form} initialValues={props.data} component={'div'} disabled={props.loading}>

            <Form.Item
                className={cls.formItem}
                label={t('Asset type')}
                name={'type'}
                rules={[
                    {
                        required: true,
                        message: t('Please enter the data') || '',
                    },
                ]}
            >
                <Select className={cls.input} popupMatchSelectWidth={false}>
                    <Select.Option value={'estate'}>
                        <HomeOutlined/> {t('Real estate') || ''}
                    </Select.Option>
                    <Select.Option value={'tech'}>
                        <ExcavatorOutlined/> {t('Construction vehicles') || ''}
                    </Select.Option>
                    <Select.Option value={'car'}>
                        <CarOutlined/> {t('Transport') || ''}
                    </Select.Option>
                </Select>
            </Form.Item>

            <Form.Item name={'country'} label={t('Country of asset sale')}
                       rules={[
                           {
                               required: true,
                               message: t('Please enter the data') || '',
                           },
                       ]}
                       className={cls.country}
            >
                <Select loading={query.loading} onChange={onChangeCountry}>
                    {countries.map(item => {
                        const Flag = Flags[item.code as keyof typeof Flags]
                        return (
                            <Select.Option key={item.id} value={item.id}>
                                <Flag className={cls.flag}/> {t(item.name)}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>

            {regions &&
                <Form.Item name={'region'} label={t('Region of asset sale')}
                           rules={[
                               {
                                   required: true,
                                   message: t('Please enter the data') || '',
                               },
                           ]}
                           className={cls.region}
                >
                    <Select>
                        {_.map(regions, (item) => (
                            <Select.Option key={item.id} value={item.id}>{t(item.name)}</Select.Option>))}
                    </Select>
                </Form.Item>
            }

            <Form.Item name={'link'} label={t('See the sale announcement')}
                       rules={[
                           {
                               required: true,
                               message: t('Please enter the data') || '',
                           },
                           {
                               type: 'url',
                               message: t('Please enter correct URL') || '',
                           },
                       ]}>

                <Input placeholder={'https://'}/>
            </Form.Item>

            <div className={cls.costCurrency}>
                <Form.Item name={'cost'} label={t('Asset price')}
                           rules={[
                               {
                                   required: true,
                                   message: t('Please enter the data') || '',
                               },
                           ]}
                           className={cls.cost}
                >
                    <InputNumber style={{width: '100%'}}
                                 pattern={'[0-9]*'}
                                 formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    />
                </Form.Item>

                <Form.Item name={'currency'} label={t('Currency')}
                           rules={[
                               {
                                   required: true,
                                   message: t('Please enter the data') || '',
                               },
                           ]}
                           className={cls.currency}
                >
                    <Select disabled={!currencies} onChange={onChangeCurrency}>
                        {_.map(currencies, (item) => (
                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
                    </Select>
                </Form.Item>
            </div>

            <Form.Item name={'minProfit'} label={t('I want to earn, the amount')}
                       rules={[
                           {
                               required: true,
                               message: t('Please enter the data') || '',
                           },
                       ]}
                       className={cls.cost}
            >
                <InputNumber style={{width: '100%'}}
                             pattern={'[0-9]*'}
                             formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                             addonAfter={<span>{currency}</span>}
                />
            </Form.Item>

            {/*<Form.Item name={'minProfit'} label={t('I want to earn, the amount')}*/}
            {/*           rules={[*/}
            {/*               {*/}
            {/*                   required: true,*/}
            {/*                   message: t('Please enter the data') || '',*/}
            {/*               },*/}
            {/*           ]}*/}
            {/*>*/}
            {/*    <div className={cls.costCurrency}>*/}

            {/*        <InputNumber pattern={'[0-9]*'}*/}
            {/*                     formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}*/}
            {/*                     className={cls.cost}*/}
            {/*        />*/}
            {/*        <Select disabled={true} value={form.getFieldValue('currency')} className={cls.currency}>*/}
            {/*            {_.map(currencies, (item) => (*/}
            {/*                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}*/}
            {/*        </Select>*/}
            {/*    </div>*/}
            {/*</Form.Item>*/}

            <Form.Item name={'maxPeriod'} label={t('For duration of the deal, no more than, month')}
                       rules={[
                           {
                               required: true,
                               message: t('Please enter the data') || '',
                           },
                       ]}
            >
                <InputNumber
                    pattern={'[0-9]*'}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    className={cls.duration}
                />
            </Form.Item>

            <Form.Item>
                <div className={cls.publicSave}>

                    {props.onComplete &&
                        <Button type={'primary'} htmlType={'submit'} onClick={() => form.submit()}
                                className={cls.public} loading={props.loading}>
                            {t('Publish')}
                        </Button>
                    }

                    {props.onSave &&
                        <Button type={'primary'} htmlType={'submit'} onClick={onSave} className={cls.save} loading={props.loading}>
                            {t('Save')}
                        </Button>
                    }

                    {props.onDraft &&
                        <Button type={'default'} onClick={() => onDraft()} className={cls.save} loading={props.loading}>
                            {t('Save draft')}
                        </Button>
                    }
                </div>
            </Form.Item>

        </Form>
    )
}