import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useQuery} from '@apollo/client';
import { Skeleton} from 'antd';
import { Cards } from 'app/Layout/Cards';
import {Store} from 'app/store/Store';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import {TypeElement} from 'pages/Index/ui/Layout/TypeElement';
import {offers, offersVariables} from 'shared/graphql/__generated__/offers';
import {QUERY_OFFERS} from 'shared/graphql/queryOffers';
import {useLayout} from 'shared/hooks/useTrainerLayout';
import {OfferCard} from 'widgets/OfferCard';
import {EmptyCard} from 'widgets/OfferCard/ui/EmptyCard';
import {PageTitle} from 'widgets/PageTitle';

export const FavoritesOffers = observer(() => {

    const {t} = useTranslation();
    const {setLayout} = useLayout();
    const { auth } = useInstance(Store);

    const query = useQuery<offers,offersVariables>(QUERY_OFFERS, {
        variables: {
            filters: {
                UID: {in: auth.favoritesOffers.toJSON()},
                status: {in: ['PUBLIC_2']},
            },
            pagination: {
                limit: 1000,
            }
        },
        skip: !auth.hasProfile
    })

    useEffect(() => {
        setLayout({
            title: (
                <PageTitle title={t('Favorites')} control={<TypeElement/>}/>
            ),
        });
    }, [setLayout, t]);

    return (
            <Cards>
                {query.data?.offers?.data.length === 0 && <EmptyCard maxInRow={4}/>}
                {query.loading && <Skeleton active/>}

                {_.map(query.data?.offers?.data, (item) => (
                    <OfferCard key={item.id} offer={item} maxInRow={4}/>
                ))}
            </Cards>
    );
})