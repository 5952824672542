import {gql} from '@apollo/client';

export const UPDATE_ME = gql`
    mutation updateUsersPermissionsUser($data: UsersPermissionsUserInput!, $id: ID!) {
        updateUsersPermissionsUser(data: $data, id: $id) {
            data {
                id
                attributes {
                    email
                    firstName
                    lastName
                    color
                    language
                    photo
                }
            }
        }
    }
`;