import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useLocation, useNavigate} from 'react-router-dom';
import {Store} from 'app/store/Store';

const useChangeLanguage = () => {

    const {lang} = useInstance(Store);
    const {i18n} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();


    return useCallback(async (language: string | null) => {

        if (language) {
            if (i18n.language !== language) {

                lang.setLang(language)
                await i18n.changeLanguage(language)
                
                // если мы на кастомере, то обновляем страницу
                if (location.pathname.includes('/customer')) {
                    navigate(0)
                }

            }
        }

    }, [lang, i18n, location, navigate])
}

export default useChangeLanguage