import {applySnapshot,types as t} from 'mobx-state-tree';

export const initialAuthStore = {
    token: null,
    refresh: null,
    id: null,
    role: null,
    data: null,
    email: '',
    inAuthProcess: false,
    modalEmailOpen: false,
    modalCodeOpen: false,
    modalAnketOpen: false,
    favoritesOffers: [],
    favoritesAssets: [],
};

export const AuthStore = t
    .model({
        token: t.maybeNull(t.string),
        refresh: t.maybeNull(t.string),
        id: t.maybeNull(t.string),
        role: t.maybeNull(t.string),
        data: t.maybeNull(
            t.model({
                firstName: t.string,
                lastName: t.string,
                color: t.string,
                photo: t.string,
                email: t.string,
            }),
        ),
        email: t.maybeNull(t.string),
        inAuthProcess: t.boolean,
        modalEmailOpen: t.boolean,
        modalCodeOpen: t.boolean,
        modalAnketOpen: t.boolean,
        favoritesOffers: t.array(t.string),
        favoritesAssets: t.array(t.string),
    })
    .views((self) => ({
        get hasProfile() {
            return !!self.data;
        },
        isFavoritesOffers(UID: string) {
            return self.favoritesOffers.includes(UID)
        },
        isFavoritesAssets(UID: string) {
            return self.favoritesAssets.includes(UID)
        },
    }))
    .actions((self) => ({
        clearState: () => {
            applySnapshot(self, initialAuthStore);
        },
        setToken: (token: string | null) => {
            self.token = token;
        },
        setRefresh: (refresh: string | null) => {
            self.refresh = refresh;
        },
        setId: (id: string | null) => {
            self.id = id;
        },
        setTokenAndId(token: string | null, id: string | null) {
            self.token = token;
            self.id = id;
        },
        setTokenAndRefresh(token: string | null, refresh: string | null, callback: () => void) {
            self.token = token;
            self.refresh = refresh;

            if (callback) {
                callback();
            }
        },
        setData(data: { firstName: string, lastName: string, color: string, photo: string, email: string}) {
            self.data = data;
            self.inAuthProcess = false
        },
        openAuth() {
            self.modalCodeOpen = false
            self.modalEmailOpen = true
            self.inAuthProcess = true
        },
        closeModalEmail() {
            self.modalEmailOpen = false
            self.inAuthProcess = false
        },
        enterCode(email: string) {
            self.modalEmailOpen = false
            self.modalCodeOpen = true
            self.email = email
        },
        closeModalCode() {
            self.modalCodeOpen = false
            self.inAuthProcess = false
        },
        fillAnket() {
            self.modalCodeOpen = false
            self.modalAnketOpen = true
        },
        closeModalAnket() {
            self.modalAnketOpen = false
            self.inAuthProcess = false
        },
        setFavoritesOffers(data: string[]) {
            self.favoritesOffers.clear()
            for (const item of data) {
                self.favoritesOffers.push(item)
            }
        },
        addToFavoritesOffers(UID: string) {
            self.favoritesOffers.push(UID)
        },
        removeFromFavoritesOffers(UID: string) {
            const filtered = self.favoritesOffers.filter(i => i !== UID)
            self.favoritesOffers.clear()
            for (const item of filtered) {
                self.favoritesOffers.push(item)
            }
        },
        setFavoritesAssets(data: string[]) {
            self.favoritesAssets.clear()
            for (const item of data) {
                self.favoritesAssets.push(item)
            }
        },
        addToFavoritesAssets(UID: string) {
            self.favoritesAssets.push(UID)
        },
        removeFromFavoritesAssets(UID: string) {
            const filtered = self.favoritesAssets.filter(i => i !== UID)
            self.favoritesAssets.clear()
            for (const item of filtered) {
                self.favoritesAssets.push(item)
            }
        },
    }));
