import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CustomIcon = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 36 36">
        <path d="M26.7348 7H24.422C24.0977 7 23.79 7.15376 23.5915 7.41687L9.94977 25.2636L3.40849 16.7041C3.30953 16.5744 3.18341 16.4695 3.03957 16.3973C2.89574 16.3251 2.73793 16.2875 2.57801 16.2873H0.265231C0.0435483 16.2873 -0.0788734 16.5504 0.0567829 16.728L9.11929 28.5848C9.5428 29.1384 10.3567 29.1384 10.7836 28.5848L26.9432 7.43737C27.0789 7.2631 26.9565 7 26.7348 7Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.5945 26.59L18.1193 28.5848C18.5428 29.1384 19.3567 29.1384 19.7835 28.5848L35.9432 7.43737C36.0788 7.2631 35.9564 7 35.7347 7H33.422C33.0977 7 32.79 7.15376 32.5915 7.41687L18.9497 25.2636L18.2753 24.381L16.5945 26.59Z"/>
    </svg>
)

const DoubleCheck = (props: Partial<CustomIconComponentProps>) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
        <Icon component={CustomIcon} {...props} />
    )

export default DoubleCheck