import {useTranslation} from 'react-i18next';

import img_deu from './images/pie-deu.jpg';
import img_eng from './images/pie-eng.jpg';
import img_esp from './images/pie-esp.jpg';
import img_fra from './images/pie-fra.jpg';
import img_kaz from './images/pie-kaz.jpg';
import img_rus from './images/pie-rus.jpg';
import img_tur from './images/pie-tur.jpg';

import cls from './Pres.module.scss';

export const Slide2 = () => {

    const {t, i18n} = useTranslation();

    let src = img_eng;

    switch (i18n.language) {
        case 'rus':
            src = img_rus;
            break;
        case 'eng':
            src = img_eng;
            break;
        case 'tur':
            src = img_tur;
            break;
        case 'kaz':
            src = img_kaz;
            break;
        case 'fra':
            src = img_fra;
            break;
        case 'deu':
            src = img_deu;
            break;
        case 'esp':
            src = img_esp;
            break;
    }

    return (
        <div className={cls.slide}>
            <div className={cls.headline}>
                <p>
                    {t('- How to drive demand for assets?')}
                </p>
                <p>
                    {t('- Bring "+1 Tool" of asset purchasing to the market')}
                </p>
            </div>
            <div className={cls.content}>
                <img className={cls.img} src={src} alt={'Cover'}/>
            </div>
        </div>
    )
}