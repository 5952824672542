import {Avatar, ConfigProvider, Tooltip} from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import cn from 'classnames';

import cls from './SultanAvatar.module.scss'

interface IAvatarProps {
    color: string,
    photo?: string,
    size?: number,
    firstName: string,
    lastName: string,
    className?: string,
}

export const SultanAvatar = (props: IAvatarProps) => {

    const {color, photo, size, firstName, lastName, className} = props

    const letter = `${firstName.charAt(0)}${lastName.charAt(0)}`
    const name = `${firstName} ${lastName}`

    return (
        <ConfigProvider
            locale={ru_RU}
            theme={{
                token: {
                    colorTextLightSolid: '#ffffff',
                },
            }}
        >
            <Tooltip title={name}>
                <Avatar size={size || undefined} style={{backgroundColor: photo ? '#FFFFFF' : color}}
                        src={photo || undefined}
                        className={cn([cls.avatar, className && className])}
                >
                    {letter}
                </Avatar>
            </Tooltip>
        </ConfigProvider>
    );
};