import React, { useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {Map,YMaps, ZoomControl} from '@pbe/react-yandex-maps';
import {Skeleton} from 'antd';
import {Store} from 'app/store/Store';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import { IMapItem } from 'pages/Index/types';
import {GeoPin} from 'widgets/Map/GeoPin';

import cls from './SultanMap.module.scss'

interface IMapProps {
    data: IMapItem[]
    loading?: boolean
}

export const SultanMap = observer((props:IMapProps) => {

    const {data, loading} = props
    const { lang } = useInstance(Store);
    const {t} = useTranslation();
    const map = useRef<any>()
    const [zoom, setZoom] = useState<number>(3)

    let YMapLang:'ru_RU'|'tr_TR'|'en_US'|undefined = 'en_US'
    switch (lang.lang) {
        case 'rus': YMapLang = 'ru_RU'; break;
        case 'tur': YMapLang = 'tr_TR'; break;
        case 'eng': YMapLang = 'en_US'; break;
        case 'kaz': YMapLang = 'ru_RU'; break;
        case 'fra': YMapLang = 'en_US'; break;
        case 'deu': YMapLang = 'en_US'; break;
        case 'esp': YMapLang = 'en_US'; break;
    }

    // рассчет центра по крайним объектам
    let maxLatitude = 0
    let minLatitude = 0
    let maxLongitude = 0
    let minLongitude = 0
    _.forEach(data, item => {
        maxLatitude = Math.max(maxLatitude, item.latitude)
        minLatitude = Math.min(minLatitude, item.latitude)
        maxLongitude = Math.max(maxLongitude, item.longitude)
        minLongitude = Math.min(minLongitude, item.longitude)
    })
    const mapCenter: number[] =
        [
            (maxLatitude + minLatitude) / 2 + 5,
            (maxLongitude + minLongitude) / 2,
        ]

    const options = {}

    if (loading) return <Skeleton active/>

    const handleMapBoundsChange = () => {
        if (map && map.current) {
            setZoom(map.current.getZoom())
        }
    }

    console.log('zoom', zoom)

    return (
            <YMaps query={{load: 'templateLayoutFactory', lang: YMapLang, apikey: 'f519ee1f-04d6-469b-b79d-7203e749761a'}}>
                <Map options={options} className={cls.map} defaultState={{ center: mapCenter, zoom: 3 }} instanceRef={map}
                     onBoundsChange={handleMapBoundsChange}>
                    <ZoomControl options={{size: 'small'}}/>
                    {_.map(data, (item) => (
                        <GeoPin
                            key={`${item.country}${item.region}`}
                            country={item.country}
                            region={item.region}
                            latitude={item.latitude}
                            longitude={item.longitude}
                            name={t(item.name)}
                            url={item.url}
                            open={item.open}
                            disabled={item.disabled}
                            onClick={item.onClick}
                            onOpen={item.onOpen}
                            content={item.content}
                            loading={item.loading}
                        />
                    ))}
                </Map>
            </YMaps>
    )
})