import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';

import 'shared/config/i18n/i18n';

import App from './app/App';

import 'app/styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <ConfigProvider
            locale={ru_RU}
            theme={{
                token: {
                    colorPrimary: '#ffd911',
                    colorTextLightSolid: '#000000',
                    // controlHeight: 48,
                    fontSize: 14,
                },
                components: {
                    Tooltip: {
                        colorTextLightSolid: '#ffffff'
                    },
                },
            }}
        >

            <App />
        </ConfigProvider>
    </React.StrictMode>,
);
