import _, {toInteger} from 'lodash';
import {IPinContent} from 'pages/Index/types';
import {offers_offers_data} from 'shared/graphql/__generated__/offers';
import {OfferCalcSumIncome} from 'shared/helpers/OfferCalcSumIncome';

export function ConvertOffersToPin (offers: offers_offers_data[]) {

    const groups = _.groupBy(offers, 'attributes.type')

    let result:IPinContent[] = []

    _.forEach(groups, (group, key) => {
        result.push({
            type: key,
            count: group.length,
            totalInvest: _.reduce(group, (r, i) => r + toInteger(i.attributes?.investmentSum), 0),
            totalEarn: _.reduce(group, (r, i) => r + OfferCalcSumIncome({
                rentCost: i.attributes?.rentCost!,
                partsCount: i.attributes?.partsCount!,
                partPaymentPeriod: i.attributes?.partPaymentPeriod!,
                activeCost: i.attributes?.activeCost!,
                activePayment: i.attributes?.activePayment!,
                partCost: i.attributes?.partCost!,
            }), 0),
        })
    })

    return  result

}