import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {Link, useNavigate, useParams} from 'react-router-dom';
import ym from 'react-yandex-metrika';
import {CarOutlined, HomeOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {Button, Divider, Popover, Skeleton} from 'antd';
import {Store} from 'app/store/Store';
import currency from 'currency.js';
import {observer} from 'mobx-react-lite';
import {createOfferOverAsset, userList} from 'shared/config/routes';
import {asset, assetVariables} from 'shared/graphql/__generated__/asset';
import {assets, assetsVariables} from 'shared/graphql/__generated__/assets';
import {QUERY_ASSET} from 'shared/graphql/queryAsset';
import {QUERY_ASSETS} from 'shared/graphql/queryAssets';
import {isValidUrl} from 'shared/helpers/isValidURL';
import {useLayout} from 'shared/hooks/useTrainerLayout';
import {SultanAvatar} from 'widgets/Avatar/SultanAvatar';
import {CalcData} from 'widgets/Calc';
import ExcavatorOutlined from 'widgets/Icons/ExcavatorOutlined';
import {PageTitle} from 'widgets/PageTitle';

import cls from 'src/pages/Offer/ui/Offer.module.scss';

const currencyFormat = (value: number | string) =>
    currency(value, {
        symbol: '',
        separator: ' ',
        precision: 0,
    }).format();

export const ViewAsset = observer(() => {

    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {auth} = useInstance(Store);

    const {setLayout} = useLayout();

    useEffect(() => {
        setLayout({
            title: (<PageTitle title={t('Asset')}/>),
        });
    }, [setLayout, t, id]);

    const asset = useQuery<asset, assetVariables>(QUERY_ASSET, {
        variables: {
            UID: id!,
        },
    })

    const assets = useQuery<assets, assetsVariables>(QUERY_ASSETS, {
        variables: {
            filters: {
                author: {UID: {eq: asset.data?.asset?.attributes?.author?.data?.attributes?.UID}},
                status: {in: ['PUBLIC_2']},
            },
            pagination: {
                limit: 1000,
            }
        },
    })

    if (asset.loading) {
        return <Skeleton active/>;
    }

    let type
    switch (asset.data?.asset?.attributes?.asset.type) {
        case 'estate':
            type = <span><HomeOutlined className={cls.icon}/> {t('Real estate')}</span>;
            break;
        case 'tech':
            type = <span><ExcavatorOutlined className={cls.icon}/> {t('Construction vehicles')}</span>;
            break;
        case 'car':
            type = <span><CarOutlined className={cls.icon}/> {t('Transport')}</span>;
            break;
    }

    const authorAssetsCount = assets.data?.assets?.data.length

    const onClickCreateOffer = async () => {
        ym('reachGoal', 'click_create_offer_from_asset');
        navigate(createOfferOverAsset(id!))
    }

    const showButton = auth.hasProfile
        ?
        auth.id !== asset.data?.asset?.attributes?.author?.data?.id!
        :
        true;

    return (
        <div className={cls.Offer}>
            <div className={cls.dataGroup}>
                <div className={cls.wrap}>
                    <CalcData
                        name={t('Asset type')}
                        value={type}
                    />
                    <Divider className={cls.divider}/>
                    <CalcData
                        name={t('Country of asset sale')}
                        value={t(asset.data?.asset?.attributes?.asset.country?.data?.attributes?.name!)}
                    />
                    <Divider className={cls.divider}/>
                    <CalcData
                        name={t('Asset price')}
                        value={currencyFormat(asset.data?.asset?.attributes?.asset.cost!)}
                    />
                    <Divider className={cls.divider}/>
                    <CalcData
                        name={t('Transaction currency')}
                        value={asset.data?.asset?.attributes?.asset.currency?.data?.attributes?.name!}
                    />
                    {isValidUrl(asset.data?.asset?.attributes?.asset.link!) &&
                        <>
                            <Divider className={cls.divider}/>
                            <CalcData
                                name={t('See the sale announcement')}
                                value={
                                    <Popover
                                        content={`${asset.data?.asset?.attributes?.asset.link!}`}
                                    >
                                        <a className={cls.getTabel} href={asset.data?.asset?.attributes?.asset.link!}
                                           target={'_blank'} rel={'noreferrer'}>{t('Review')}</a>
                                    </Popover>
                                }
                            />
                        </>
                    }
                    <Divider className={cls.divider}/>
                    <CalcData
                        name={t('Expected income')}
                        value={currencyFormat(asset.data?.asset?.attributes?.minProfit!)}
                    />
                    <Divider className={cls.divider}/>
                    <CalcData
                        name={t('Duration of the deal, no more than, month')}
                        value={asset.data?.asset?.attributes?.maxPeriod!}
                    />

                    {showButton &&
                        <>
                            <Divider className={cls.divider}/>
                            <Button block type={'primary'} onClick={onClickCreateOffer}>{t('Submit the offer')}</Button>
                        </>
                    }

                </div>
            </div>

            <div className={cls.dataGroup}>
                <div className={cls.wrap}>
                    <div className={cls.author}>
                        <SultanAvatar
                            size={40}
                            color={asset.data?.asset?.attributes?.author?.data?.attributes?.color || '#F0F0F0'}
                            firstName={asset.data?.asset?.attributes?.author?.data?.attributes?.firstName || ''}
                            lastName={asset.data?.asset?.attributes?.author?.data?.attributes?.lastName || ''}
                            photo={asset.data?.asset?.attributes?.author?.data?.attributes?.photo || undefined}
                        />
                        <div>
                            <div
                                className={cls.name}>{asset.data?.asset?.attributes?.author?.data?.attributes?.firstName} {asset.data?.asset?.attributes?.author?.data?.attributes?.lastName}</div>
                            <div className={cls.offers}><Link
                                to={userList(asset.data?.asset?.attributes?.author?.data?.attributes?.UID!)}
                                className={cls.link}>{t('{{x}} offers', {x: authorAssetsCount})}</Link></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
});