import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CarOutlined, HomeOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {Button, Form, Input, Select} from 'antd';
/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as Flags from 'country-flag-icons/react/3x2'
import {ICreateOfferFormValues} from 'features/Offer/model/types/form';
import _ from 'lodash';
import {countries, countriesVariables} from 'shared/graphql/__generated__/countries';
import {QUERY_COUNTRIES} from 'shared/graphql/queryCountries';
import ExcavatorOutlined from 'widgets/Icons/ExcavatorOutlined';

import cls from 'features/Offer/ui/createOffer/CreateOffer.module.scss';

interface IFormOfferProps {
    initialData: ICreateOfferFormValues;
    onCompleted: (values: ICreateOfferFormValues) => void;
    disabled?: boolean;
}

type ICurrenciesOptions = {
    id: string,
    name: string,
};

type IRegionsOptions = {
    id: string,
    name: string,
};

type ICountriesOptions = {
    id: string,
    name: string,
    code: string,
    currencies: ICurrenciesOptions[],
    regions: IRegionsOptions[],
}[];

export const FormOffer = ({initialData, onCompleted, disabled}: IFormOfferProps) => {

    const [assetType, setAssetType] = useState(initialData.type)

    const [countries, setCountries] = useState<ICountriesOptions>([])
    const [country, setCountry] = useState<string>(initialData.country)
    const [countryUse, setCountryUse] = useState<string>(initialData.countryUse || '')

    const [isUpdated, setIsUpdated] = useState<boolean>(false)

    const {t} = useTranslation();
    const [form] = Form.useForm();

    const query = useQuery<countries, countriesVariables>(QUERY_COUNTRIES, {
        variables: {
            filters: {enabled: {eq: true}},
            pagination: {limit: 100},
            regionsFilters: {enabled: {eq: true}},
            regionsPagination: {limit: 100},
            currenciesPagination: {limit: 100},
        },
        onCompleted: (data) => {
            let countriesData: ICountriesOptions = []
            _.map(data.countries?.data, item => {

                countriesData.push({
                    id: item.id || '',
                    name: item.attributes?.name || '',
                    code: item.attributes?.code2 || '',
                    currencies: _.map(item.attributes?.currencies?.data, cur => ({
                        id: cur.id || '',
                        name: cur.attributes?.name || ''
                    })),
                    regions: _.map(item.attributes?.regions?.data, reg => ({
                        id: reg.id || '',
                        name: reg.attributes?.name || ''
                    })),
                })
            })
            setCountries(countriesData)
        },
    })

    const onChangeCountry = (value: string) => {
        setCountry(value)
        form.setFieldValue('currency', '')
    }

    const onFinish = async (values: ICreateOfferFormValues) => {
        onCompleted(values);
    };

    const data = _.find(countries, {id: country})
    const currencies = data && data.currencies
    const regions = data && (data.regions.length ? data.regions : undefined)

    const dataUse = _.find(countries, {id: countryUse})
    const regionsUse = dataUse && (dataUse.regions.length ? dataUse.regions : undefined)



    return (

        <Form layout={'vertical'} onFinish={onFinish} form={form} initialValues={initialData} onValuesChange={() => setIsUpdated(true)} disabled={disabled}>

            <Form.Item
                className={cls.formItem}
                label={t('Asset type')}
                name={'type'}
                rules={[
                    {
                        required: true,
                        message: t('Please enter the data') || '',
                    },
                ]}
            >
                <Select className={cls.input} popupMatchSelectWidth={false}
                        onChange={(value) => setAssetType(value)}>
                    <Select.Option value={'estate'}>
                        <HomeOutlined/> {t('Real estate') || ''}
                    </Select.Option>
                    <Select.Option value={'tech'}>
                        <ExcavatorOutlined/> {t('Construction vehicles') || ''}
                    </Select.Option>
                    <Select.Option value={'car'}>
                        <CarOutlined/> {t('Transport') || ''}
                    </Select.Option>
                </Select>
            </Form.Item>

            <Form.Item name={'country'} label={t('Country of the asset purchasing')}
                       rules={[
                           {
                               required: true,
                               message: t('Please enter the data') || '',
                           },
                       ]}
            >
                <Select loading={query.loading} onChange={value => onChangeCountry(value)}>
                    {countries.map(item => {
                        const Flag = Flags[item.code as keyof typeof Flags]
                        return (
                            <Select.Option key={item.id} value={item.id}>
                                <Flag className={cls.flag}/> {t(item.name)}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>

            {regions &&
                <Form.Item name={'region'} label={t('Region of the asset purchasing')}
                           rules={[
                               {
                                   required: true,
                                   message: t('Please enter the data') || '',
                               },
                           ]}>
                    <Select>
                        {_.map(regions, (item) => (
                            <Select.Option key={item.id} value={item.id}>{t(item.name)}</Select.Option>))}
                    </Select>
                </Form.Item>
            }

            {assetType !== 'estate' &&
                <>
                    <Form.Item name={'countryUse'} label={t('Country of predominant use of the asset')}
                               rules={[
                                   {
                                       required: true,
                                       message: t('Please enter the data') || '',
                                   },
                               ]}>
                        <Select onChange={value => setCountryUse(value)}>
                            {countries.map(item => {
                                const Flag = Flags[item.code as keyof typeof Flags]
                                return (
                                    <Select.Option key={item.id} value={item.id}>
                                        <Flag className={cls.flag}/> {t(item.name)}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    {regionsUse &&
                        <Form.Item name={'regionUse'} label={t('Region of predominant use of the asset')}
                                   rules={[
                                       {
                                           required: true,
                                           message: t('Please enter the data') || '',
                                       },
                                   ]}>
                            <Select>
                                {_.map(regionsUse, (item) => (
                                    <Select.Option key={item.id} value={item.id}>{t(item.name)}</Select.Option>))}
                            </Select>
                        </Form.Item>
                    }
                </>
            }

            <Form.Item name={'currency'} label={t('Transaction currency')}
                       rules={[
                           {
                               required: true,
                               message: t('Please enter the data') || '',
                           },
                       ]}>
                <Select disabled={!currencies || disabled}>
                    {_.map(currencies, (item) => (
                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
                </Select>
            </Form.Item>

            <Form.Item name={'link'} label={t('See the sale announcement')}
                       rules={[
                           {
                               required: true,
                               message: t('Please enter the data') || '',
                           },
                           {
                               type: 'url',
                               message: t('Please enter correct URL') || '',
                           },
                       ]}>
                <Input placeholder={'https://'}/>
            </Form.Item>

            {!disabled &&
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} disabled={!isUpdated}>
                    {t('Save')}
                </Button>
            </Form.Item>
            }

        </Form>
    );
}