import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {DownloadOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import ava1 from 'pages/Pres/images/ava1.jpg';
import ava2 from 'pages/Pres/images/ava2.jpg';
import ava3 from 'pages/Pres/images/ava3.jpg';
import { PRESENTATION_GLOBAL } from 'shared/config/routes';
import {useLayout} from 'shared/hooks/useTrainerLayout';
import {MainFooter} from 'widgets/MainFooter/MainFooter';
import {PageTitle} from 'widgets/PageTitle';

import cls from './Breeding.module.scss';

export const Breeding = () => {

    const {setLayout} = useLayout();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        setLayout({
            title: (
                <PageTitle
                    title={
                        <div className={cls.pageTitle}>
                            {t('Platform presentation')}
                        </div>
                    }
                />
            ),
            footer: (<MainFooter/>)
        });
    }, [setLayout, t]);

    let presUrl = '/GlobalHalal_Pres_EN.pdf'
    switch (i18n.language) {
        case 'rus':
            presUrl = '/GlobalHalal_Pres_RU.pdf'; break;
        case 'kaz':
            presUrl = '/GlobalHalal_Pres_RU.pdf'; break;
        case 'tur':
            presUrl = '/GlobalHalal_Pres_TR.pdf'; break;
    }

    return (
        <div className={cls.wrapper}>

            <div className={cls.slide}>
                <div className={cls.content}>
                    <div className={cls.slide3items}>

                        <div className={cls.item}>
                            <div className={cls.ava}><img src={ava2} alt={'avatar'}/></div>
                            <div>
                                {t('For the economic development of the region/territory decision-makers')}
                            </div>
                            <a href={presUrl} target={'_blank'} rel={'noreferrer'}>
                                <Button className={cls.button} type={'primary'} icon={<DownloadOutlined/>}>{t('Download')}</Button>
                            </a>
                        </div>

                        <div className={cls.item}>
                            <div className={cls.ava}><img src={ava1} alt={'avatar'}/></div>
                            <div>
                                {t('For owners of assets without financial encumbrances (pledges)')}
                            </div>
                            <a href={presUrl} target={'_blank'} rel={'noreferrer'}>
                                <Button className={cls.button} type={'primary'} icon={<DownloadOutlined/>}>{t('Download')}</Button>
                            </a>
                        </div>

                        <div className={cls.item}>
                            <div className={cls.ava}><img src={ava3} alt={'avatar'}/></div>
                            <div>
                                {t('For investment funds, financial managers, private investors (individuals)')}
                            </div>
                            <Button className={cls.button} type={'primary'} onClick={() => navigate(PRESENTATION_GLOBAL)}>{t('Open')}</Button>
                        </div>
                        

                    </div>
                </div>
            </div>

        </div>
    )
}
