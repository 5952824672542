import {gql} from '@apollo/client';

export const CREATE_OFFER = gql`
    mutation createOffer ($data: OfferInput!) {
        createOffer(data: $data) {
            data {
                id
            }
        }
    }
`