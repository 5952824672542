import {useEffect, useMemo} from 'react';
import {useInstance} from 'react-ioc';
import {useNavigate,useParams, useSearchParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {Store} from 'app/store/Store';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import {AssetsFilter} from 'pages/Index/Buy/AssetsFilter';
import {filtersToQuery, filtersToSearchParams, IAssetsFilters, searchParamsToFilters} from 'pages/Index/Buy/filter';
import {IMapItem} from 'pages/Index/types';
import {Assets} from 'pages/Index/ui/Content/Assets';
import {SultanMap} from 'pages/Index/ui/Content/SultanMap'
import IndexLayout from 'pages/Index/ui/Layout/IndexLayout';
import {ModeElement} from 'pages/Index/ui/Layout/ModeElement';
import {TypeElement} from 'pages/Index/ui/Layout/TypeElement';
import {goIndexBuy} from 'shared/config/routes';
import {assets, assetsVariables} from 'shared/graphql/__generated__/assets';
import {countries,countriesVariables} from 'shared/graphql/__generated__/countries';
import {QUERY_ASSETS} from 'shared/graphql/queryAssets';
import {QUERY_COUNTRIES} from 'shared/graphql/queryCountries';
import {ConvertAssetsToPin} from 'shared/helpers/ConvertAssetsToPin';
import {useLayout} from 'shared/hooks/useTrainerLayout';

import { getApiBase } from '../../../env';

export const Buy = observer(() => {

    // определяем что в url - карта или список
    const {mode} = useParams();
    // подключаем навигатор
    const navigate = useNavigate();

    // подключаем стор
    const store = useInstance(Store);

    // ставим глобальный лэйаут без тайтла
    const { setLayout } = useLayout();
    useEffect(() => {
        setLayout({
            title: null,
        });
    }, [setLayout]);

    // работа с фильтрами
    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);

    // главный запрос
    const queryAssets = useQuery<assets, assetsVariables>(QUERY_ASSETS, {
        variables: {
            filters: {
                asset: filtersToQuery(filters),
                status: {eq: 'PUBLIC_2'},
            },
            pagination: {
                limit: 1000,
            }
        },
    })

    // запрос всех стран
    const queryCountries = useQuery<countries, countriesVariables>(QUERY_COUNTRIES, {
        variables: {
            filters: {enabled: {eq: true}},
            pagination: {limit: 100},
            regionsFilters: {enabled: {eq: true}},
            regionsPagination: {limit: 100},
            currenciesPagination: {limit: 100},
        },
        onCompleted: (data) => {
            const countryData = _.map(data.countries?.data, item => ({
                    name: item.attributes?.name,
                    code: item.attributes?.code2,
                    currencies: _.map(item.attributes?.currencies?.data, cur => ({
                        name: cur.attributes?.name
                    })),
                    regions: _.map(item.attributes?.regions?.data, reg => ({
                        name: reg.attributes?.name,
                        logo: reg.attributes?.logo.data?.attributes?.url
                    }))
                }))
            store.countries.setCountries(countryData)
        },
    })

    // assets - country, region, length
    const assetsData = _.map(queryAssets.data?.assets?.data, asset => ({
        UID: asset?.attributes?.UID!,
        country: asset.attributes?.asset.country?.data?.attributes?.name!,
        region: asset.attributes?.asset.region?.data?.attributes?.name,
    }))

    // конвертируем данные для карты
    const countriesData = _.map(queryCountries.data?.countries?.data, item => ({
        name: item.attributes?.name!,
        code: item.attributes?.code2!,
        latitude: item.attributes?.map?.latitude!,
        longitude: item.attributes?.map?.longitude!,
        hasAssets: _.findIndex(assetsData, {'country': item.attributes?.name!}) >= 0,
        regions: _.map(item.attributes?.regions?.data, region => ({
            name: region.attributes?.name!,
            logo: region.attributes?.logo.data?.attributes?.url!,
            latitude: region.attributes?.map?.latitude!,
            longitude: region.attributes?.map?.longitude!,
            hasAssets: _.findIndex(assetsData, {'region': region.attributes?.name!}) >= 0,
        })),
    }))

    // фильтруем массив активов по стране и региону из фильтра
    const displayAssets = _.filter(queryAssets.data?.assets?.data, asset => {
        const countryMatch = filters.country ? asset.attributes?.asset.country?.data?.attributes?.name === filters.country : true
        const regionMatch = filters.region ? asset.attributes?.asset.region?.data?.attributes?.name === filters.region : true
        return countryMatch && regionMatch
    })

    const onOpenGeoPin = (countryId: string|null, regionId: string|null) => {
        const newFilter = {
            ...filters,
            country: countryId,
            region: regionId,
        }
        setSearchParams(filtersToSearchParams(newFilter))
    }

    const onChangeFilter = (filters:IAssetsFilters) => {
        setSearchParams(filtersToSearchParams(filters))
    }

    const onChangeMode = (value:'map'|'list') => {
        navigate(goIndexBuy(value, searchParams.toString()))
    }


    const mapData: IMapItem[] = useMemo(() => {
        const items: IMapItem[] = [];
        _.forEach(countriesData, (country) => {

            if (country.regions.length) {

                _.forEach(country.regions, (region) => {
                    const latitude = region.latitude;
                    const longitude = region.longitude;
                    const name = region.name;

                    if (!!region && !!latitude && !!longitude && !!name) {
                        const x = parseFloat(latitude);
                        const y = parseFloat(longitude);

                        if (!isNaN(x) && !isNaN(y)) {

                            items.push({
                                country: country.name,
                                region: region.name,
                                name,
                                latitude: x,
                                longitude: y,
                                url: `${getApiBase()}${region.logo}`,
                                disabled: !region.hasAssets,
                                open: filters.country === country.name && filters.region === region.name,
                                onOpen: onOpenGeoPin,
                                onClick: () => onChangeMode('list'),
                                content: ConvertAssetsToPin(displayAssets)
                            });
                        }
                    }

                })

            } else {
                const latitude = country.latitude;
                const longitude = country.longitude;
                const name = country.name;

                if (!!country && !!latitude && !!longitude && !!name) {
                    const x = parseFloat(latitude);
                    const y = parseFloat(longitude);

                    if (!isNaN(x) && !isNaN(y)) {

                        items.push({
                            country: country.name!,
                            region: null,
                            name,
                            latitude: x,
                            longitude: y,
                            url: `https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.code}.svg`,
                            disabled: !country.hasAssets,
                            open: filters.country === country.name,
                            onOpen: onOpenGeoPin,
                            onClick: () => onChangeMode('list'),
                            content: ConvertAssetsToPin(displayAssets)
                        });
                    }
                }
            }

        });
        return items;
        // eslint-disable-next-line
    }, [countriesData, filters])

    const modeValue = mode === 'map' ? 'map' : 'list'

    return (
        <IndexLayout filterElement={<AssetsFilter filters={filters} onChange={onChangeFilter}/>} modeElement={<ModeElement mode={modeValue} onChange={onChangeMode}/>} typeElement={<TypeElement/>}>
            {mode !== 'list' && <SultanMap data={mapData} loading={queryCountries.loading}/>}
            {mode === 'list' && <Assets data={displayAssets} loading={queryAssets.loading}/>}
        </IndexLayout>
    )
})