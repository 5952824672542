import {useEffect, useState} from 'react';
import Stories from 'react-insta-stories';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import { useLayout } from 'shared/hooks/useTrainerLayout';
import One from 'widgets/Slides/One';
import Three from 'widgets/Slides/Three';
import Two from 'widgets/Slides/Two';

import cls from './customer.module.scss';

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export function Customer() {
    
    const { setLayout } = useLayout();
    useEffect(() => {
        setLayout({
            title: null,
            footer: null,
        });
    }, [setLayout]);

    const storyContent = {
        width: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 'auto',

    }

    const contaiterStyle = {
        backgroundColor: '#F6F7F8',
        borderRadius: 16,
        overflow: 'hidden',
    }

    const [index, setIndex] = useState<number>(0)

    const [height, setHeight] = useState(getWindowDimensions().height);
    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setHeight(getWindowDimensions().height);
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const vertical = height > width;
    const storyHeight = Math.max(width > 500 ? height - 64 - 32 - 32 : height - 104 - 32 - 32, 400);
    const storyWidth = vertical ? width - 32 : Math.round(storyHeight / 1.68);
    const buttons = width > storyWidth + 32 + 64;

    const stories = [
        {
            content: () => (<One width={storyWidth} height={storyHeight}/>),
            duration: 4000,
        },
        {
            content: () => (<Two width={storyWidth} height={storyHeight}/>),
            duration: 10000,
        },
        {
            content: () => (<Three height={storyHeight}/>),
        },
    ]

    const onStoryStart = (props:any) => {
        setIndex(props)
    }

    const progressContainerStyles = {
        zIndex: 100,
        filter: 'none',
    }

    const progressWrapperStyles = {
        height: 4,
        backgroundColor: '#E2E5E9',
        borderRadius: 2,
    }

    const progressStyles = {
        height: 4,
        backgroundColor: '#00965E',
        borderRadius: 2,
    }

    return (
        <div className={cls.storiesWrapper}>
            {buttons && <Button icon={<LeftOutlined/>} onClick={() => setIndex(index - 1)} disabled={index <= 0}/>}
            <Stories
                progressContainerStyles={progressContainerStyles}
                progressWrapperStyles={progressWrapperStyles}
                progressStyles={progressStyles}
                currentIndex={index}
                onStoryStart={onStoryStart}
                width={storyWidth}
                height={storyHeight}
                stories={stories}
                defaultInterval={5000}
                storyStyles={storyContent}
                loop={false}
                keyboardNavigation={true}
                storyContainerStyles={contaiterStyle}
            />
            {buttons && <Button icon={<RightOutlined/>} onClick={() => setIndex(index + 1)} disabled={index >= 2}/>}
        </div>

    );
}