export interface ITypeOfActive {
    estate: boolean;
    tech: boolean;
    car: boolean;
}

export const typeToUrl = (type:ITypeOfActive) => {
    let result = ''
    for (const [key, value] of Object.entries(type)) {
        if (value) result+=`${key},`
    }
    return result
}

export const typeToQuery = (type:ITypeOfActive) => {
    let result = []
    for (const [key, value] of Object.entries(type)) {
        if (value) {result.push(key)}
    }
    return result
}

export const urlToType = (value:string) => ({
    estate: value.split(',').includes('estate'),
    tech: value.split(',').includes('tech'),
    car: value.split(',').includes('car')
}) as ITypeOfActive

export const Currencies = {
    CurrencyFormatLE: 'ruble',
    DOLLAR: 'dollar',
} as const;

export const CurrencyLettersEnum = {
    CurrencyFormatLE: '₽',
    DOLLAR: '$',
} as const;

export type CurrencyLetters = typeof CurrencyLettersEnum[keyof typeof CurrencyLettersEnum]

export interface CurrencyAmount {
    letter: string;
    startAmount: number | null;
    endAmount: number | null;
}

