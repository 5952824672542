import React from 'react';
import _ from 'lodash';
import {IDialogsItems} from 'widgets/Menu/Menu';
import {MenuDialog} from 'widgets/Menu/MenuDialog';

import cls from './Menu.module.scss';


interface IMenuDialogsProps {
    dialogs: IDialogsItems[];
    selected: string;
}

export const MenuDialogs = ({dialogs, selected}: IMenuDialogsProps) => {

    if (dialogs.length === 0) {return null}

    return (
    <div className={cls.dialogs}>
        {_.map(dialogs, (dialog) => {
            const selectedItem = dialog.UID === selected
            return (<MenuDialog key={dialog.key} dialog={dialog} selected={selectedItem}/>)
        })}
    </div>
)}