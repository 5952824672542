import {useEffect,useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {WarningOutlined} from '@ant-design/icons';
import {Button, Divider,Form, InputNumber} from 'antd';
import {Store} from 'app/store/Store';
import {IOfferFormValues} from 'features/Offer/model/types/form';
import _ from 'lodash';
import {CurrencyFormat} from 'shared/helpers/CurrencyFormat';

import cls from 'features/Offer/ui/form/OfferForm.module.scss';


interface IFormCalcProps {
    initialData: IOfferFormValues;
    onCompleted: (values: IOfferFormValues) => void;
}

export const FormCalc = ({initialData, onCompleted}: IFormCalcProps) => {

    const {t} = useTranslation();
    const [form] = Form.useForm();

    const { offer } = useInstance(Store);
    const { setFormValues, setHasChanges, setCalculated } = offer;

    const [assetCost, setAssetCost] = useState(initialData.assetCost);
    const [firstPayment, setFirstPayment] = useState(initialData.firstPayment);
    const [firstPaymentPercent, setFirstPaymentPercent] = useState(initialData.firstPaymentPercent);
    const [paymentPeriod, setPaymentPeriod] = useState(initialData.paymentPeriod);
    const [numberOfSubShares, setNumberOfSubShares] = useState(initialData.numberOfSubShares);
    const [isNumberOfSubSharesChanged, setIsNumberOfSubSharesChanged] = useState(false);
    const [dealPeriod, setDealPeriod] = useState<Number|undefined>(undefined)

    const [isUpdated, setIsUpdated] = useState<boolean>(false)

    const onFinish = async (values: IOfferFormValues) => {
        setFormValues(values);
        onCompleted(values);
    };

    const assetCostHandler = (value: string) => {
        setAssetCost(+value);
        setHasChanges(true);
        setCalculated(false);

        // если известна сумма 1ого платежа - вычисляем % 1ого платежа и количество платежей
        if (firstPayment) {
            const calculatedPaymentPercent: number = +((firstPayment / +value) * 100).toFixed(0);

            // % 1ого платежа
            form.setFieldValue('firstPaymentPercent', calculatedPaymentPercent);
            setFirstPaymentPercent(calculatedPaymentPercent);

            // количество платежей
            if (!isNumberOfSubSharesChanged) {
                form.setFieldValue('numberOfSubShares', 100 - calculatedPaymentPercent);
                setNumberOfSubShares(100 - calculatedPaymentPercent);
            }

            // после вычислений явно валидируем новые данные
            form.validateFields(['firstPaymentPercent', 'numberOfSubShares']);
        } else if (firstPaymentPercent) {
            // доля для выкупа
            form.setFieldValue('shareToRepayment', 100 - firstPaymentPercent);
            // количество платежей
            if (!isNumberOfSubSharesChanged) {
                form.setFieldValue('numberOfSubShares', 100 - firstPaymentPercent);
                setNumberOfSubShares(100 - firstPaymentPercent);
            }

            // устанавливаем сумму первого взноса
            form.setFieldValue('firstPayment', ((+value / 100) * firstPaymentPercent).toFixed(0));
        } else {
            // % 1ого платежа
            form.setFieldValue('firstPaymentPercent', '');
            setFirstPaymentPercent(0);

            // количество платежей
            if (!isNumberOfSubSharesChanged) {
                form.setFieldValue('numberOfSubShares', '');
                setNumberOfSubShares(0);
            }
        }
    };

    const firstPaymentHandler = (value: number) => {
        setFirstPayment(value);
        setHasChanges(true);
        setCalculated(false);


        // если известна общая стоимость - вычисляем % 1ого платежа и количество платежей
        if (assetCost) {
            const calculatedPaymentPercent: number = +((+value / assetCost) * 100).toFixed(0);

            // % 1ого платежа
            form.setFieldValue('firstPaymentPercent', calculatedPaymentPercent);
            setFirstPaymentPercent(calculatedPaymentPercent);

            // количество платежей
            if (!isNumberOfSubSharesChanged) {
                form.setFieldValue('numberOfSubShares', 100 - calculatedPaymentPercent);
                setNumberOfSubShares(100 - calculatedPaymentPercent);
            }

            // после вычислений явно валидируем новые данные
            form.validateFields(['firstPaymentPercent', 'numberOfSubShares']);
        } else {
            // % 1ого платежа
            form.setFieldValue('firstPaymentPercent', '');
            setFirstPaymentPercent(0);

            // количество платежей
            if (!isNumberOfSubSharesChanged) {
                form.setFieldValue('numberOfSubShares', '');
                setNumberOfSubShares(0);
            }
        }
    };

    const firstPaymentPercentHandler = (value: string) => {
        setFirstPaymentPercent(+value);
        setHasChanges(true);
        setCalculated(false);


        // при корректном вводе высчитываем значение доли для выкупа, количество платежей и % 1ого платежа
        if (+value < 100 && assetCost) {
            // доля для выкупа
            form.setFieldValue('shareToRepayment', 100 - +value);
            // количество платежей
            if (!isNumberOfSubSharesChanged) {
                form.setFieldValue('numberOfSubShares', 100 - +value);
                setNumberOfSubShares(100 - +value);
            }

            // устанавливаем сумму первого взноса
            form.setFieldValue('firstPayment', ((assetCost / 100) * +value).toFixed(0));

            // после вычислений явно валидируем новые данные
            form.validateFields(['numberOfSubShares', 'firstPayment', '']);
        } else {
            // доля для выкупа
            form.setFieldValue('shareToRepayment', '');
            // количество платежей
            if (!isNumberOfSubSharesChanged) {
                form.setFieldValue('numberOfSubShares', '');
                setNumberOfSubShares(0);
            }

            // устанавливаем сумму первого взноса
            form.setFieldValue('firstPayment', '');
        }
    };

    const paymentPeriodHandler = (value: number) => {
        setPaymentPeriod(value);
        setHasChanges(true);
        setCalculated(false);
    };

    const numberOfSubSharesHandler = (value: number) => {
        setNumberOfSubShares(value);
        setIsNumberOfSubSharesChanged(true);
        setHasChanges(true);
        setCalculated(false);
    };

    useEffect(() => {
        if (numberOfSubShares && paymentPeriod) {
            setDealPeriod(numberOfSubShares * paymentPeriod)
            form.setFieldValue('dealPeriod', Number(numberOfSubShares * paymentPeriod))
        } else {
            setDealPeriod(undefined)
            form.setFieldValue('dealPeriod', undefined)
        }
        // form.validateFields(['dealPeriod'])
    }, [firstPayment, numberOfSubShares, paymentPeriod, form])

    let investorCost = '0';
    if (form.getFieldValue('assetCost') > 0 && form.getFieldValue('firstPayment') > 0) {
        investorCost = CurrencyFormat(+form.getFieldValue('assetCost') - form.getFieldValue('firstPayment'));
    }

    return (

        <Form onFinish={onFinish} form={form} initialValues={initialData}
              onValuesChange={() => {setIsUpdated(true)}}
              labelAlign={'left'}
              component={'div'}
        >

            <Form.Item
                className={cls.formItem}
                label={t('Asset price')}
                name={'assetCost'}
                validateFirst
                dependencies={['firstPayment']}
                rules={[
                    {
                        required: true,
                        message: t('Please enter the data') || '',
                    },
                    () => ({
                        validator(_, value) {
                            const firstPayment = form.getFieldValue('firstPayment') || 0;

                            if (+value > firstPayment) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('Please enter correct data') || ''));
                        },
                    }),
                ]}
            >
                <InputNumber
                    disabled={initialData.assetCostDisabled}
                    onChange={_.debounce((event) => assetCostHandler(event), 100)}
                    pattern={'[0-9]*'}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    bordered={false}
                    className={cls.input}
                    onPressEnter={() => form.submit()}
                />
            </Form.Item>

            <Divider className={cls.divider} />

            <Form.Item
                className={cls.formItem}
                label={t('Amount you pay upon purchasing')}
                name={'firstPayment'}
                validateFirst
                dependencies={['assetCost']}
                rules={[
                    {
                        required: true,
                        message: t('Please enter the data') || '',
                    },
                    () => ({
                        validator(_, value) {
                            const assetCost = form.getFieldValue('assetCost') || 0;

                            if (+value < assetCost) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('Please enter correct data') || ''));
                        },
                    }),
                ]}
            >
                <InputNumber
                    bordered={false}
                    className={cls.input}
                    onChange={_.debounce((event) => firstPaymentHandler(event), 100)}
                    pattern={'[0-9]*'}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    onPressEnter={() => form.submit()}
                />
            </Form.Item>

            <Divider className={cls.divider} />

            <Form.Item
                className={cls.formItem}
                label={t('Your initial asset share, %')}
                name={'firstPaymentPercent'}
                validateFirst
                rules={[
                    {
                        required: true,
                        message: t('Please enter the data') || '',
                    },
                ]}
            >
                <InputNumber
                    bordered={false}
                    className={cls.input}
                    onChange={_.debounce((event) => {firstPaymentPercentHandler(event);}, 100)}
                    formatter={(value) => `${value}%`}
                    pattern={'[0-9]*'}
                    onPressEnter={() => form.submit()}
                />
            </Form.Item>

            <Divider className={cls.divider} />

            <Form.Item className={cls.formItem} label={t('Investor\'s share, investment amount')} name={'shareToRepayment'}>
                    <span className={cls.value}>
                        {firstPaymentPercent! < 100 ? 100 - firstPaymentPercent! : ''}%, {investorCost}
                    </span>
            </Form.Item>

            <Divider className={cls.divider} />

            <Form.Item
                className={cls.formItem}
                label={t('The amount per month for which you are willing to rent the investor\'s share initially')}
                name={'amountToRent'}
                rules={[
                    {
                        required: true,
                        message: t('Please enter the data') || '',
                    },
                ]}
            >
                <InputNumber
                    bordered={false}
                    className={cls.input}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    onChange={_.debounce(() => setHasChanges(true), 100)}
                    pattern={'[0-9]*'}
                    onPressEnter={() => form.submit()}
                />
            </Form.Item>

            <Divider className={cls.divider} />

            <Form.Item
                className={cls.formItem}
                label={t('The amount for which you are ready to purchase the investor\'s share')}
                name={'amountToBuy'}
                rules={[
                    {
                        required: true,
                        message: t('Please enter the data') || '',
                    },
                ]}
            >
                <InputNumber
                    bordered={false}
                    className={cls.input}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    onChange={_.debounce(() => setHasChanges(true), 100)}
                    pattern={'[0-9]*'}
                    onPressEnter={() => form.submit()}
                />
            </Form.Item>

            <Divider className={cls.divider} />

            <Form.Item
                className={cls.formItem}
                label={t('How many sub-shares do you propose to divide the investor\'s share')}
                name={'numberOfSubShares'}
                dependencies={['firstPaymentPercent']}
                rules={[
                    {
                        required: true,
                        message: t('Please enter the data') || '',
                    },
                ]}
            >
                <InputNumber
                    bordered={false}
                    className={cls.input}
                    onChange={_.debounce((value) => {numberOfSubSharesHandler(value);}, 100)}
                    pattern={'[0-9]*'}
                    onPressEnter={() => form.submit()}
                />
            </Form.Item>

            <Divider className={cls.divider} />

            <Form.Item
                className={cls.formItem}
                label={t('You agree to redeem each of the investor\'s sub shares, every X months')}
                name={'paymentPeriod'}
                rules={[
                    {
                        required: true,
                        message: t('Please enter the data') || '',
                    },
                ]}
            >
                <InputNumber
                    bordered={false}
                    className={cls.input}
                    onChange={_.debounce((value) => {paymentPeriodHandler(value);}, 100)}
                    pattern={'[0-9]*'}
                    onPressEnter={() => form.submit()}
                />
            </Form.Item>

            <Divider className={cls.divider} />

            <Form.Item className={cls.formItem} label={t('Duration of the deal, months')}
                       // rules={[
                       //     () => ({
                       //         validator(_, value) {
                       //             const maxPeriod = initialData.maxPeriod ? initialData.maxPeriod : 360;
                       //             if (value && value <= maxPeriod) {
                       //                 return Promise.resolve();
                       //             }
                       //             return Promise.reject(new Error(t('Must be less then {{X}}', {X: maxPeriod})!));
                       //         },
                       //     }),
                       // ]}
                       // validateStatus={dealPeriod! > initialData.maxPeriod! ? 'warning' : ''}
                       // help={dealPeriod! > initialData.maxPeriod! ? t('Must be less then {{X}}', {X: initialData.maxPeriod!})! : ''}
            >
                    {dealPeriod &&
                        <>
                        <span className={cls.value}>
                            {`${dealPeriod}`}
                        </span>
                        {(initialData.maxPeriod && dealPeriod! > initialData.maxPeriod) && <span className={cls.warning}><WarningOutlined/> {t('Recomendent no more than {{X}}', {X: initialData.maxPeriod})}</span>}
                        </>
                    }

            </Form.Item>

            <Divider className={cls.divider} />

            <Form.Item className={cls.buttonWrap}>
                <Button
                    htmlType={'submit'}
                    size={'large'}
                    type={'primary'}
                    className={cls.button}
                    disabled={!isUpdated}
                    onClick={() => form.submit()}
                >
                    {t('calculate')}
                </Button>
            </Form.Item>

        </Form>
    );
}