import React from 'react';
import { IOfferFormValues } from 'features/Offer/model/types/form';
import { applySnapshot, types as t } from 'mobx-state-tree';

export const initialOfferState = {
    assetCost: 0,
    firstPayment: 0,
    firstPaymentPercent: 0,
    amountToRent: 0,
    amountToBuy: 0,
    numberOfSubShares: 0,
    paymentPeriod: 1,
    isHasChanges: false,
    isCalculated: false,
};

export const OfferStore = t
    .model({
        assetCost: t.number,
        firstPayment: t.number,
        firstPaymentPercent: t.number,
        amountToRent: t.number,
        amountToBuy: t.number,
        numberOfSubShares: t.number,
        paymentPeriod: t.number,
        isHasChanges: t.boolean,
        isCalculated: t.boolean,
    })
    .views(self => ({
        get calculatedValues() {

            interface DataType {
                key: React.Key | string;
                investorsSharesBeforePayment: number;
                clientSharesBeforePayment: number;
                rentOfInvestorsShare?: number;
                repurchaseOfInvestorsShare?: number;
                paymentSum: number;
                investorsSharesAfterPayment: number;
                clientSharesAfterPayment: number;
            }

            const data: DataType[] = [];

            // задаем данные для аванса
            const dealPeriod = self.paymentPeriod * self.numberOfSubShares
            const shareReduce = self.amountToBuy / self.numberOfSubShares
            const rentReduce = self.amountToRent / self.numberOfSubShares
            let shareReducePercent = self.firstPaymentPercent
            let rentOfInvestorsShare = 0

            let investorsSharesBeforePayment = 100
            let clientSharesBeforePayment = 0
            let investorsSharesAfterPayment = 100 - shareReducePercent
            let clientSharesAfterPayment = shareReducePercent

            // аванс
            data.push({
                key: 'upfront',
                investorsSharesBeforePayment: investorsSharesBeforePayment,
                clientSharesBeforePayment: clientSharesBeforePayment,
                rentOfInvestorsShare: rentOfInvestorsShare,
                repurchaseOfInvestorsShare: self.firstPayment,
                paymentSum: self.firstPayment,
                investorsSharesAfterPayment: investorsSharesAfterPayment,
                clientSharesAfterPayment: clientSharesAfterPayment,
            });

            // задаем данные перед итерацииями
            investorsSharesBeforePayment = investorsSharesAfterPayment
            clientSharesBeforePayment = clientSharesAfterPayment
            rentOfInvestorsShare = self.amountToRent
            shareReducePercent = investorsSharesBeforePayment / self.numberOfSubShares

            let rentSum = 0

            // итерируемся по месяцам
            for (let i = 1; i <= dealPeriod; i++) {

                let repurchaseOfInvestorsShare = 0

                investorsSharesBeforePayment = investorsSharesAfterPayment
                clientSharesBeforePayment = clientSharesAfterPayment

                // изменяем доли и добавляем выкуп в последнем платеже периода
                if (i % self.paymentPeriod === 0) {
                    investorsSharesAfterPayment -= shareReducePercent
                    clientSharesAfterPayment += shareReducePercent
                    repurchaseOfInvestorsShare = shareReduce
                }

                rentSum += rentOfInvestorsShare

                data.push({
                    key: i,
                    investorsSharesBeforePayment,
                    clientSharesBeforePayment,
                    rentOfInvestorsShare,
                    repurchaseOfInvestorsShare,
                    paymentSum: rentOfInvestorsShare + repurchaseOfInvestorsShare,
                    investorsSharesAfterPayment,
                    clientSharesAfterPayment,
                });

                // изменяем аренду для следующего платежа
                if (i % self.paymentPeriod === 0) {
                    rentOfInvestorsShare -= rentReduce
                }
            }

            const dealCost = rentSum + self.firstPayment + self.amountToBuy

            // добавляем вначае сумму по платежам
            data.unshift({
                key: 'sum',
                investorsSharesBeforePayment: 0,
                clientSharesBeforePayment: 0,
                rentOfInvestorsShare: rentSum,
                repurchaseOfInvestorsShare: self.firstPayment + self.amountToBuy,
                paymentSum: dealCost,
                investorsSharesAfterPayment: 0,
                clientSharesAfterPayment: 0,
            });

            const investPayment = self.assetCost - self.firstPayment;
            const investSellProfit = self.amountToBuy - (self.assetCost - self.firstPayment);
            const investRentProfit = rentSum;
            const investProfitPercent = (((investSellProfit + investRentProfit) / investPayment) * 100);

            return {
                dealPeriod: (self.paymentPeriod * self.numberOfSubShares),
                dealCost,
                investPayment,
                investProfitPercent,
                investSellProfit,
                investRentProfit,
                paymentTable: data,
            }
        },

    }))
    .actions((self) => ({
        clearState: () => {
            applySnapshot(self, initialOfferState);
        },
        setFormValues: (values: IOfferFormValues) => {
            self.assetCost = +values.assetCost!;
            self.firstPayment = +values.firstPayment!;
            self.firstPaymentPercent = +values.firstPaymentPercent!;
            self.amountToRent = +values.amountToRent!;
            self.amountToBuy = +values.amountToBuy!;
            self.numberOfSubShares = +values.numberOfSubShares!;
            self.paymentPeriod = +values.paymentPeriod!;
            self.isCalculated = true;
        },
        setCalculated(value: boolean) {
            self.isCalculated = value
        },
        setHasChanges(value: boolean) {
            self.isHasChanges = value
        },
    }));
