import {FC} from 'react';
import {provider, useInstance} from 'react-ioc';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {YMInitializer} from 'react-yandex-metrika';
import {ApolloClient, ApolloProvider} from '@apollo/client';
import ApolloClientDI from 'app/graphql/ApolloClientDI';
import StoreDI from 'app/store/StoreDI';
import {Metrika} from 'features/Metrika/Metrika';
import {observer} from 'mobx-react-lite';
import {Dialogues, EditOffer, Favorites, MyOffers} from 'pages/Account';
import {MyAssets} from 'pages/Account/MyOffers/MyAssets';
import {CreateAsset, EditAsset,ViewAsset} from 'pages/Asset';
import {CreateOfferOverAsset} from 'pages/Asset/CreateOfferOverAsset';
import {Calc} from 'pages/Calc';
import {Customer} from 'pages/Customer';
import {Feedback} from 'pages/Feedback/Feedback';
import { IndexRedirect, UserList} from 'pages/Index';
import {Buy} from 'pages/Index/Buy/Buy';
import {Invest} from 'pages/Index/Invest/Invest';
import NeedAuth from 'pages/NeedAuth/NeedAuth';
import NotFound from 'pages/NotFound/NotFound';
import {Offer} from 'pages/Offer/ui/Offer';
import {Breeding} from 'pages/Pres/Breeding';
import {Pres} from 'pages/Pres/Pres';
import {Timer} from 'pages/Timer';
import {
    ASSET,
    ASSET_CREATE,
    CALC,
    CREATE_OFFER_OVER_ASSET,
    CUSTOMER,
    DIALOGUES,
EDIT_ASSET,
    EDIT_OFFER,
    FAVORITES,
    FEEDBACK,
    INDEX,
    INDEX_BUY,
    INDEX_INVEST,
    MY_ASSETS,
    MY_OFFERS,
    OFFER,
    PRESENTATION,
    PRESENTATION_GLOBAL,
    ROOT,
    TIMER,
    USER_LIST} from 'shared/config/routes';
import ScrollToTop from 'shared/helpers/ScrollToTop';

import Layout from './Layout/Layout';
import {Store} from './store/Store';

const App: FC = () => {

    const store = useInstance(Store);
    const {hydrated, auth} = store;
    const {hasProfile} = auth;
    const apolloClient = useInstance(ApolloClient);

    if (!hydrated) {
        return null;
    }

    return (
        <ApolloProvider client={apolloClient}>
            <Router>
                <ScrollToTop/>
                <YMInitializer accounts={[94129781]}/>
                <Metrika/>
                <Routes>

                    <Route path={ROOT} element={<Layout/>}>
                        <Route path={INDEX} element={<IndexRedirect/>}/>
                        <Route path={INDEX_BUY} element={<Buy/>}/>
                        <Route path={INDEX_INVEST} element={<Invest/>}/>
                        <Route path={USER_LIST} element={<UserList/>}/>
                        <Route path={`${CALC}/:lang?`} element={<Calc/>}/>
                        <Route path={OFFER} element={<Offer/>}/>

                        <Route path={ASSET} element={<ViewAsset/>}/>
                        <Route path={ASSET_CREATE} element={<CreateAsset/>}/>
                        <Route path={CREATE_OFFER_OVER_ASSET} element={<CreateOfferOverAsset/>}/>

                        <Route path={`${CUSTOMER}/:lang?`} element={<Customer/>}/>

                        <Route path={TIMER} element={<Timer/>}/>
                        <Route path={FEEDBACK} element={<Feedback/>}/>
                        <Route path={PRESENTATION} element={<Breeding/>}/>
                        <Route path={PRESENTATION_GLOBAL} element={<Pres/>}/>

                        {hasProfile ?
                            <>
                                <Route path={`${MY_OFFERS}/:status?`} element={<MyOffers/>}/>
                                <Route path={`${MY_ASSETS}/:status?`} element={<MyAssets/>}/>
                                <Route path={EDIT_OFFER} element={<EditOffer/>}/>
                                <Route path={EDIT_ASSET} element={<EditAsset/>}/>
                                <Route path={FAVORITES} element={<Favorites/>}/>
                                <Route path={`${DIALOGUES}/:UID?`} element={<Dialogues/>}/>
                            </>
                            :
                            <>
                                <Route path={`${MY_OFFERS}/:status?`} element={<NeedAuth/>}/>
                                <Route path={`${MY_ASSETS}/:status?`} element={<NeedAuth/>}/>
                                <Route path={EDIT_OFFER} element={<NeedAuth/>}/>
                                <Route path={EDIT_ASSET} element={<NeedAuth/>}/>
                                <Route path={FAVORITES} element={<NeedAuth/>}/>
                                <Route path={`${DIALOGUES}/:UID?`} element={<NeedAuth/>}/>
                            </>
                        }

                    </Route>
                    <Route path={'*'} element={<NotFound/>}/>
                </Routes>
            </Router>
        </ApolloProvider>
    );
};

export default provider(StoreDI(), ApolloClientDI())(observer(App));
