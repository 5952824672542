import {applySnapshot,types as t} from 'mobx-state-tree';

export const initialFilterStore = {
    type: 'invest',
}

export const FilterStore = t
    .model({
        type: t.string,
    })
    .actions((self) => ({
        clearState: () => {
            applySnapshot(self, initialFilterStore);
        },
        setType: (type: 'invest' | 'buy') => {
            self.type = type;
        },
    }));
