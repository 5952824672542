import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {Collapse, Skeleton} from 'antd';
import {Store} from 'app/store/Store';
import {ICreateOfferFormValues, IOfferFormValues} from 'features/Offer/model/types/form';
import {observer} from 'mobx-react-lite';
import {FormCalc} from 'pages/Account/MyOffers/Forms/FormCalc';
import {FormOffer} from 'pages/Account/MyOffers/Forms/FormOffer';
import {FormResult} from 'pages/Account/MyOffers/Forms/FormResult';
import {offer, offerVariables} from 'shared/graphql/__generated__/offer';
import {updateOffer, updateOfferVariables} from 'shared/graphql/__generated__/updateOffer';
import {QUERY_OFFER} from 'shared/graphql/queryOffer';
import {UPDATE_OFFER} from 'shared/graphql/updateOffer';
import {useLayout} from 'shared/hooks/useTrainerLayout';
import {ModalSave} from 'widgets/Offer/ui/ModalSave';
import {PageTitle} from 'widgets/PageTitle';

import {ENUM_OFFER_TYPE, OfferInput} from '../../../__generated__/global-types';

import cls from '../../Calc/ui/Calc.module.scss';

const {Panel} = Collapse;

export const EditOffer = observer(() => {

    const {t} = useTranslation();
    const {UID} = useParams();

    const store = useInstance(Store);
    const {offer} = store;
    
    const [offerData, setOfferData] = useState<ICreateOfferFormValues>({
        type: ENUM_OFFER_TYPE.estate,
        country: '',
        region: '',
        countryUse: '',
        regionUse: '',
        currency: '',
        link: '',
    })

    const [calcData, setCalcData] = useState<IOfferFormValues>({
        assetCost: 0,
        firstPayment: 0,
        firstPaymentPercent: 0,
        amountToBuy: 0,
        amountToRent: 0,
        numberOfSubShares: 0,
        paymentPeriod: 0,
        isCalculated: true,
        isHasChanges: false,
    })

    const [modalSaveOpen, setModalSaveOpen] = useState<boolean>(false)

    const query = useQuery<offer, offerVariables>(QUERY_OFFER, {
        variables: {
            UID: UID || '',
        },
        onCompleted: (data) => {
            setOfferData({
                type: data.offer?.attributes?.type || ENUM_OFFER_TYPE.estate,
                country: data.offer?.attributes?.country?.data?.id || '',
                region: data.offer?.attributes?.region?.data?.id || null,
                currency: data.offer?.attributes?.currency?.data?.id || '',
                countryUse: data.offer?.attributes?.countryUse?.data?.id || null,
                regionUse: data.offer?.attributes?.regionUse?.data?.id || null,
                link: data.offer?.attributes?.link || null,
            })

            const calcValues:IOfferFormValues = {
                assetCost: data.offer?.attributes?.activeCost!,
                firstPayment: data.offer?.attributes?.activePayment!,
                firstPaymentPercent: Math.round((data.offer?.attributes?.activePayment!) / (data.offer?.attributes?.activeCost!) * 100),
                amountToRent: data.offer?.attributes?.rentCost!,
                amountToBuy: data.offer?.attributes?.partCost!,
                numberOfSubShares: data.offer?.attributes?.partsCount!,
                paymentPeriod: data.offer?.attributes?.partPaymentPeriod!,
                isCalculated: true,
                isHasChanges: false,
                assetCostDisabled: !!data.offer?.attributes?.asset?.data,
            }

            setCalcData(calcValues)
            offer.setFormValues(calcValues)
            offer.setHasChanges(false)
        }
    })

    const [update] = useMutation<updateOffer, updateOfferVariables>(UPDATE_OFFER)

    const {setLayout} = useLayout();
    useEffect(() => {
        setLayout({
            title: (<PageTitle title={t('Edit')}/>),
        });
    }, [setLayout, t]);

    const [active, setActive] = useState('');
    const toggleOffer = () => {
        setActive((prevState) => (prevState !== 'offer' ? 'offer' : ''));
    };
    const toggleCalc = () => {
        setActive((prevState) => (prevState !== 'form' ? 'form' : ''));
    };

    const toggleSecond = () => {
        setActive((prevState) => (prevState !== 'graph' ? 'graph' : ''));
    };

    if (query.loading) {
        return <Skeleton active/>
    }

    const onOfferUpdate = async (values: ICreateOfferFormValues) => {
        await update({
            variables: {
                UID: UID || '',
                data: values
            },
            onCompleted: () => {
                setModalSaveOpen(true)
                query.refetch()
            }
        })
    }

    const onCalcUpdate = (values: IOfferFormValues) => {
        setCalcData(values)
        toggleSecond()
    }

    const onResultUpdate = async () => {
        const data: OfferInput = {
            activeCost: offer.assetCost,
            activePayment: offer.firstPayment,
            rentCost: offer.amountToRent,
            partCost: offer.amountToBuy,
            partsCount: offer.numberOfSubShares,
            partPaymentPeriod: offer.paymentPeriod,
            investmentSum: offer.calculatedValues.investPayment,
            incomePercent: offer.calculatedValues.investProfitPercent,
            totalDuration: offer.calculatedValues.dealPeriod,
            calcData: offer.calculatedValues,
        }

        await update({
            variables: {
                UID: UID || '',
                data: data,
            },
            onCompleted: () => {
                setModalSaveOpen(true)
                query.refetch()
            }
        })

    }

    return (
        <div className={cls.Offer}>

            <ModalSave open={modalSaveOpen} onOk={() => setModalSaveOpen(false)}/>

            <Collapse bordered={false} className={cls.collapse} onChange={toggleOffer} activeKey={active}>
                <Panel key={'offer'} header={t('Asset')} className={cls.panel}>
                    <div className={cls.content}>
                        <FormOffer initialData={offerData} onCompleted={onOfferUpdate} disabled={!!query.data?.offer?.attributes?.asset?.data}/>
                    </div>
                </Panel>
            </Collapse>

            <Collapse bordered={false} className={cls.collapse} onChange={toggleCalc} activeKey={active}>
                <Panel key={'form'} header={t('Calculation parameters')} className={cls.panel}>
                    <div className={cls.content}>
                        <FormCalc initialData={calcData} onCompleted={onCalcUpdate}/>
                    </div>
                </Panel>
            </Collapse>

            <Collapse bordered={false} className={cls.collapse} onChange={toggleSecond} activeKey={active}>
                <Panel key={'graph'} header={t('Calculation results')} className={cls.panel}>
                    <div className={cls.content}>
                        <FormResult onSave={onResultUpdate}/>
                    </div>
                </Panel>
            </Collapse>

        </div>
    );
});
