import {useTranslation} from 'react-i18next';
import {Radio} from 'antd';

interface IModeElement {
    mode: 'map'|'list'
    onChange: (mode:'map'|'list') => void
}

export const ModeElement = ({mode, onChange}:IModeElement) => {

    const {t} = useTranslation();

    const optionsMode = [
        { label: t('Map'), value: 'map' },
        { label: t('List'), value: 'list' },
    ];

    const onModeChange = async (event:any) => {
        const newMode = event.target.value === 'map' ? 'map' : 'list'
        onChange(newMode)
    }


    return (
        <Radio.Group options={optionsMode} onChange={onModeChange} value={mode} optionType="button" buttonStyle="solid"/>
    )
}