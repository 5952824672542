import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import {Badge, Collapse} from 'antd';
import cn from 'classnames'
import _ from 'lodash';
import {SultanAvatar} from 'widgets/Avatar/SultanAvatar';

import cls from './Menu.module.scss';

export interface IMenuAvatar {
    firstName: string;
    lastName: string;
    color: string;
    photo?: string;
    UID: string;
}

export interface IDialogsItems {
    key: React.Key;
    UID: string;
    offerUID: string;
    userUID: string;
    unreadMessages: number;
}

export interface IMenuItems {
    key: React.Key;
    title?: string;
    count?: number;
    route?: string;
    avatar?: IMenuAvatar;
    dialogs?: IDialogsItems[];
}

export interface IGroupsType {
    key: React.Key;
    title?: string;
    items: IMenuItems[];
    unreadMessages?: number;
}

interface IMenuProps {
    groups: IGroupsType[];
    selected: React.Key;
}

export const Menu = (props: IMenuProps) => {

    const {groups, selected} = props

    const activeGroup = _.find(groups, group => _.find(group.items, item => item.dialogs ? _.find(item.dialogs, dialog => dialog.key === selected) : item.key === selected))
    // @ts-ignore
    const activeKey = activeGroup ? activeGroup.key : undefined
    const [active, setActive] = useState(activeKey)

    useEffect(() => {
        setActive(activeKey)
    }, [activeKey])

    return (
        <div className={cls.menu}>
            <Collapse accordion={true} activeKey={active} className={cls.collapse} onChange={(key) => setActive(key)}>
                {_.map(groups, ({key, title, items, unreadMessages}) => (
                            <Collapse.Panel key={key} header={title} className={cls.panel} extra={(unreadMessages && unreadMessages > 0) ? <Badge count={unreadMessages}/> : null}>
                                <div className={cls.content}>

                                    {items.length > 0 &&
                                        <div className={cls.items}>
                                            {_.map(items, ({key, title, count, route, avatar, dialogs}) => {

                                                    const selectedItem = key === selected || _.find(dialogs, item => item.UID === selected)

                                                    const itemRow = (
                                                        <div className={cn(cls.item, {[cls.selected]: selectedItem})}>
                                                            <div className={cls.left}>

                                                                {avatar &&
                                                                    <>
                                                                        <SultanAvatar color={avatar.color} firstName={avatar.firstName}
                                                                                      lastName={avatar.lastName}
                                                                                      photo={avatar.photo}/> {avatar.firstName} {avatar.lastName}
                                                                    </>
                                                                }
                                                                {title}

                                                            </div>
                                                            <div className={cls.right}>
                                                                {(count && count > 0) ? <Badge count={count}/> : null}
                                                            </div>
                                                        </div>
                                                    )


                                                    return (
                                                        <React.Fragment key={key}>
                                                            {route ?
                                                                <Link to={route}>
                                                                    {itemRow}
                                                                </Link>
                                                                : itemRow
                                                            }
                                                        </React.Fragment>
                                                    )
                                                }
                                            )}
                                        </div>
                                    }
                                </div>

                            </Collapse.Panel>
                        )
                )}

            </Collapse>
        </div>
    )
}