import {useInstance} from 'react-ioc';
import {useParams} from 'react-router-dom';
import {Store} from 'app/store/Store';
import {observer} from 'mobx-react-lite';
import {UserListAssets} from 'pages/Index/ui/IndexList/UserListAssets';
import {UserListOffers} from 'pages/Index/ui/IndexList/UserListOffers';

export const UserList = observer(() => {

    const { UID } = useParams();
    const { filters } = useInstance(Store);

    if (filters.type === 'invest') {
        return <UserListOffers UID={UID!}/>
    } else {
        return <UserListAssets UID={UID!}/>
    }
})