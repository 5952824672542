import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {
    MoreOutlined,
    RightOutlined
} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Badge,Button, Dropdown} from 'antd';
import cn from 'classnames'
import {goToDialog, goToOffer} from 'shared/config/routes';
import {IDialogsItems} from 'widgets/Menu/Menu';

import cls from './Menu.module.scss';


interface IMenuDialogProps {
    dialog: IDialogsItems;
    selected: boolean;
}

export const MenuDialog = ({dialog, selected}: IMenuDialogProps) => {

    const {UID, offerUID, unreadMessages} = dialog
    const {t} = useTranslation();

    const items: MenuProps['items'] = [
        {
            key: 'open',
            label: <Link to={goToOffer(offerUID)} className={cls.bold}>{t('Open')}</Link>,
            icon: <RightOutlined/>
        },
    ];

    return (
        <Badge count={unreadMessages}>
            <div className={cn(cls.dialog, {[cls.selected]: selected})}>
                <div className={cls.left}>
                    <Link to={goToDialog(UID)}>
                        {offerUID}
                    </Link>
                </div>
                <div className={cls.right}>
                    <Dropdown menu={{items}}>
                        <Button type={'text'} size={'small'} icon={<MoreOutlined/>}/>
                    </Dropdown>
                </div>
            </div>
        </Badge>
    )
}