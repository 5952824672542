import {gql} from '@apollo/client';

export const CREATE_ASSET = gql`
    mutation createAsset (
        $data: AssetInput!
    ) {
        createAsset (data: $data) {
            data {
                attributes {
                    UID
                }
            }
        }
    }
`