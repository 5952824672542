import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {Button, InputNumber, Select} from 'antd';
import {Store} from 'app/store/Store';
import classNames from 'classnames';
/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as Flags from 'country-flag-icons/react/3x2';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import {defaultOfferFilter, IOffersFilters, isChangedFilter} from 'pages/Index/Invest/filter';
import {ICurrencyOption, IRegionOption} from 'shared/types/select';
import {ITypeOfActive} from 'shared/types/types';
import {TypeOfActive} from 'shared/ui/TypeOfActive/TypeOfActive';
import {Filter} from 'widgets/Filters/Filter';
import RegionIcon from 'widgets/Icons/RegionIcon';

import cls from './OffersFilter.module.scss';

interface IOffersFilterProps {
    filters: IOffersFilters;
    onChange: (filters: IOffersFilters) => void;
}

export const OffersFilter = observer(({filters, onChange}: IOffersFilterProps) => {

    const {t} = useTranslation();
    const store = useInstance(Store);

    const [open, setOpen] = useState(false)
    const [filtersData, setFiltersData] = useState<IOffersFilters>(filters)

    // если извне поменяли страну и регион - переписываем данные внутри
    useEffect(() => {
        setFiltersData(filters)
        // eslint-disable-next-line
    }, [filters.country, filters.region])

    const countries = store.countries.allowedCountries(filtersData.currency);
    const regions = store.countries.allowedRegions(filtersData.country);
    const currencies = store.countries.allowedCurrencies(filtersData.country);

    const onChangeCountry = (value: string) => {
        const newFilters = {
            ...filtersData,
            country: value,
            region: null,
        }
        setFiltersData(newFilters)
    }

    const onChangeRegion = (value: string) => {
        const newFilters = {
            ...filtersData,
            region: value,
        }
        setFiltersData(newFilters)
    }

    const onChangeCurrency = (value: string | null) => {
        const newFilters = {
            ...filtersData,
            currency: value,
        }
        setFiltersData(newFilters)
    }
    const onAmountMinChange = (value: number | null) => {
        const newFilters = {
            ...filtersData,
            amountMin: value,
        }
        setFiltersData(newFilters)
    }
    const onAmountMaxChange = (value: number | null) => {
        const newFilters = {
            ...filtersData,
            amountMax: value,
        }
        setFiltersData(newFilters)
    }

    const onProfitPercentChange = (value: number | null) => {
        const newFilters = {
            ...filtersData,
            profitPercent: value,
        }
        setFiltersData(newFilters)
    }

    const onPeriodChange = (value: number | null) => {
        const newFilters = {
            ...filtersData,
            period: value,
        }
        setFiltersData(newFilters)
    }

    const onChangeType = ((value: ITypeOfActive) => {
        const newFilters = {
            ...filtersData,
            type: value
        }
        setFiltersData(newFilters)
    });

    const onOkHandler = async () => {
        setOpen(false)
        onChange(filtersData)
    }

    const onClearHandler = async () => {
        setOpen(false)
        setFiltersData(defaultOfferFilter)
        onChange(defaultOfferFilter)
    }

    return (
        <Filter open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} badge={isChangedFilter(filters)}>
            <div className={classNames(cls.AdsFilter)}>

                <div>
                <span className={cls.label}>{t('The amount I am ready to invest: from {{min}} to {{max}}', {
                    min: 0,
                    max: 100
                })}</span>
                    <div className={cls.inputSumGroup}>
                        <InputNumber
                            value={filtersData.amountMin}
                            onChange={onAmountMinChange}
                            pattern={'[0-9]*'}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            className={cls.inputSum}
                        />
                        <span className={cls.sumDivider}>-</span>
                        <InputNumber
                            pattern={'[0-9]*'}
                            value={filtersData.amountMax}
                            onChange={onAmountMaxChange}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            className={cls.inputSum}
                        />
                        <Select loading={store.countries.loading} onChange={value => onChangeCurrency(value)}
                                value={filtersData.currency}
                                className={cls.inputCurrency} popupMatchSelectWidth={false}>
                            <Select.Option key={'all'} value={null}>{t('All currencies')}</Select.Option>
                            {_.map(currencies, (item: ICurrencyOption) => (
                                <Select.Option key={item.name} value={item.name}>{item.name}</Select.Option>))}
                        </Select>
                    </div>
                </div>

                <div className={cls.inputPercentGroup}>
                    <div className={cls.inputPercent}>
                        <span className={cls.label}>{t('and want to earn not less than %')}</span>
                        <InputNumber
                            pattern={'[0-9]*'}
                            value={filtersData.profitPercent}
                            onChange={onProfitPercentChange}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            className={cls.input}
                        />
                    </div>
                    <div className={cls.inputPercent}>
                        <span className={cls.label}>{t('with the deal term up to, months')}</span>
                        <InputNumber
                            pattern={'[0-9]*'}
                            value={filtersData.period}
                            onChange={onPeriodChange}
                            className={cls.input}
                        />
                    </div>
                </div>

                <div>
                    <span className={cls.label}>{t('Country of investment')}</span>
                    <div className={cls.twoInputsWrapper}>
                        <Select loading={store.countries.loading} onChange={value => onChangeCountry(value)}
                                value={filtersData.country}
                                className={cls.input}>
                            <Select.Option key={'all'} value={null}>{t('All countries')}</Select.Option>
                            {_.map(countries, (item) => {
                                const Flag = Flags[item.code as keyof typeof Flags]
                                return (
                                    <Select.Option key={item.name} value={item.name}>
                                        <Flag className={cls.flag}/> {t(item.name)}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                        {regions &&
                            <Select loading={store.countries.loading} onChange={value => onChangeRegion(value)}
                                    value={filtersData.region}
                                    className={cls.selectRegion}>
                                <Select.Option key={'all'} value={null}>{t('All regions')}</Select.Option>
                                {_.map(regions, (item: IRegionOption) => (
                                    <Select.Option key={item.name} value={item.name}><RegionIcon
                                        url={item.logo}/> {t(item.name)}
                                    </Select.Option>))}
                            </Select>
                        }
                    </div>
                </div>

                <div>
                    <span className={cls.label}>{t('Asset type')}</span>
                    <TypeOfActive onChange={onChangeType} value={filtersData.type}/>
                </div>

                <div className={cls.submitLine}>
                    <Button onClick={onClearHandler}>{t('Clear')}</Button>
                    <Button type={'primary'} block onClick={onOkHandler}
                            disabled={filters === filtersData}
                    >{t('OK')}</Button>
                </div>

            </div>
        </Filter>
    );
})
