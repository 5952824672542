import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd';
import classNames from 'classnames';
import {ITypeOfActive} from 'shared/types/types';

import cls from './TypeOfActive.module.scss';

interface ITypeOfActiveProps {
    className?: string;
    onChange: (value: ITypeOfActive) => void;
    value: ITypeOfActive;
}

export const TypeOfActive = memo(function TypeOfActive(props: ITypeOfActiveProps) {
    const {onChange, value} = props;
    const {t} = useTranslation();


    const clickHandler = (name: keyof ITypeOfActive) => {
        const newValue = {
            ...value,
            [name]: !value[name],
        }
        onChange(newValue)

    };

    return (
        <div className={cls.icons}>
            <Button type={'text'} onClick={() => clickHandler('estate')}
                    className={classNames(cls.icon, {[cls.active]: value.estate})}
            >
                {t('Real estate')}
            </Button>
            <Button type={'text'} onClick={() => clickHandler('tech')}
                    className={classNames(cls.icon, {[cls.active]: value.tech})}
            >
                {t('Construction vehicles')}
            </Button>
            <Button type={'text'} onClick={() => clickHandler('car')}
                    className={classNames(cls.icon, {[cls.active]: value.car})}
            >
                {t('Transport')}
            </Button>
        </div>
    );
});