import react, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useLocation} from 'react-router';
import {useNavigate,useParams} from 'react-router-dom';
import {Button, Modal} from 'antd';
import {hasLang} from 'app/config/lang';
import {Store} from 'app/store/Store';
import classNames from 'classnames';
import {DE, ES,FR, GB, KZ, RU, TR} from 'country-flag-icons/react/3x2'
import useChangeLanguage from 'features/Language/useChangeLanguage';
import { observer } from 'mobx-react-lite';

import cls from 'src/app/Layout/Layout.module.scss';

interface ILanguageProviderProps {
    children?: react.ReactNode;
}

const LanguageProvider = (props: ILanguageProviderProps) => {

    const {children} = props

    const store = useInstance(Store);
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const {t, i18n} = useTranslation()

    const [modalOpen, setModalOpen] = useState(false)
    const [urlLangProcessed, setUrlLangProcessed] = useState(false)

    const changeLanguage = useChangeLanguage();

    const selectLanguageFromModal = async (lang: string) => {
        await changeLanguage(lang)
        setModalOpen(false)
    }

    useEffect( () => {
        // если в адресе есть язык и мы знаем этот язык
        if (params.lang && hasLang(params.lang)) {
            // устанавливаем язык из адреса
            changeLanguage(params.lang).then(() => {
                navigate(location.pathname.replace(`/${params.lang}`, ''))
            })
        // иначе оставляем язык из стора
        } else {
            setUrlLangProcessed(true)
            // если в сторе пусто, то просим его выбрать в модальнике
            if (store.lang.lang === '') {
                setModalOpen(true)
            }
        }
    }, [store, params, i18n, changeLanguage, location, navigate])

    if (!urlLangProcessed) {
        return null;
    }

    return (
        <>

            <Modal open={modalOpen} footer={false} className={cls.modal} closable={false} style={{zIndex: 10000}}>
                <div className={cls.lang}>
                    <Button className={cls.button} onClick={() => selectLanguageFromModal('tur')} type={'text'}>
                        <div><TR className={cls.flag}/></div>
                        <div>{t('Turkish')}</div>
                    </Button>
                    <Button className={cls.button} onClick={() => selectLanguageFromModal('eng')} type={'text'}>
                        <div><GB className={cls.flag}/></div>
                        <div>{t('English')}</div>
                    </Button>
                    <Button className={cls.button} onClick={() => selectLanguageFromModal('fra')} type={'text'}>
                        <div><FR className={classNames([cls.flag])}/></div>
                        <div>{t('French')}</div>
                    </Button>
                    <Button className={cls.button} onClick={() => selectLanguageFromModal('deu')} type={'text'}>
                        <div><DE className={classNames([cls.flag])}/></div>
                        <div>{t('German')}</div>
                    </Button>
                </div>
                <div className={cls.lang}>
                    <Button className={cls.button} onClick={() => selectLanguageFromModal('kaz')} type={'text'}>
                        <div><KZ className={classNames([cls.flag, cls.ru])}/></div>
                        <div>{t('Kazakh')}</div>
                    </Button>
                    <Button className={cls.button} onClick={() => selectLanguageFromModal('rus')} type={'text'}>
                        <div><RU className={classNames([cls.flag, cls.ru])}/></div>
                        <div>{t('Russian')}</div>
                    </Button>
                    <Button className={cls.button} onClick={() => selectLanguageFromModal('esp')} type={'text'}>
                        <div><ES className={classNames([cls.flag])}/></div>
                        <div>{t('Spanish')}</div>
                    </Button>
                </div>
            </Modal>

            {children}

        </>
    );
}

export default observer(LanguageProvider)