import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import {Button, Form,Modal, Select, Space} from 'antd';
/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as Flags from 'country-flag-icons/react/3x2';
import _ from 'lodash';
import {countries, countriesVariables} from 'shared/graphql/__generated__/countries';
import {QUERY_COUNTRIES} from 'shared/graphql/queryCountries';

import cls from './modal.module.scss';

interface IModalProps {
    open: boolean;
    onOk: () => void;
    onCancel: () => void;
}


type IRegionsOptions = {
    id: string,
    name: string,
};

type ICountriesOptions = {
    id: string,
    name: string,
    code: string,
    regions: IRegionsOptions[],
}[];
export const ModalRegionUse = ({open, onOk, onCancel}: IModalProps) => {
    
    const { t } = useTranslation()

    const [countries, setCountries] = useState<ICountriesOptions>([])
    const query = useQuery<countries, countriesVariables>(QUERY_COUNTRIES, {
        variables: {
            filters: {enabled: {eq: true}},
            pagination: {limit: 100},
            regionsFilters: {enabled: {eq: true}},
            regionsPagination: {limit: 100},
            currenciesPagination: {limit: 100},
        },
        onCompleted: (data) => {
            let countriesData: ICountriesOptions = []
            _.map(data.countries?.data, item => {
                countriesData.push({
                    id: item.id || '',
                    name: item.attributes?.name || '',
                    code: item.attributes?.code2 || '',
                    regions: _.map(item.attributes?.regions?.data, reg => ({
                        id: reg.id || '',
                        name: reg.attributes?.name || ''
                    })),
                })
            })
            setCountries(countriesData)
        },
    })

    const [countryUse, setCountryUse] = useState<string>('')

    const [form] = Form.useForm();

    const onFinish = () => {
        onOk()
    }

    const dataUse = _.find(countries, {id: countryUse})
    const regionsUse = dataUse && (dataUse.regions.length ? dataUse.regions : undefined)

    return (
        <Modal title={undefined} open={open} footer={[]} onCancel={onCancel}>
            <Form layout={'vertical'} onFinish={onFinish} form={form}>

                        <Form.Item name={'countryUse'} label={t('Country of predominant use of the asset')}
                                   rules={[
                                       {
                                           required: true,
                                           message: t('Please enter the data') || '',
                                       },
                                   ]}>
                            <Select onChange={value => setCountryUse(value)} loading={query.loading}>
                                {countries.map(item => {
                                    const Flag = Flags[item.code as keyof typeof Flags]
                                    return (
                                        <Select.Option key={item.id} value={item.id}>
                                            <Flag className={cls.flag}/> {t(item.name)}
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        {regionsUse &&
                            <Form.Item name={'regionUse'} label={t('Region of predominant use of the asset')}
                                       rules={[
                                           {
                                               required: true,
                                               message: t('Please enter the data') || '',
                                           },
                                       ]}>
                                <Select loading={query.loading}>
                                    {_.map(regionsUse, (item) => (
                                        <Select.Option key={item.id} value={item.id}>{t(item.name)}</Select.Option>))}
                                </Select>
                            </Form.Item>
                        }

                <Form.Item>
                    <Space>
                        <Button type={'primary'} htmlType={'submit'}>
                            {t('Publish')}
                        </Button>
                    </Space>
                </Form.Item>

            </Form>
        </Modal>
    )
}