import {OfferFiltersInput} from '__generated__/global-types';
import {toNumber} from 'lodash';
import {ITypeOfActive, typeToQuery, typeToUrl, urlToType} from 'shared/types/types';

export interface IOffersFilters {
    amountMin: number | null
    amountMax: number | null
    currency: string | null
    profitPercent: number | null;
    period: number | null;
    country: string | null
    region: string | null
    type: ITypeOfActive
}

export const defaultOfferFilter:IOffersFilters = {
    amountMin: null,
    amountMax: null,
    currency: null,
    profitPercent: null,
    period: null,
    country: null,
    region: null,
    type: {estate: true, tech: true, car: true},
}

export const isChangedFilter = (filter: IOffersFilters) => {
    if (filter.amountMin !== defaultOfferFilter.amountMin) return true
    if (filter.amountMax !== defaultOfferFilter.amountMax) return true
    if (filter.currency !== defaultOfferFilter.currency) return true
    if (filter.profitPercent !== defaultOfferFilter.profitPercent) return true
    if (filter.period !== defaultOfferFilter.period) return true
    if (filter.country !== defaultOfferFilter.country) return true
    if (filter.region !== defaultOfferFilter.region) return true
    if (filter.type !== defaultOfferFilter.type) return true
}

export const filtersToSearchParams = (filter: IOffersFilters) => ({
        ...(filter.amountMin && {amountMin: filter.amountMin.toString()}),
        ...(filter.amountMax && {amountMax: filter.amountMax.toString()}),
        ...(filter.currency && {currency: filter.currency}),
        ...(filter.profitPercent && {profitPercent: filter.profitPercent.toString()}),
        ...(filter.period && {period: filter.period.toString()}),
        ...(filter.country && {country: filter.country}),
        ...(filter.region && {region: filter.region}),
        ...(filter.type !== defaultOfferFilter.type && {type: typeToUrl(filter.type)}),
    })

export const searchParamsToFilters = (params: URLSearchParams) => ({
        amountMin: params.get('amountMin') ? toNumber(params.get('amountMin')) : null,
        amountMax: params.get('amountMax') ? toNumber(params.get('amountMax')) : null,
        currency: params.get('currency') ? params.get('currency') : null,
        profitPercent: params.get('profitPercent') ? toNumber(params.get('profitPercent')) : null,
        period: params.get('period') ? toNumber(params.get('period')) : null,
        country: params.get('country') ? params.get('country') : null,
        region: params.get('region') ? params.get('region') : null,
        type: params.get('type') ? urlToType(params.get('type')!) : defaultOfferFilter.type,
    }) as IOffersFilters

export const filtersToQuery = (filter: IOffersFilters) => ({
    type: {in: typeToQuery(filter.type)},
    ...(filter.amountMin && {investmentSum: {gte: filter.amountMin}}),
    ...(filter.amountMax && {investmentSum: {lte: filter.amountMax}}),
    ...((filter.amountMin && filter.amountMax) && {investmentSum: {between: [filter.amountMin, filter.amountMax]}}),
    ...(filter.currency && {currency: {name: {eq: filter.currency}}}),
    ...(filter.profitPercent && {incomePercent: {gte: filter.profitPercent}}),
    ...(filter.period && {totalDuration: {lte: filter.period}}),
}) as OfferFiltersInput

