import React from 'react';
import {useInstance} from 'react-ioc';
import {useQuery} from '@apollo/client';
import {Store} from 'app/store/Store';
import {ModalAnket} from 'features/Auth/ModalAnket';
import {ModalCode} from 'features/Auth/ModalCode';
import {ModalEmail} from 'features/Auth/ModalEmail';
import useChangeLanguage from 'features/Language/useChangeLanguage';
import {observer} from 'mobx-react-lite';
import {me} from 'shared/graphql/__generated__/me';
import { ME_QUERY } from 'shared/graphql/queryMe';

export const Auth = observer(() => {

    const {auth} = useInstance(Store);

    const changeLanguage = useChangeLanguage();

    useQuery<me>(ME_QUERY, {
        skip: !auth.hasProfile,
        onCompleted: (data) => {
            if (data.me?.firstName && data.me?.lastName && data.me?.color && data.me?.email) {
                auth.setData({
                    firstName: data.me?.firstName || '',
                    lastName: data.me?.lastName || '',
                    color: data.me?.color || '',
                    photo: data.me?.photo || '',
                    email: data.me?.email || '',
                })
                auth.setFavoritesOffers(data.me?.favoritesOffers || [])
                auth.setFavoritesAssets(data.me?.favoritesAssets || [])
                changeLanguage(data.me?.language).then()

            }
        }
    })

    return (
        <>
            <ModalEmail/>
            <ModalCode/>
            <ModalAnket/>
        </>
    )
});