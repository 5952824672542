import {useTranslation} from 'react-i18next';
import {ISlide} from 'pages/Pres/Pres';

import cls from './Pres.module.scss';

export const Slide7 = ({platform}:ISlide) => {

    const {t} = useTranslation();

    return (
        <div className={cls.slide}>
            <div className={cls.headline}>
                {t('Partner offer from {{platform}}', {platform: platform})}
            </div>
            <div className={cls.content}>
                <div className={cls.slide3items}>

                    <div className={cls.item}>
                        <div className={cls.title}>{'1'}</div>
                        <div>
                            {t('platform functionality - with customized interface and on original domain')}
                        </div>
                    </div>

                    <div className={cls.item}>
                        <div className={cls.title}>{'2'}</div>
                        <div>
                            {t('original name for the "+1 Tool" corresponding with your concept')}
                        </div>
                    </div>

                    <div className={cls.item}>
                        <div className={cls.title}>{'3'}</div>
                        <div>
                            {t('access to API services to create ergonomic widgets on the assets offering websites')}
                        </div>
                    </div>

                    <div className={cls.item}>
                        <div className={cls.title}>{'4'}</div>
                        <div>
                            {t('all the analytics partners need to develop their business cases')}
                        </div>
                    </div>

                    <div className={cls.item}>
                        <div className={cls.title}>{'5'}</div>
                        <div>
                            {t('low cost of additional functionality according to partners\' needs')}
                        </div>
                    </div>

                    <div className={cls.item}>
                        <div className={cls.title}>{'6'}</div>
                        <div>
                            {t('high-quality service and consulting support')}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}