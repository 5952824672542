import React, {useEffect,useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {Button, Form, Input} from 'antd';
import {Store} from 'app/store/Store';
import {observer} from 'mobx-react-lite';
import {INDEX} from 'shared/config/routes';
import {feedback, feedbackVariables} from 'shared/graphql/__generated__/feedback';
import {MUTATION_FEEDBACK} from 'shared/graphql/feedback';
import {emailRegex} from 'shared/helpers/regex';
import {useLayout} from 'shared/hooks/useTrainerLayout';
import {ModalSuccess} from 'widgets/Feedback/ModalSuccess';
import {PageTitle} from 'widgets/PageTitle';

import cls from './Feedback.module.scss';

interface IFormData {
    name: string;
    email: string;
    subject: string;
    message: string;
}


export const Feedback = observer(() => {

    const {auth} = useInstance(Store);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {setLayout} = useLayout();
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
    const [feedback, feedbackData] = useMutation<feedback,feedbackVariables>(MUTATION_FEEDBACK)

    useEffect(() => {
        setLayout({
            title: (
                <PageTitle
                    title={t('Feedback')}
                />
            ),
        });
    }, [setLayout, t]);


    const initData = {
        name: auth.data?.firstName,
        email: auth.data?.email,
    }

    const onFinish = async (values:IFormData) => {
            await feedback({
                variables: {...values, subject: ''},
                onCompleted: () => {
                    setIsSuccessOpen(true)
                }
            })
    }
    
    const onOk = () => {
        setIsSuccessOpen(false)
        navigate(INDEX)
    }


    return (
        <div className={cls.wrapper}>

            <Form layout={'vertical'} onFinish={onFinish} validateTrigger={'onSubmit'} initialValues={initData}>

                <Form.Item label={t('Name')} name={'name'}
                           rules={[
                               {required: true, message: `${t('Please enter the data')}`},
                           ]}>
                    <Input/>
                </Form.Item>

                <Form.Item label={t('Email')} name={'email'}
                           rules={[
                               {required: true, message: `${t('Please enter the data')}`},
                               {pattern: emailRegex, message: `${t('Please enter correct email')}`},
                           ]}>
                    <Input placeholder="example@site.com"/>
                </Form.Item>

                <Form.Item label={t('Message')} name={'message'}
                           rules={[
                               {required: true, message: `${t('Please enter the data')}`},
                           ]}>
                    <Input.TextArea autoSize={{minRows: 2, maxRows: 6}}/>
                </Form.Item>
                
                <Form.Item>
                    <Button type={'primary'} htmlType={'submit'} loading={feedbackData.loading}>
                        {t('Send')}
                    </Button>
                </Form.Item>

            </Form>
            
            <ModalSuccess open={isSuccessOpen} onOk={onOk} platform={'Global Halal Market'}/>

        </div>
    );
});