
import img from './pattern.svg'

import cls from './Agreement.module.scss'

interface IAgreementFooterProps {
    page: number,
    total: number
    UID: string,
}

export const AgreementFooter = ({page, total, UID}:IAgreementFooterProps) => (
        <div className={cls.footer} style={{backgroundImage: `url(${img})`}}>
            <div className={cls.pageEn}>{`Page ${page} of ${total}`}</div>
            <div className={cls.UID}>{UID}</div>
            <div className={cls.pageRu}>{`Страница ${page} из ${total}`}</div>
        </div>
    )