import {memo} from 'react';
import classNames from 'classnames';
import EmptyOutlined from 'widgets/Icons/EmptyOutlined';

import cls from './OfferCard.module.scss';

interface IEmptyCardProps {
    maxInRow?: 3 | 4
}

export const EmptyCard = memo(function OfferCard({maxInRow}: IEmptyCardProps) {

    return (
        <div className={classNames([
            cls.emptyCard,
            maxInRow && maxInRow === 4 && cls.row4,
        ])}>
            <EmptyOutlined className={cls.emptyIcon}/>
        </div>
    );
});