import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {Collapse, Skeleton} from 'antd';
import {Store} from 'app/store/Store';
import {ICreateOfferFormValues, IOfferFormValues} from 'features/Offer/model/types/form';
import {createOffer, createOfferVariables} from 'features/Offer/ui/graph/__generated__/createOffer';
import {observer} from 'mobx-react-lite';
import {FormCalc} from 'pages/Account/MyOffers/Forms/FormCalc';
import {FormOffer} from 'pages/Account/MyOffers/Forms/FormOffer';
import {FormResultOverAsset} from 'pages/Account/MyOffers/Forms/FormResultOverAsset';
import { myOffers} from 'shared/config/routes';
import {asset, assetVariables} from 'shared/graphql/__generated__/asset';
import {CREATE_OFFER} from 'shared/graphql/createOffer';
import {QUERY_ASSET} from 'shared/graphql/queryAsset';
import {useLayout} from 'shared/hooks/useTrainerLayout';
import {ModalDraft} from 'widgets/Offer/ui/ModalDraft';
import {ModalRegionUse} from 'widgets/Offer/ui/ModalRegionUse';
import {ModalSendSeller} from 'widgets/Offer/ui/ModalSendSeller';
import {PageTitle} from 'widgets/PageTitle';

import {ENUM_COMPONENTASSETASSET_TYPE, ENUM_OFFER_STATUS, ENUM_OFFER_TYPE} from '../../__generated__/global-types';

import cls from './style.module.scss'

const {Panel} = Collapse;


export const CreateOfferOverAsset = observer(() => {

    const {setLayout} = useLayout();
    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const store = useInstance(Store);
    const {auth, offer} = store;

    const [offerData, setOfferData] = useState<ICreateOfferFormValues>({
        type: ENUM_OFFER_TYPE.estate,
        country: '',
        region: '',
        countryUse: '',
        regionUse: '',
        currency: '',
        link: '',
    })

    const [calcData, setCalcData] = useState<IOfferFormValues>({
        assetCost: 0,
        firstPayment: 0,
        firstPaymentPercent: 0,
        amountToBuy: 0,
        amountToRent: 0,
        numberOfSubShares: 0,
        paymentPeriod: 0,
        isCalculated: true,
        isHasChanges: false,
    })

    const asset = useQuery<asset, assetVariables>(QUERY_ASSET, {
        variables: {
            UID: id!,
        },
        onCompleted: (data) => {

            let type = ENUM_OFFER_TYPE.estate
            if (data.asset?.attributes?.asset.type === ENUM_COMPONENTASSETASSET_TYPE.estate) { type = ENUM_OFFER_TYPE.estate }
            if (data.asset?.attributes?.asset.type === ENUM_COMPONENTASSETASSET_TYPE.tech) { type = ENUM_OFFER_TYPE.tech }
            if (data.asset?.attributes?.asset.type === ENUM_COMPONENTASSETASSET_TYPE.car) { type = ENUM_OFFER_TYPE.car }

            setOfferData({
                type: type,
                country: data.asset?.attributes?.asset.country?.data?.id!,
                region: data.asset?.attributes?.asset.region?.data?.id!,
                currency: data.asset?.attributes?.asset.currency?.data?.id!,
                countryUse: null,
                regionUse: null,
                link: data.asset?.attributes?.asset.link!,
            })
            setCalcData({
                assetCost: data.asset?.attributes?.asset.cost!,
                assetCostDisabled: true,
                maxPeriod: data.asset?.attributes?.maxPeriod!,
                paymentPeriod: 1,
            })
            offer.setHasChanges(false)
        }
    })

    const [active, setActive] = useState('form');
    const toggleOffer = () => {
        setActive((prevState) => (prevState !== 'offer' ? 'offer' : ''));
    };
    const toggleCalc = () => {
        setActive((prevState) => (prevState !== 'form' ? 'form' : ''));
    };
    const toggleSecond = () => {
        setActive((prevState) => (prevState !== 'graph' ? 'graph' : ''));
    };

    useEffect(() => {
        setLayout({
            title: (
                <PageTitle
                    title={t('Submit the offer')}
                />
            ),
        });
    }, [setLayout, t]);

    const [isSuccessOpen, setIsSuccesssOpen] = useState(false);
    const [isDraftOpen, setIsDraftOpen] = useState(false);
    const [regionUseOpen, setRegionUseOpen] = useState(false);

    const [createOffer, {loading}] = useMutation<createOffer, createOfferVariables>(CREATE_OFFER);

    const onOkHandler = () => {
        if (isSuccessOpen) {
            setIsSuccesssOpen(false);
            navigate(myOffers('published'));
        }
        if (isDraftOpen) {
            setIsDraftOpen(false)
            navigate(myOffers('drafts'))
        }
    }

    const onCalcUpdate = (values: IOfferFormValues) => {
        setCalcData({
            ...values,
            assetCostDisabled: true,
        })
        toggleSecond()
    }

    const onCheckRegionUse = async () => {

        if (offerData.type !== ENUM_OFFER_TYPE.estate) {
            setRegionUseOpen(true)
        } else {
            await onComplete(ENUM_OFFER_STATUS.PUBLIC_2)
        }

    }

    const onRegionUseOk = async () => {
        setRegionUseOpen(false)
        await onComplete(ENUM_OFFER_STATUS.PUBLIC_2)
    }

    const onComplete = async (status: ENUM_OFFER_STATUS) => {
        if (auth.hasProfile) {
            const data = {
                type: offerData.type,
                country: offerData.country,
                region: offerData.region,
                countryUse: offerData.countryUse,
                regionUse: offerData.regionUse,
                currency: offerData.currency,
                link: offerData.link,
                status: status,

                asset: asset.data?.asset?.id!,

                activeCost: offer.assetCost,
                activePayment: offer.firstPayment,
                rentCost: offer.amountToRent,
                partCost: offer.amountToBuy,
                partsCount: offer.numberOfSubShares,
                partPaymentPeriod: offer.paymentPeriod,
                investmentSum: offer.calculatedValues.investPayment,
                incomePercent: offer.calculatedValues.investProfitPercent,
                totalDuration: offer.calculatedValues.dealPeriod,
                calcData: offer.calculatedValues,
            }

            await createOffer({
                variables: {
                    data: data
                },
                onCompleted: () => {
                    if (status === ENUM_OFFER_STATUS.DRAFT_1) {
                        setIsDraftOpen(true)
                    }
                    if (status === ENUM_OFFER_STATUS.PUBLIC_2) {
                        setIsSuccesssOpen(true)
                    }
                },
            })
        } else {
            auth.openAuth()
        }
    }

    if (asset.loading) {
        return <Skeleton active/>
    }

    const showButton = auth.hasProfile
        ?
        auth.id !== asset.data?.asset?.attributes?.author?.data?.id!
        :
        true;

    return (
        <div className={cls.wrapper}>
            <Collapse bordered={false} className={cls.collapse} onChange={toggleOffer} activeKey={active}>
                <Panel key={'offer'} header={t('Asset')} className={cls.panel}>
                    <div className={cls.content}>
                        <FormOffer initialData={offerData} onCompleted={() => {}} disabled={true}/>
                    </div>
                </Panel>
            </Collapse>

            <Collapse bordered={false} className={cls.collapse} onChange={toggleCalc} activeKey={active}>
                <Panel key={'form'} header={t('Calculation parameters')} className={cls.panel}>
                    <div className={cls.content}>
                        <FormCalc initialData={calcData} onCompleted={onCalcUpdate}/>
                    </div>
                </Panel>
            </Collapse>

            <Collapse bordered={false} className={cls.collapse} onChange={toggleSecond} activeKey={active}>
                <Panel key={'graph'} header={t('Calculation results')} className={cls.panel}>
                    <div className={cls.content}>
                        <FormResultOverAsset
                            maxPeriod={asset.data?.asset?.attributes?.maxPeriod!}
                            minProfit={asset.data?.asset?.attributes?.minProfit!}
                            onDraft={() => onComplete(ENUM_OFFER_STATUS.DRAFT_1)}
                            onPublish={onCheckRegionUse}
                            loading={loading}
                            disabled={!showButton}
                        />
                    </div>
                </Panel>
            </Collapse>

            <ModalSendSeller open={isSuccessOpen} onOk={onOkHandler}/>
            <ModalDraft open={isDraftOpen} onOk={onOkHandler}/>
            <ModalRegionUse open={regionUseOpen} onOk={onRegionUseOk} onCancel={() => setRegionUseOpen(false)}/>
        </div>
    )
})