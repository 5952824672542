import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {Link, useParams} from 'react-router-dom';
import {CarOutlined, HomeOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import { Divider, Popover, Skeleton} from 'antd';
import {Store} from 'app/store/Store';
import currency from 'currency.js';
import {PaymentTableProps} from 'features/Offer/ui/table/PaymentTableProps';
import {observer} from 'mobx-react-lite';
import {OfferDialog} from 'pages/Offer/ui/OfferDialog';
import {userList} from 'shared/config/routes';
import {offer, offerVariables} from 'shared/graphql/__generated__/offer';
import {offers, offersVariables} from 'shared/graphql/__generated__/offers';
import {QUERY_OFFER} from 'shared/graphql/queryOffer';
import {QUERY_OFFERS} from 'shared/graphql/queryOffers';
import {isValidUrl} from 'shared/helpers/isValidURL';
import {OfferCalcRentIncome} from 'shared/helpers/OfferCalcRentIncome';
import {OfferCalcSellIncome} from 'shared/helpers/OfferCalcSellIncome';
import {useLayout} from 'shared/hooks/useTrainerLayout';
import {SultanAvatar} from 'widgets/Avatar/SultanAvatar';
import {CalcData} from 'widgets/Calc';
import ExcavatorOutlined from 'widgets/Icons/ExcavatorOutlined';
import {PageTitle} from 'widgets/PageTitle';

import cls from './Offer.module.scss';

const currencyFormat = (value: number | string) =>
    currency(value, {
        symbol: '',
        separator: ' ',
        precision: 0,
    }).format();

export const Offer = observer(() => {

    const {id} = useParams();
    const {auth} = useInstance(Store);
    const {t} = useTranslation();

    const {setLayout} = useLayout();

    useEffect(() => {
        setLayout({
            title: (
                <PageTitle
                    title={
                        <div className={cls.pageTitle}>
                            <span className={cls.pageTitle__offer}>{t('Offer')}</span>
                            <span className={cls.pageTitle__code}>{id}</span>
                        </div>
                    }
                />
            ),
        });
    }, [setLayout, t, id]);


    // const [isLoading, setIsLoading] = useState(true);
    const [isTableOpen, setIsTableOpen] = useState(false);

    const offer = useQuery<offer, offerVariables>(QUERY_OFFER, {
        variables: {
            UID: id || '',
        },
    })

    const offers = useQuery<offers, offersVariables>(QUERY_OFFERS, {
        variables: {
            filters: {
                author: {UID: {eq: offer.data?.offer?.attributes?.author?.data?.attributes?.UID}},
                status: {in: ['PUBLIC_2']},
            },
            pagination: {
                limit: 1000,
            }
        },
    })

    const openTable = () => {
        setIsTableOpen(true);
    };

    const closeTable = () => {
        setIsTableOpen(false);
    };

    if (offer.loading) {
        return <Skeleton active/>;
    }

    // prettier-ignore
    const clientsShare = offer.data?.offer?.attributes?.activePayment! / offer.data?.offer?.attributes?.activeCost! * 100

    const investorsRentIncome = OfferCalcRentIncome({
        rentCost: +offer.data?.offer?.attributes?.rentCost!,
        partsCount: +offer.data?.offer?.attributes?.partsCount!,
        partPaymentPeriod: +offer.data?.offer?.attributes?.partPaymentPeriod!
    })

    const investorsSellIncome = OfferCalcSellIncome({
        activeCost: +offer.data?.offer?.attributes?.activeCost!,
        partCost: +offer.data?.offer?.attributes?.partCost!,
        activePayment: +offer.data?.offer?.attributes?.activePayment!,
    })

    let type
    switch (offer.data?.offer?.attributes?.type) {
        case 'estate':
            type = <span><HomeOutlined className={cls.icon}/> {t('Real estate')}</span>;
            break;
        case 'tech':
            type = <span><ExcavatorOutlined className={cls.icon}/> {t('Construction vehicles')}</span>;
            break;
        case 'car':
            type = <span><CarOutlined className={cls.icon}/> {t('Transport')}</span>;
            break;
    }

    const authorOffersCount = offers.data?.offers?.data.length
    
    return (
        <div className={cls.Offer}>
            <div className={cls.dataGroup}>
                <div className={cls.wrap}>
                    <CalcData
                        name={t('Asset type')}
                        value={type}
                    />
                    <Divider className={cls.divider}/>
                    <CalcData
                        name={t('Country of the asset purchasing')}
                        value={t(offer.data?.offer?.attributes?.country?.data?.attributes?.name || '')}
                    />
                    <Divider className={cls.divider}/>
                    <CalcData
                        name={t('Asset price')}
                        value={currencyFormat(offer.data?.offer?.attributes?.activeCost!)}
                    />
                    <Divider className={cls.divider}/>
                    <CalcData
                        name={t('Transaction currency')}
                        value={offer.data?.offer?.attributes?.currency?.data?.attributes?.name}
                    />
                    <Divider className={cls.divider}/>
                    <CalcData
                        name={t('Advanced payment')}
                        value={`${currencyFormat(clientsShare)} %`}
                    />
                    {isValidUrl(offer.data?.offer?.attributes?.link!) &&
                        <>
                            <Divider className={cls.divider}/>
                            <CalcData
                                name={t('See the sale announcement')}
                                value={
                                    <Popover
                                        content={`${offer.data?.offer?.attributes?.link}`}
                                    >
                                        <a className={cls.getTabel} href={offer.data?.offer?.attributes?.link!}
                                           target={'_blank'} rel={'noreferrer'}>{t('Review')}</a>
                                    </Popover>
                                }
                            />
                        </>
                    }

                </div>
            </div>

            <div className={cls.dataGroup}>
                <div className={cls.wrap}>
                    <CalcData name={t('Amount of investment')}
                              value={currencyFormat(offer.data?.offer?.attributes?.investmentSum!)}/>
                    <Divider className={cls.divider}/>
                    <CalcData name={t('Duration of the deal, months')}
                              value={offer.data?.offer?.attributes?.totalDuration}/>
                    <Divider className={cls.divider}/>
                    <CalcData
                        name={t('Payment frequency')}
                        value={
                            <span onClick={openTable} className={cls.link}>
                                {t('Review')}
                            </span>
                        }
                    />
                    <Divider className={cls.divider}/>
                    <CalcData name={t('Revenue received from the purchase and sale')}
                              value={currencyFormat(investorsSellIncome)}/>
                    <Divider className={cls.divider}/>
                    <CalcData
                        name={t('Revenue received from the rent')}
                        value={currencyFormat(investorsRentIncome)}
                    />
                    <Divider className={cls.divider}/>
                    <CalcData
                        name={''}
                        value={currencyFormat(
                            investorsSellIncome + investorsRentIncome,
                        )}
                    />
                </div>
            </div>

            <div className={cls.dataGroup}>
                <div className={cls.wrap}>
                    <div className={cls.author}>
                        <SultanAvatar
                            size={40}
                            color={offer.data?.offer?.attributes?.author?.data?.attributes?.color || '#F0F0F0'}
                            firstName={offer.data?.offer?.attributes?.author?.data?.attributes?.firstName || ''}
                            lastName={offer.data?.offer?.attributes?.author?.data?.attributes?.lastName || ''}
                            photo={offer.data?.offer?.attributes?.author?.data?.attributes?.photo || undefined}
                        />
                        <div>
                            <div
                                className={cls.name}>{offer.data?.offer?.attributes?.author?.data?.attributes?.firstName} {offer.data?.offer?.attributes?.author?.data?.attributes?.lastName}</div>
                            <div className={cls.offers}><Link
                                to={userList(offer.data?.offer?.attributes?.author?.data?.attributes?.UID!)}
                                className={cls.link}>{t('{{x}} offers', {x: authorOffersCount})}</Link></div>
                        </div>
                    </div>
                    <div className={cls.dialog}>
                        <OfferDialog offerUID={id!} isClient={offer.data?.offer?.attributes?.author?.data?.id === auth.id}/>
                    </div>
                </div>
            </div>

            <PaymentTableProps isOpen={isTableOpen} data={offer.data?.offer?.attributes?.calcData?.paymentTable || []}
                               onCancel={closeTable}/>
        </div>
    );
});