import {Watermark} from 'antd';
import {DataType} from 'features/Offer/ui/table/PaymentTable';
import _ from 'lodash';
import {IHeader,IPage, IWatermark} from 'pages/Agreement/Agreement';
import {AgreementFooter} from 'pages/Agreement/AgreementFooter';
import {AgreementHeader} from 'pages/Agreement/AgreementHeader';

import {AgreementStamp,IStamp} from '../AgreementStamp'

import cls from '../Agreement.module.scss'

interface ITablePageProps {

    currency: string
    data: DataType[],
    stamp?: IStamp,
    watermark: IWatermark,
    page: IPage,
    header: IHeader,
}

export const TablePage = ({currency, data, stamp, watermark, page, header}:ITablePageProps) => (
    <Watermark content={watermark.name} gap={[watermark.gap, watermark.gap]}>
        <div className={cls.page}>

            <AgreementFooter page={page.current} total={page.total} UID={header.UID}/>
            <AgreementHeader platform={header.platform} UID={header.UID} date={header.date}/>

            {data.length > 0 &&

            <table className={cls.tableAsset}>
                <thead>
                <tr>
                    <td></td>
                    <td colSpan={2}>{'Структура долей в активе до платежа'}</td>
                    <td colSpan={3}>{'Слагаемые суммы платежа'}</td>
                    <td colSpan={2}>{'Структура долей в активе после платежа'}</td>
                </tr>
                <tr>
                    <td>{'Порядковый номер платежа'}</td>
                    <td>{'Доля Инвестора, %'}</td>
                    <td>{'Доля Клиента, %'}</td>
                    <td>{'Аренда доли Инвестора'}</td>
                    <td>{'Выкуп доли Инвестора'}</td>
                    <td>{`Сумма платежа, ${currency}`}</td>
                    <td>{'Доля Инвестора, %'}</td>
                    <td>{'Доля Клиента, %'}</td>
                </tr>
                </thead>
                <tbody>

                {_.map(data, (item, index) => (
                    <tr key={index}>
                        <td>{item.paymentNumber}</td>
                        <td>{item.investorsSharesBeforePayment}</td>
                        <td>{item.clientSharesBeforePayment}</td>
                        <td>{item.rentOfInvestorsShare}</td>
                        <td>{item.repurchaseOfInvestorsShare}</td>
                        <td>{item.paymentSum}</td>
                        <td>{item.investorsSharesAfterPayment}</td>
                        <td>{item.clientSharesAfterPayment}</td>
                    </tr>
                ))}

                </tbody>

            </table>
            }

            {stamp &&
                <AgreementStamp platformUrl={stamp.platformUrl} registerUrl={stamp.registerUrl} UID={stamp.UID}/>
            }

        </div>
    </Watermark>
    )