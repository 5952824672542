import {useTranslation} from 'react-i18next';
import {HistoryOutlined} from '@ant-design/icons';
import {Button, Modal} from 'antd';

import cls from './modal.module.scss';

interface IModalProps {
    open: boolean;
    onOk: () => void;
}

export const ModalDraft = ({open, onOk}: IModalProps) => {
    
    const { t } = useTranslation()
    
    return (
        <Modal title={undefined} open={open} footer={[]} closable={false}>
            <div className={cls.modal}>
                <HistoryOutlined className={cls.icon}/>
                {t('Offer is saved in drafts')}
                <Button type={'primary'} onClick={onOk} className={cls.button}>{t('OK')}</Button>
            </div>
        </Modal>
    )
}